import React from 'react'
import './App.css'
import { createBrowserHistory } from 'history'
import { applyMiddleware, combineReducers, compose, createStore } from 'redux'
import thunk from 'redux-thunk'
import { create } from 'jss'
import jssExtend from 'jss-plugin-extend'
import CssBaseline from '@material-ui/core/CssBaseline'
import { jssPreset, StylesProvider, ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles'
import createMuiTheme from '@material-ui/core/styles/createMuiTheme'
import Root from './router/Root'
import salesForceOAuth2 from './reducers/salesforceOAuth2'

const test_prod = true


let oauth2 = salesForceOAuth2({
  response_type: 'token',
  scope: 'web api id',
  oauth2: {
    // TODO move to env vars for deploy
    // you can change loginUrl to connect to sandbox or prerelease env.
    loginUrl: (process.env.NODE_ENV === 'development' && !test_prod) ? 'https://test.salesforce.com' : 'https://login.salesforce.com',
    // loginUrl: 'https://chtc-ctlc--pc.my.salesforce.com',
    clientId: process.env.NODE_ENV === 'development' && !test_prod ? '3MVG9SUK8FGR3O.tJC0xL_RuyKrAj1GPEpQx.beFIT.5PLDTerGvqdjF_FNQIKH1.nCMRn9M91eLCdn6L8Ry3' : '3MVG9nkapUnZB56EDSefar0nDpISbc7lEwJCEA8XaDZ.4aNq9EBTg8pV0.dzHqAcaF3Ktdnc6Qm12MRWEh6MB',
    clientSecret: process.env.NODE_ENV === 'development' && !test_prod ? 'D4EA1E97CD671C866BE2501036C6CB9C17027F434BB3073891863687EB1C8390' : '44B131A2128A2D607DF22ADB9DF75F5C0D62EECB8769FDEF0A148D92A523CBD3',
    redirectUri: process.env.NODE_ENV === 'development' ? 'http://localhost:3000' : 'https://pdf.chtc.shiva.magnaro.com'
  }
})

// Create a history of your choosing (we're using a browser history in this case)
const browserHistory = createBrowserHistory()

const reducers = {
  oauth2: oauth2.reducer
  // authorization,
  // alerts,
  // schema,
  // restApi,
  // appState,
  // tableState,
  // router: connectRouter(history)
}

const combinedReducer = combineReducers(reducers)

/** @namespace window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ **/
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const store = createStore(combinedReducer, composeEnhancers(
  applyMiddleware(thunk)
))

const appSettings = {
  dateFormat: 'D MMM, ddd',
  pdfDateFormat: 'MMMM Do YYYY',
  dateTimeFormat: 'D MMM, ddd hh:mm',
}

const theme = createMuiTheme({})

// add JSS plugins
const jss = create({
  plugins: [...jssPreset().plugins, jssExtend()]
})

function App () {
  return (
    <StylesProvider jss={jss}>
      <MuiThemeProvider theme={theme}>
        <CssBaseline/>
        <Root history={browserHistory} store={store}/>
      </MuiThemeProvider>
    </StylesProvider>
  )
}

export { oauth2, test_prod, appSettings }
export default App
