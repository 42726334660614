/**
 * Created by Michał Stawski on 01.03.2020.
 */
import React from 'react'
import { WrappedRechart } from './rechartToSVG'
import { Bar, BarChart, CartesianGrid, XAxis, YAxis } from 'recharts'
import Legend from 'recharts/lib/component/Legend'

const statusTypes = {
  'In Progress': '#00A1E0',
  'Submitted': '#16325C',
  'First Review': '#76DED9',
  'External Review': '#08A69E',
  'Executive Director Review': '#E2CE7D',
  'Validated': '#E69F00',
  'More Info Required': '#C23934',
  'Special scrutiny': '#7f211e',
  'Declined': '#FFB75D',
  'Contracted': '#238023',
  'Operational': '#47a00c',
}

const statusToFill = (item) => statusTypes[item.status]

const markArray = (dataArray, yAxisId, fillFunction = statusToFill) => {
  let lastValue = ''
  dataArray = dataArray.sort((a, b) => {
      if (a[yAxisId] < b[yAxisId]) {
        return -1
      } else if (a[yAxisId] > b[yAxisId]) {
        return 1
      } else {
        return 0
      }
    }
  )
  for (let i = 0; i < dataArray.length; i++) {
    if (dataArray[i][yAxisId] !== lastValue) {
      lastValue = dataArray[i][yAxisId]
      dataArray[i].categoryTick = dataArray[i][yAxisId]
    }
    if (fillFunction) {
      dataArray[i].fill = fillFunction(dataArray[i])
    }
  }
  return dataArray
}

const renderCategoryTick = (tickProps) => {
  const { y, payload } = tickProps
  const { value, offset } = payload

  // console.log(tickProps)
  if (value) {
    return <g className="recharts-layer recharts-cartesian-axis-tick">
      {/*<line className="recharts-yAxis yAxis" type="category" width="250" orientation="left" height="340" x="105" y="5"*/}
      {/*      viewBox="[object Object]" stroke="#666" fill="none" x1="349" y1="175.00000000000003" x2="355"*/}
      {/*      y2="175.00000000000003"></line>*/}
      <path d={`M${0},${y - 8}h${350}`} stroke="#666"/>
      {/*;*/}
      <text x={0} y={y + offset} textAnchor="left">{`${value}`}</text>
    </g>
  } else {
    return null
  }
}

class HorizontalBarsMultiAxisChart extends WrappedRechart {
  render () {
    let {
      width, height, data, id, barDefinition, yWidth = 180, tickFormatter,
      yAxisId = 'projectType', fillFunction, legend = false, smallTick = {}
    } = this.props
    data = markArray(data, yAxisId, fillFunction).filter((item => item.value > 0))
    return <BarChart
      width={width}
      height={height}
      data={data}
      ref={(chart) => {
        this.chartRef = chart
      }}
      id={id}
      layout='vertical'
    >
      <CartesianGrid strokeDasharray="3 3"/>
      <XAxis
        type='number'
        domain={[0, 'dataMax']}
        tickFormatter={tickFormatter}
      />
      <YAxis dataKey='categoryTick'
             type='category'
             width={100}
             tickLine={false}
             axisLine={false}
             interval={0}
             height={1}
             tick={renderCategoryTick}
             yAxisId={yAxisId}
      />

      <YAxis dataKey='status' type='category' width={yWidth}
             interval={0}
             tick={smallTick}
      />
      {legend && <Legend/>}
      {
        barDefinition.map(
          (item, index) => <Bar dataKey={item.dataKey} fill={item.fill} name={item.name} key={index}
                                isAnimationActive={false} maxBarSize={50}
          />
        )
      }
    </BarChart>
  }
}

export { HorizontalBarsMultiAxisChart, statusToFill }
