/**
 * Created by Michał Stawski on 27.03.2020.
 */
import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import { withStyles } from '@material-ui/core'

const styles = theme => ({
  loadingContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  }
})

class LoadingPlaceholder extends React.Component {
  render () {
    let { loading, className, classes } = this.props

    return loading ? <div className={classes.loadingContainer}>
      <CircularProgress/>
    </div> : ''
  }
}

export default withStyles(styles)(LoadingPlaceholder)
