/**
 * Created by Michał Stawski on 21.02.2020.
 */
import React from 'react'
import { withStyles } from '@material-ui/core'
import { connect } from 'react-redux'
import { appSettings, oauth2 } from '../../App'
import {
  filterOpportunities,
  filterOpportunitiesByDate,
  getOpportunities,
  START_OF_RANGES
} from '../../jsforceApi/opportunities'
import Grid from '@material-ui/core/Grid'
import moment from 'moment'
import Button from '@material-ui/core/Button'
import { idToSVG } from './charts/rechartToSVG'
import { VerticalBarsChart } from './charts/VerticalBarsChart'
import { HorizontalBarsChart } from './charts/HorizontalBarsChart'
import { calcPriorityAreasCentre, calcPriorityAreasCMHC } from './calc/calcPriorityAreas'
import calcGeoDistribution from './calc/calcGeoDistribution'
import LoadingPlaceholder from '../../common/components/LoadingPlaceholder'
import Typography from '@material-ui/core/Typography'
import { calcFundsByFundType } from './calc/calcFundsByFundType'
import { Area, CartesianGrid, ComposedChart, LabelList, Legend, Text, XAxis, YAxis } from 'recharts'
import generateAllocationCommitteePDF from '../../components/pdfAllocationCommittee'
import { PieChartContainer } from './charts/PieChartContainer'
import { calcAverageByFund } from './calc/calcFundsAverage'
import { DoubleHorizontalBarsChart } from './charts/DoubleHorizontalChart'
import {
  firstStageDate,
  isAwarded,
  isInPipeline,
  isInPipelineAllocation,
  isSubmitted,
  oppToState,
  oppToValue,
  oppToValueRequested,
  sortByString
} from './calc/commonCalc'
import { AllocationForm } from './AllocationForm'
import calcOpportunitiesByStage, { calcOpportunitiesByProvinceByStage } from './calc/calcOpportunitiesByStage'
import { HorizontalBarsMultiAxisChart } from './charts/HorizontalBarsMultiAxisChart'
import generateCMHCQuaterlyPDF from '../../components/pdfCHTCQuaterly'
import {
  calcAverageDuration,
  calcAverageTimeFromSubmitting,
  calcDurationInProgress,
  calcDurationSubmitted
} from './calc/calcApplicationsInProgress'
import { calcFundingByProvinces } from './calc/calcFundingByProvince'
import { summaryOptions } from '../../components/pdfExport'
import { endOfFiscalYear, startOfFiscalYear } from '../../common/utils/dateUtils'
import { LineChartContainer } from './charts/LineChartContainer'
import { calcTimeInPipeline } from './calc/calcTimeInPipeline'

const styles = theme => ({
  mainGrid: {
    height: '100%'
  },
  root: {
    flexGrow: 1,
    padding: 10,
    maxHeight: 'calc(100vh - 64px)'
    // overflow:'hidden'
  },
  paper: {
    padding: theme.spacing(1),
    height: 'calc(100vh - 80px)'
    // overflowY: 'scroll',
  },
  tableContainer: {
    // TODO change to calc
    height: 'calc(100% - 92px)'
  },
  gridItem: {
    maxHeight: '100%'
  },
  gridHeader: {
    textAlign: 'center'
  },
  formControl: {
    margin: theme.spacing(3)
  },
  reportType: {
    width: '100%'
  }
})

const nf = new Intl.NumberFormat('en-CA', {
  style: 'currency',
  currency: 'CAD',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0
})

function valueShortened (value) {
  let toReturn = value
  let shortened = ''
  let divider
  if (value > 1000) {
    // value > 1000000 ? (divider = 1000000) : (divider = 1000);
    // value > 1000000 ? (shortened = "m") : (shortened = "k");
    divider = 1000
    shortened = 'k'
    let howMany = Math.floor(value / divider)
    // let reminder = (value % divider) / divider
    // reminder = reminder.toString().slice(2)
    // if (reminder != '') {
    //   toReturn = howMany.toString() + '.' + reminder
    // } else {
    //   toReturn = howMany.toString()
    // }
    toReturn = howMany
  } else if (value > 0) {
    toReturn = (value % 1000) / 1000
  }
  let string = nf.format(toReturn) + shortened
  return string
}

const FundsByFundType = ({ id, data }) => (
  <VerticalBarsChart
    id={id}
    // ref={(chart) => reference[referenceName] = chart}
    yWidth={100}
    data={data}
    axisLabelKey={'name'}
    skewedAxis={false}
    labelsHeight={30}
    xAxisTickOptions={{ fontSize: 24 }}
    margin={{ top: 15, right: 25, bottom: 5, left: 65 }}
    yAxisOptions={{ tickFormatter: (value) => valueShortened(value.toFixed(0)) }}
    barDefinition={[
      // {
      //   dataKey: 'percent', fill: '#40BAD2', maxBarSize: 70,
      //   children: <LabelList dataKey='percent' position='top'
      //                        formatter={(value) => value.toFixed(1) + ' %'}
      //   />
      // },
      {
        dataKey: 'value',
        name: 'Amount Awarded',
        fill: '#FAB900',
        maxBarSize: 70,
        children: (
          <LabelList
            dataKey='value'
            position='top'
            formatter={value => valueShortened(value.toFixed(0))}
          />
        )
      },
      {
        dataKey: 'budget',
        name: 'Budget',
        fill: '#40BAD2',
        maxBarSize: 70,
        children: (
          <LabelList
            dataKey='budget'
            position='top'
            formatter={value => valueShortened(value.toFixed(0))}
          />
        )
      }
    ]}
  />
)
const resortData = data => {
  // resort data so not all small slices are next to each other (so the labels look ok)
  let ret = []
  data = data.sort((a, b) => a.allValue - b.allValue)
  let i = 0,
    j = data.length - 1
  while (i <= j) {
    ret.push(data[i])
    i++
    if (i > j) {
      break
    }
    ret.push(data[j])
    j--
  }
  return ret
}

const GeoDistribution = ({ id, data }) => (
  <PieChartContainer
    id={id}
    height={340}
    yWidth={100}
    data={resortData(data)}
    axisLabelKey={'name'}
    skewedAxis={false}
    label={props => {
      let changed = valueShortened(props.allValue)
      let labelText = `${props.name} ${changed}`
      return (
        <Text
          {...props}
          width={120}
          //Change treshold if charts sizes will be different
          verticalAnchor={props.y < 200 ? 'end' : 'start'}
          style={{ fontSize: '16px', marginBottom: 20 }}
        >
          {labelText}
        </Text>
      )
    }}
    // label={({
    //           cx, cy, midAngle, innerRadius, outerRadius, value, fill, startAngle, endAngle,
    //           name, allValue, ...props
    //         }) => {
    //   const RADIAN = Math.PI / 180
    //   const diffAngle = endAngle - startAngle
    //   const delta = ((360 - diffAngle) / 25) - 1
    //   const radius = innerRadius + (outerRadius - innerRadius)
    //   let x = cx + (radius + delta) * Math.cos(-midAngle * RADIAN)
    //   const y = cy + (radius + (delta * delta)) * Math.sin(-midAngle * RADIAN)
    //   let labelText = `${name} ${allValue}$`
    //   console.log(fill, props)
    //   if (x > cx){
    //     x += 2
    //   }else{
    //     x -= 2
    //   }
    //   return (
    //     <Text x={x} y={y} fill={fill} textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central"
    //           fontWeight="normal" width={120}>
    //       {labelText}
    //     </Text>
    //   )
    // }}
    // labelLine={(props) => {
    //   let {
    //     cx, cy, midAngle, innerRadius, outerRadius, fill, stroke, startAngle, endAngle,
    //   } = props
    //   const RADIAN = Math.PI / 180
    //   const diffAngle = endAngle - startAngle
    //   const radius = innerRadius + (outerRadius - innerRadius)
    //   let path = ''
    //   for (let i = 0; i < ((360 - diffAngle) / 25); i++) {
    //     path += `${(cx + (radius + i) * Math.cos(-midAngle * RADIAN))},${(cy + (radius + i * i) * Math.sin(-midAngle * RADIAN))} `
    //   }
    //   console.log(fill, props)
    //   return (
    //     <polyline points={path} stroke={stroke} fill="none"/>
    //   )
    // }}

    dataKey={'allValue'}
    // margin={{ top: 15, right: 25, bottom: 5, left: 65 }}
    filter={item => item.allValue !== 0}
    pieFill={(item, index) => item.fill}
  />
)

const CentrePrioritiesValue = ({ id, data, options = {} }) => {
  if (options.noTotal) {
    let newArray = [...data]
    if (newArray.length > 1) {
      newArray.splice(0, 1)
      data = newArray
    }
  }
  return (
    <VerticalBarsChart
      id={id}
      yWidth={180}
      data={data}
      axisLabelKey={'name'}
      skewedAxis={false}
      labelsHeight={options.labelsHeight}
      margin={{ top: 20, right: 5, bottom: 5, left: 15 }}
      xAxisTickOptions={{ fontSize: options.fontSize }}
      barDefinition={[
        {
          dataKey: 'value',
          fill: '#40BAD2',
          maxBarSize: 80,
          children: (
            <LabelList
              dataKey='value'
              position='top'
              formatter={value => valueShortened(value.toFixed(0))}
            />
          )
        }
      ]}
    />
  )
}

const AverageTimeFromSubmitting = ({ id, data }) => (
  <LineChartContainer id={id} data={data}></LineChartContainer>
)

const CentrePrioritiesCount = ({ id, data }) => (
  <HorizontalBarsChart
    id={id}
    data={data}
    yWidth={180}
    yAxisTick={{ fontSize: 17 }}
    margin={{ top: 15, right: 5, bottom: 5, left: 55 }}
    barDefinition={[
      {
        dataKey: 'count',
        fill: '#FAB900',
        maxBarSize: 60,
        children: <LabelList dataKey='count' position='right'/>
      }
    ]}
  />
)

const DoubleBarsHorizontalChart = ({ id, data, props }) => {
  if (props.noTotal) {
    let newArray = [...data]
    if (newArray.length > 1) {
      newArray.splice(5, 1)
      data = newArray
    }
  }
  return (
    <DoubleHorizontalBarsChart
      id={id}
      yWidth={props.yWidth ? props.yWidth : 100}
      yAxisTick={{ fontSize: props.yAxisTick }}
      data={data}
      xAxis={false}
      margin={{ top: 0, right: 5, bottom: 5, left: 5 }}
      barDefinition={[
        {
          dataKey: 'value',
          name: 'Total amount awarded',
          fill: '#40BAD2',
          maxBarSize: 80,
          xAxisId: 0,
          children: (
            <LabelList
              dataKey='value'
              position='center'
              formatter={value => valueShortened(value.toFixed(0))}
            />
          )
        },
        {
          dataKey: 'count',
          name: 'Number of Projects',
          fill: '#FAB900',
          maxBarSize: 40,
          xAxisId: 1,
          children: (
            <LabelList
              dataKey='count'
              position='insideLeft'
              formatter={value => value.toFixed(0)}
            />
          )
        }
      ]}
    />
  )
}

const TotalAmountAndProjectsNumber = ({ id, data, noTotal }) => {
  if (noTotal) {
    let newArray = [...data]
    if (newArray.length > 1) {
      newArray.splice(0, 1)
      data = newArray
    }
  }
  return (
    <DoubleHorizontalBarsChart
      id={id}
      yWidth={100}
      data={data}
      xAxis={false}
      yAxisTick={{ fontSize: 24 }}
      margin={{ top: 15, right: 5, bottom: 5, left: 55 }}
      barDefinition={[
        {
          dataKey: 'value',
          name: 'Total value of project',
          fill: '#40BAD2',
          maxBarSize: 80,
          xAxisId: 0,
          children: (
            <LabelList
              dataKey='value'
              position='center'
              formatter={value => valueShortened(value.toFixed(0))}
            />
          )
        },
        {
          dataKey: 'count',
          name: 'Number of Projects',
          fill: '#FAB900',
          maxBarSize: 40,
          xAxisId: 1,
          children: (
            <LabelList
              dataKey='count'
              position='insideLeft'
              formatter={value => value.toFixed(0)}
            />
          )
        }
      ]}
    />
  )
}

const OpportunitiesByStage = ({ id, data, yAxisId = 'projectType' }) => (
  <HorizontalBarsMultiAxisChart
    id={id}
    data={data}
    yAxisId={yAxisId}
    yWidth={80}
    margin={{ top: 15, right: 5, bottom: 5, left: 55 }}
    tickFormatter={data => {
      if (data === 0) {
        return 0
      } else if (data < 1000000) {
        return Math.floor(data / 1000).toString() + 'K'
      } else {
        return (Math.floor(data / 100000) / 10).toString() + 'M'
      }
    }}
    barDefinition={[
      {
        dataKey: 'value'
      }
    ]}
  />
)

const ApplicationsInProgress = ({ id, data, noTotal = false }) => {
  if (noTotal) {
    let newArray = [...data]
    console.log(newArray)
    if (newArray.length > 1) {
      newArray.splice(-2, 1)
      data = newArray
    }
  }
  return (
    <HorizontalBarsChart
      id={id}
      yWidth={210}
      yAxisTick={{ fontSize: 20 }}
      margin={{ top: 5, right: 0, bottom: 5, left: 5 }}
      data={data}
      // xAxis={false}
      barDefinition={[
        {
          dataKey: 'value',
          maxBarSize: 80,
          xAxisId: 0,
          children: (
            <LabelList
              dataKey='value'
              position='right'
              formatter={value => (value ? value.toFixed(0) : '')}
            />
          )
        }
      ]}
    />
  )
}

const ApplicationAverageDuration = ({ id, data }) => (
  <HorizontalBarsChart
    id={id}
    data={data}
    yWidth={210}
    yAxisTick={{ fontSize: 21 }}
    margin={{ top: 0, right: 5, bottom: 5, left: 5 }}
    barDefinition={[
      {
        dataKey: 'value',
        fill: '#FAB900',
        maxBarSize: 60,
        children: (
          <LabelList
            dataKey='value'
            position='right'
            formatter={value => (value ? value.toFixed(0) : '')}
          />
        )
      }
    ]}
  />
)

const AvarageTimeInPipeline = ({ id, data }) => (
  <ComposedChart
    // width={500}
    // height={400}
    width={700}
    id={id}
    height={400}
    data={data}
    margin={{
      top: 10, right: 30, left: 0, bottom: 0,
    }}
  >
    <CartesianGrid strokeDasharray="3 3"/>
    {/*<XAxis dataKey="month" />*/}
    {/*<XAxis dataKey="year" />*/}
    <XAxis dataKey="name" interval={'preserveStartEnd'}/>
    <YAxis/>
    <Legend/>
    <Area type="monotone" stackId={1} dataKey="grantee" stroke="#95BF28" fill="#95BF28" label={'Grantee Stage'}/>
    <Area type="monotone" stackId={1} dataKey="chtc" stroke="#FABD00" fill="#FABD00" label={'CHTC Stage'}/>
    {/*<Line type="monotone" dataKey="diff" stroke="#000000"/>*/}
  </ComposedChart>
)

const calcHighlight = (
  results,
  range,
  {
    countIsValidated = false,
    allocationCommittee = false
  }
) => {
  console.log(results)
  let inPipeline
  if (allocationCommittee) {
    inPipeline = results.filter(item => isInPipelineAllocation(item.StageName, countIsValidated))
  } else {
    inPipeline = results.filter(item => isInPipeline(item.StageName))
  }
  console.log('projects in pipeline', inPipeline)

  let pipelineValue = 0
  for (const res of inPipeline) {
    if (oppToValueRequested(res) !== null) {
      pipelineValue += oppToValueRequested(res)
    }
  }
  let applications = results
    .filter(isSubmitted)
    .filter(item =>
      moment
        .utc(item.Submit_Date__c)
        .isBetween(range.start, range.end, 'day', '[]')
    ).length

  let awardedList = results
    .filter(isAwarded)
    .filter(filterOpportunitiesByDate({ ...range, countIsValidated }))

  console.log('awarded Listshighlights', awardedList)
  let total = 0
  let provinces = {}
  for (let res of awardedList) {
    total += oppToValue(res)
    provinces[oppToState(res)] = true
  }
  let provinceCount = 0
  let hasCanada = false

  for (let p in provinces) {
    if (p === 'Canada') {
      hasCanada = true
    } else {
      provinceCount++
    }
  }
  //total = valueShortened(total);
  return {
    applications,
    awarded: awardedList.length,
    total,
    provinceCount,
    range,
    hasCanada,
    inPipeline,
    pipelineValue
  }
}

const dateRanges = form => {
  // console.log(form)
  let month = 4
  let year = 2020
  let current_date = moment.utc()
  let current_year = moment.utc().year()
  let current_quarter = moment.utc(current_date).quarter()
  let selected_quarter = form.selected_quarter

  let endDate
  let startDate
  if (form && form.period === 'quarterly') {
    startDate = moment
      .utc([parseInt(form.selected_year, 10)])
      .quarter(selected_quarter)
      .startOf('quarter')
    endDate = moment
      .utc([parseInt(form.selected_year, 10)])
      .quarter(selected_quarter)
      .endOf('quarter')
  } else if (form && form.period === 'last_quarter') {
    endDate = moment
      .utc()
      .quarter(current_quarter - 1)
      .endOf('quarter')
    startDate = moment
      .utc()
      .quarter(current_quarter - 1)
      .startOf('quarter')
  } else if (form && form.period === 'monthly') {
    endDate = moment.utc(form.end).endOf('month')
    startDate = endDate.clone().startOf('month')
  } else if (form && form.period === 'date_picker') {
    endDate = moment.utc(form.end)
    startDate = moment.utc(form.start)
  } else if (form && form.period === 'yearly') {
    startDate = moment.utc([parseInt(form.fiscal_year, 10), 3, 1])
    endDate = moment.utc([parseInt(form.fiscal_year, 10) + 1, 2, 31])
  } else {
    endDate = moment.utc({ year, month }).endOf('month')
    startDate = endDate.clone().startOf('month')
  }

  // todo options to pdf description
  let range_total = { start: START_OF_RANGES, end: form.endAtEndOfActual ? endDate : current_date }
  //  last month
  let range_actual = { start: startDate, end: endDate }
  // current fiscal year till end of year
  let range_annual = {
    start: startOfFiscalYear(startDate),
    end: form.endAtEndOfActual ? endDate : endOfFiscalYear(startDate),
  }

  return { endDate, range_annual, range_actual, range_total }
}

const addDatesFromHistory = (result) => {
  for (let res of result) {
    res.firstAwardedDate = firstStageDate(res, 'Awarded')
  }
  return result
}

const generateDocumentParts = ({ graphData, form = {} }) => {
  // add which FY
  const ANNUAL = 'Since Beginning of Fiscal Year'
  const CUMULATIVE = 'Since Dec 13 2019'
  console.log(form)
  let ACTUAL
  if (form.period === 'last_quarter') {
    ACTUAL = `in Last Quarter`
  } else if (form.period === 'quarterly') {
    // quarter
    if (form.useCustomLabel) {
      ACTUAL = `in Last Quarter`
    } else {
      ACTUAL = `in Q${graphData.endDate.format('Q')} `
    }
  } else if (form.period === 'monthly') {
    // month
    ACTUAL = `in ${moment.utc(form.end).format('MMMM')}  `
  } else if (form.period === 'date_picker') {
    // date range
    ACTUAL = `from ${graphData.startDate.format(
      'MMMM Do YYYY'
    )} to ${graphData.endDate.format('MMMM Do YYYY')} `
  } else {
    console.warn('unhandled period', form)
    ACTUAL = `from ${graphData.startDate.format(
      'MMMM Do YYYY'
    )} to ${graphData.endDate.format('MMMM Do YYYY')} `
  }

  let info = {
    ...form,
    start: moment.utc(form.start).format(appSettings.pdfDateFormat),
    end: moment.utc(form.end).format(appSettings.pdfDateFormat),
    actual: ACTUAL,
    countIsValidated: form.countIsValidated + ''
  }
  console.log(form)

  return {
    info,
    endDate: graphData.endDate,
    startDate: graphData.startDate,
    pipelineData: {
      ...graphData.durationSubmitted.annual,
      rangeString: ANNUAL
    },
    pipelineDataTotal: {
      ...graphData.durationSubmitted.total,
      rangeString: CUMULATIVE
    },
    pipelineDataActual: {
      ...graphData.durationSubmitted.actual,
      rangeString: ACTUAL
    },
    highlightData: { ...graphData.highlightData, rangeString: ANNUAL },
    highlightDataTotal: {
      ...graphData.highlightDataTotal,
      rangeString: CUMULATIVE
    },
    highlightDataActual: {
      ...graphData.highlightDataActual,
      rangeString: ACTUAL
    },
    fundingByProvincesCentre: graphData.fundingByProvincesCentre,
    fundingByProvincesCMHC: graphData.fundingByProvincesCMHC,
    fundingByRange: graphData.fundingByRange,

    // opportunitiesByStageTable: graphData.opportunitiesByStage.table,
    awardedProjects: graphData.awardedProjects,
    fundsByFundTypeAnnual: idToSVG(
      'fundsByFundTypeAnnual',
      'Allocation by Funds - ' + ANNUAL
    ),
    fundsByFundTypeActual: idToSVG(
      'fundsByFundTypeActual',
      'Allocation by Funds - ' + ACTUAL
    ),
    fundsByFundTypeCumulative: idToSVG(
      'fundsByFundTypeCumulative',
      'Allocation by Funds - ' + CUMULATIVE
    ),
    //average time in pipeline
    averageTimeInPipeLine: idToSVG(
      'averageTimeInPipeLine',
      'Average Time in Pipeline'
    ),
    // Geo Distribution
    geoDistributionTotalActual: idToSVG(
      'geoDistributionTotalActual',
      'Geographic Distribution - Total - ' + ACTUAL
    ),
    geoDistributionTotalAnnual: idToSVG(
      'geoDistributionTotalAnnual',
      'Geographic Distribution - Total - ' + ANNUAL
    ),
    geoDistributionTotalCumulative: idToSVG(
      'geoDistributionTotalCumulative',
      'Geographic Distribution - Total - ' + CUMULATIVE
    ),

    geoDistributionCBTIActual: idToSVG(
      'geoDistributionCBTIActual',
      'Geographic Distribution - CBTI - ' + ACTUAL
    ),
    geoDistributionCBTIAnnual: idToSVG(
      'geoDistributionCBTIAnnual',
      'Geographic Distribution - CBTI - ' + ANNUAL
    ),
    geoDistributionCBTICumulative: idToSVG(
      'geoDistributionCBTICumulative',
      'Geographic Distribution - CBTI - ' + CUMULATIVE
    ),

    geoDistributionStfLPActual: idToSVG(
      'geoDistributionStfLPActual',
      'Geographic Distribution - STF-LP - ' + ACTUAL
    ),
    geoDistributionStfLPAnnual: idToSVG(
      'geoDistributionStfLPAnnual',
      'Geographic Distribution - STF-LP - ' + ANNUAL
    ),
    geoDistributionStfLPCumulative: idToSVG(
      'geoDistributionStfLPCumulative',
      'Geographic Distribution - STF-LP - ' + CUMULATIVE
    ),

    geoDistributionStfSipActual: idToSVG(
      'geoDistributionStfSipActual',
      'Geographic Distribution - STF-SIP - ' + ACTUAL
    ),
    geoDistributionStfSipAnnual: idToSVG(
      'geoDistributionStfSipAnnual',
      'Geographic Distribution - STF-SIP - ' + ANNUAL
    ),
    geoDistributionStfSipCumulative: idToSVG(
      'geoDistributionStfSipCumulative',
      'Geographic Distribution - STF-SIP - ' + CUMULATIVE
    ),
    // priorities Centre Value
    priorityAreasCentreValueCurrent: idToSVG(
      'priorityAreasCentreValueCurrent',
      'Allocation by Centre\'s Priorities ($) ' + ACTUAL
    ),
    priorityAreasCentreValueAnnual: idToSVG(
      'priorityAreasCentreValueAnnual',
      'Allocation by Centre\'s Priorities ($) ' + ANNUAL
    ),
    priorityAreasCentreValueCumulative: idToSVG(
      'priorityAreasCentreValueCumulative',
      'Allocation by Centre\'s Priorities ($) ' + CUMULATIVE
    ),

    // priorities Centre Number
    priorityAreasCentreNumberCurrent: idToSVG(
      'priorityAreasCentreNumberCurrent',
      'Allocation by Centre\'s Priorities (#) ' + ACTUAL
    ),
    priorityAreasCentreNumberAnnual: idToSVG(
      'priorityAreasCentreNumberAnnual',
      'Allocation by Centre\'s Priorities (#) ' + ANNUAL
    ),
    priorityAreasCentreNumberCumulative: idToSVG(
      'priorityAreasCentreNumberCumulative',
      'Allocation by Centre\'s Priorities (#) ' + CUMULATIVE
    ),
    // Average Amount
    totalAmountAndProjectsNumberCurrent: idToSVG(
      'totalAmountAndProjectsNumberCurrent',
      'Funding Stream Distribution - Total amount and number of projects, ' +
      ACTUAL
    ),
    totalAmountAndProjectsNumberAnnual: idToSVG(
      'totalAmountAndProjectsNumberAnnual',
      'Funding Stream Distribution - Total amount and number of projects, ' +
      ANNUAL
    ),
    totalAmountAndProjectsNumberCumulative: idToSVG(
      'totalAmountAndProjectsNumberCumulative',
      'Funding Stream Distribution - Total amount and number of projects, ' +
      CUMULATIVE
    ),

    averageTimeFromSubmitting: idToSVG(
      'averageTimeFromSubmitting',
      'Average time application has spent in a stage since being submitted ' +
      CUMULATIVE
    ),

    distributionByPrioritiesCurrent: idToSVG(
      'distributionByPrioritiesCurrent',
      'Distribution of Consolidated Funding by Centre’s Priorities, ' + ACTUAL
    ),
    distributionByPrioritiesAnnual: idToSVG(
      'distributionByPrioritiesAnnual',
      'Distribution of Consolidated Funding by Centre’s Priorities, ' + ANNUAL
    ),
    distributionByPrioritiesCumulative: idToSVG(
      'distributionByPrioritiesCumulative',
      'Distribution of Consolidated Funding by Centre’s Priorities, ' +
      CUMULATIVE
    ),
    //
    priorityAreasCMHCValueCurrent: idToSVG(
      'priorityAreasCMHCValueCurrent',
      'Allocation by CMHC\'s Priorities ($) ' + ACTUAL
    ),
    priorityAreasCMHCValueAnnual: idToSVG(
      'priorityAreasCMHCValueAnnual',
      'Allocation by CMHC\'s Priorities ($) ' + ANNUAL
    ),
    priorityAreasCMHCValueCumulative: idToSVG(
      'priorityAreasCMHCValueCumulative',
      'Allocation by CMHC\'s Priorities ($) ' + CUMULATIVE
    ),

    // priorities CMHC Number
    priorityAreasCMHCNumberCurrent: idToSVG(
      'priorityAreasCMHCNumberCurrent',
      'Allocation by CMHC\'s Priorities (#) ' + ACTUAL
    ),
    priorityAreasCMHCNumberAnnual: idToSVG(
      'priorityAreasCMHCNumberAnnual',
      'Allocation by CMHC\'s Priorities (#) ' + ANNUAL
    ),
    priorityAreasCMHCNumberCumulative: idToSVG(
      'priorityAreasCMHCNumberCumulative',
      'Allocation by CMHC\'s Priorities (#) ' + CUMULATIVE
    ),

    opportunitiesByStageAnnual: idToSVG(
      'opportunitiesByStageActual',
      'Opportunities By Stage ' + ACTUAL
    ),
    opportunitiesByStageActual: idToSVG(
      'opportunitiesByStageActual',
      'Opportunities By Stage ' + ANNUAL
    ),
    opportunitiesByStageCumulative: idToSVG(
      'opportunitiesByStageCumulative',
      'Opportunities By Stage ' + CUMULATIVE
    ),

    averageAmountByPriorityCMHCCurrent: idToSVG(
      'averageAmountByPriorityCMHCCurrent',
      'Number of Projects by CMHC’s Priorities\n and the Total Amount Awarded ' +
      ACTUAL
    ),
    averageAmountByPriorityCMHCAnnual: idToSVG(
      'averageAmountByPriorityCMHCAnnual',
      'Number of Projects by CMHC’s Priorities\n and the Total Amount Awarded ' +
      ANNUAL
    ),
    averageAmountByPriorityCMHCCumulative: idToSVG(
      'averageAmountByPriorityCMHCCumulative',
      'Number of Projects by CMHC’s Priorities\n and the Total Amount Awarded ' +
      CUMULATIVE
    ),

    durationSubmittedCurrent: idToSVG(
      'durationSubmittedActual',
      'Number of applications by evaluation time ' + ACTUAL
    ),
    durationSubmittedAnnual: idToSVG(
      'durationSubmittedAnnual',
      'Number of applications by evaluation time ' + ANNUAL
    ),
    durationSubmittedCumulative: idToSVG(
      'durationSubmittedCumulative',
      'Number of applications by evaluation time ' + CUMULATIVE
    ),

    durationInProgressCurrent: idToSVG(
      'durationInProgressActual',
      'Application in Progress (number of days in that stage) ' + ACTUAL
    ),
    durationInProgressAnnual: idToSVG(
      'durationInProgressAnnual',
      'Application in Progress (number of days in that stage) ' + ANNUAL
    ),
    durationInProgressCumulative: idToSVG(
      'durationInProgressCumulative',
      'Application in Progress (number of days in that stage) ' + CUMULATIVE
    ),

    averageDurationCurrent: idToSVG(
      'averageDurationActual',
      'Average Stage Duration ' + ACTUAL
    ),
    averageDurationAnnual: idToSVG(
      'averageDurationAnnual',
      'Average Stage Duration ' + ANNUAL
    ),
    averageDurationCumulative: idToSVG(
      'averageDurationCumulative',
      'Average Stage Duration ' + CUMULATIVE
    )
  }
}

class AllocationCommitteeInner extends React.Component {
  state = {
    opportunities: [],
    opportunitiesChosen: {},
    reportType: '',
    fields: {
      startDate: new moment().startOf('year'),
      endDate: new moment().endOf('day')
    },
    error: null
  }

  constructor (props) {
    super(props)
  }

  componentDidMount () {
    this.refresh()
  }

  refresh () {
    if (!this.conn) {
      this.conn = oauth2.getConnection(this.props.oauth2)
    }

    getOpportunities(this.conn).then(
      result => {
        this.setState({ opportunities: result })
        this.calculateData(result)
      },
      err => {
        if (err) {
          this.props.handleSFError(err)
          console.error(err)
          this.setState({ error: err.toString() })
        }
      }
    )
  }

  dateChanged (dateData) {
    console.log(dateData)
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (this.state.form !== prevState.form) {
      this.calculateData(this.state.opportunities)
    }
  }

  //liczone da
  calculateData (result) {
    result = filterOpportunities(result)
    result = addDatesFromHistory(result)

    let { form = {} } = this.state

    //check data
    let no_budget = 0

    for (let b in result) {
      if (b.Budget__r) {
        // console.log(b.Budget__r)
      } else {
        no_budget++
      }
    }
    console.log('no_budget', no_budget)

    console.log('result', result)
    let { endDate, range_actual, range_total, range_annual } = dateRanges(form)

    // let pushList = []
    // let p
    // for (let i of result) {
    //   // console.log(i)
    //   // console.log(i.OpportunityHistories)
    //   if (i.OpportunityHistories) {
    //     for (let hist of i.OpportunityHistories.records) {
    //       p = {
    //         id: i.Id,
    //         name: i.Name,
    //         createdDate: hist.CreatedDate,
    //         stageName: hist.StageName
    //       }
    //       p = `${i.Id}, ${i.Name}, ${hist.CreatedDate}, ${hist.StageName} `
    //       // console.log(p)
    //       pushList.push(p)
    //     }
    //   }
    // }
    // console.log(pushList)
    // console.log(pushList.join('\n'))

    // getHistory2(this.conn).then((result) => {
    //     console.log('history', result)
    //   }
    // )

    // for (let res in result) {
    //   getHistory2(this.conn, res.Id).then((result) => {
    //       console.log('history', result)
    //     }
    //   )
    // }

    console.log(range_total, range_annual, range_actual)
    console.log(
      result
        .filter(res => res.Contract_Signed_Date__c)
        .map(res => res.Submit_Date__c)
    )
    console.log(result.map(res => res.StageName))
    console.log(result.filter(filterOpportunitiesByDate(range_total)))
    console.log(result.filter(filterOpportunitiesByDate(range_annual)))
    console.log(result.filter(filterOpportunitiesByDate(range_actual)))

    console.log(
      result.map(item => ({
        n: item.name,
        s: item.StageName,
        p: oppToState(item),
        ps: item.Geographical_scope_detailed__c,
        ivd: item.isvalidated__c,
        sud: item.Submit_Date__c,
        csd: item.Contract_Signed_Date__c,
        cdd: item.CreatedDate
      }))
    )

    // console.log(result.filter(filterOpportunitiesByDate(range_total)).map((res) =>
    //   oppToState(res)))

    const calculateAll = (
      result,
      calculator,
      options = { countIsValidated: false }
    ) => {
      let ret = {
        total: calculator(
          result.filter(
            filterOpportunitiesByDate({ ...range_total, ...options })
          ),
          { ...options, ...range_total, rangeType: 'total' }
        ),
        actual: calculator(
          result.filter(
            filterOpportunitiesByDate({ ...range_actual, ...options })
          ),
          { ...options, ...range_actual, rangeType: 'actual' }
        ),
        annual: calculator(
          result.filter(
            filterOpportunitiesByDate({ ...range_annual, ...options })
          ),
          { ...options, ...range_annual, rangeType: 'annual' }
        )
      }
      console.log(ret)
      return ret
    }
    let awarded = result.filter(isAwarded)

    let newState = {}
    try {
      newState.fundsByFundType = calculateAll(awarded, calcFundsByFundType, {
        countIsValidated: form.countIsValidated
      })
      newState.geoDistribution = calculateAll(awarded, calcGeoDistribution, {
        countIsValidated: form.countIsValidated
      })
      newState.geoDistributionCBTI = calculateAll(
        awarded,
        calcGeoDistribution,
        {
          fund: 'cbtif',
          countIsValidated: form.countIsValidated
        }
      )
      newState.geoDistributionStfSip = calculateAll(
        awarded,
        calcGeoDistribution,
        {
          fund: 'stf_s',
          countIsValidated: form.countIsValidated
        }
      )
      newState.geoDistributionStfLP = calculateAll(
        awarded,
        calcGeoDistribution,
        {
          fund: 'stf_l',
          countIsValidated: form.countIsValidated
        }
      )
      newState.averageTimeFromSubmitting = calcAverageTimeFromSubmitting(result)
      newState.priorityAreasCentre = calculateAll(
        awarded,
        calcPriorityAreasCentre,
        { countIsValidated: form.countIsValidated, noTotal: true }
      )
      newState.priorityAreasCMHC = calculateAll(
        awarded,
        calcPriorityAreasCMHC,
        { countIsValidated: form.countIsValidated, noTotal: true }
      )
      newState.averageByFund = calculateAll(awarded, calcAverageByFund, {
        countIsValidated: form.countIsValidated
      })
      newState.averageTimeInPipeLine = calcTimeInPipeline(result)
      // newState.distributionByPriorities = calculateAll(
      //   awarded,
      //   calcDistributionByPriorities,
      //   {
      //     countIsValidated: form.countIsValidated,
      //   }
      // );

      newState.opportunitiesByStage = calculateAll(
        result,
        calcOpportunitiesByStage,
        { countIsValidated: form.countIsValidated }
      )
      newState.opportunitiesByProvinceByStage = calculateAll(
        result,
        calcOpportunitiesByProvinceByStage,
        { countIsValidated: form.countIsValidated }
      )
      newState.durationSubmitted = calculateAll(result, calcDurationSubmitted, {
        countByAwardedDate: true
      })
      newState.durationInProgress = calculateAll(
        result,
        calcDurationInProgress,
        { countCreatedDate: true }
      )
      // TODO
      // ogarnac czcionki
      // dodac liste
      newState.averageDuration = calculateAll(result, calcAverageDuration, {
        countSubmitDate: true
      })
      newState.awardedProjects = awarded
        .filter(
          filterOpportunitiesByDate({
            ...range_actual,
            countIsValidated: form.countIsValidated
          })
        )
        .filter(isAwarded)
        .sort(sortByString('RecordType', 'Name'))

      // todo handle allocation committe
      newState.highlightData = calcHighlight(result, range_annual, form)
      newState.highlightDataTotal = calcHighlight(result, range_total, form)
      newState.highlightDataActual = calcHighlight(result, range_actual, form)
      newState.endDate = endDate
      newState.startDate = range_actual.start

      newState.fundingByRange = range_actual
      newState.fundingByProvincesCentre = calcFundingByProvinces(
        newState.awardedProjects,
        { priorities: summaryOptions.priorities.CENTRE.key }
      )
      newState.fundingByProvincesCMHC = calcFundingByProvinces(
        newState.awardedProjects,
        { priorities: summaryOptions.priorities.CMHC.key }
      )

      this.setState({ graphData: newState, error: null })
    } catch (e) {
      console.error(e)
      console.log(newState)
      this.setState({ error: e.toString() })
    }
  }

  /*
  Highlights table
  Allocation by Funds - vertical bars
  Geographic Allocation - pie chart

  Centre Priorities $ - bar chart veertical
  Centre Priorities number - bar chart horizontal
  Average Amount Total and per streams - horizontal bar

  Stream Ratios - only on last month - vertical bar



  */
  render () {
    let { classes } = this.props
    let { graphData, error } = this.state
    let loading = !Boolean(graphData && graphData.highlightData)
    console.log(graphData)
    return (
      <Grid container spacing={1} className={classes.mainGrid}>
        <Grid item xs={12} md={8} className={classes.gridItem}>
          Allocation Committee Opportunities
          {!loading ? (
            <div
              style={
                {
                  // display: 'none'
                }
              }
            >
              <AvarageTimeInPipeline
                data={graphData.averageTimeInPipeLine}
                id={'averageTimeInPipeLine'}
              />
              <FundsByFundType
                data={graphData.fundsByFundType.actual.data}
                id={'fundsByFundTypeActual'}
              />
              <FundsByFundType
                data={graphData.fundsByFundType.annual.data}
                id={'fundsByFundTypeAnnual'}
              />
              <FundsByFundType
                data={graphData.fundsByFundType.total.data}
                id={'fundsByFundTypeCumulative'}
              />

              <AverageTimeFromSubmitting
                data={graphData.averageTimeFromSubmitting.data}
                id={'averageTimeFromSubmitting'}
              />

              <GeoDistribution
                data={graphData.geoDistribution.actual.data}
                id={'geoDistributionTotalActual'}
              />
              <GeoDistribution
                data={graphData.geoDistribution.annual.data}
                id={'geoDistributionTotalAnnual'}
              />
              <GeoDistribution
                data={graphData.geoDistribution.total.data}
                id={'geoDistributionTotalCumulative'}
              />

              <GeoDistribution
                data={graphData.geoDistributionCBTI.actual.data}
                id={'geoDistributionCBTIActual'}
              />
              <GeoDistribution
                data={graphData.geoDistributionCBTI.annual.data}
                id={'geoDistributionCBTIAnnual'}
              />
              <GeoDistribution
                data={graphData.geoDistributionCBTI.total.data}
                id={'geoDistributionCBTICumulative'}
              />

              <GeoDistribution
                data={graphData.geoDistributionStfLP.actual.data}
                id={'geoDistributionStfLPActual'}
              />
              <GeoDistribution
                data={graphData.geoDistributionStfLP.annual.data}
                id={'geoDistributionStfLPAnnual'}
              />
              <GeoDistribution
                data={graphData.geoDistributionStfLP.total.data}
                id={'geoDistributionStfLPCumulative'}
              />

              <GeoDistribution
                data={graphData.geoDistributionStfSip.actual.data}
                id={'geoDistributionStfSipActual'}
              />
              <GeoDistribution
                data={graphData.geoDistributionStfSip.annual.data}
                id={'geoDistributionStfSipAnnual'}
              />
              <GeoDistribution
                data={graphData.geoDistributionStfSip.total.data}
                id={'geoDistributionStfSipCumulative'}
              />

              <CentrePrioritiesValue
                data={graphData.priorityAreasCentre.annual.data}
                id={'priorityAreasCentreValueAnnual'}
                options={{ labelsHeight: 90, fontSize: 16 }}
              />
              <CentrePrioritiesValue
                data={graphData.priorityAreasCentre.actual.data}
                id={'priorityAreasCentreValueCurrent'}
                options={{ labelsHeight: 90, fontSize: 16 }}
              />
              <CentrePrioritiesValue
                data={graphData.priorityAreasCentre.total.data}
                id={'priorityAreasCentreValueCumulative'}
                options={{ labelsHeight: 90, fontSize: 16 }}
              />

              <CentrePrioritiesCount
                data={graphData.priorityAreasCentre.annual.data}
                id={'priorityAreasCentreNumberAnnual'}
              />
              <CentrePrioritiesCount
                data={graphData.priorityAreasCentre.actual.data}
                id={'priorityAreasCentreNumberCurrent'}
              />
              <CentrePrioritiesCount
                data={graphData.priorityAreasCentre.total.data}
                id={'priorityAreasCentreNumberCumulative'}
              />

              <CentrePrioritiesValue
                data={graphData.priorityAreasCMHC.annual.data}
                id={'priorityAreasCMHCValueAnnual'}
                // options={{ labelsHeight: 90, fontSize: 36 }}
              />
              <CentrePrioritiesValue
                data={graphData.priorityAreasCMHC.actual.data}
                id={'priorityAreasCMHCValueCurrent'}
                // options={{ labelsHeight: 30, fontSize: 36 }}
              />
              <CentrePrioritiesValue
                data={graphData.priorityAreasCMHC.total.data}
                id={'priorityAreasCMHCValueCumulative'}
                // options={{ labelsHeight: 30, fontSize: 2 }}
              />

              <CentrePrioritiesCount
                data={graphData.priorityAreasCMHC.annual.data}
                id={'priorityAreasCMHCNumberAnnual'}
              />
              <CentrePrioritiesCount
                data={graphData.priorityAreasCMHC.actual.data}
                id={'priorityAreasCMHCNumberCurrent'}
              />
              <CentrePrioritiesCount
                data={graphData.priorityAreasCMHC.total.data}
                id={'priorityAreasCMHCNumberCumulative'}
              />

              {/* Distribution of Consolidated Funding by Centre’s Priorities */}
              <DoubleBarsHorizontalChart
                data={graphData.priorityAreasCentre.actual.data}
                id={'distributionByPrioritiesCurrent'}
                props={{ yAxisTick: 19, yWidth: 200 }}
              />

              <DoubleBarsHorizontalChart
                data={graphData.priorityAreasCentre.annual.data}
                id={'distributionByPrioritiesAnnual'}
                props={{ yAxisTick: 19, yWidth: 200 }}
              />

              <DoubleBarsHorizontalChart
                data={graphData.priorityAreasCentre.total.data}
                id={'distributionByPrioritiesCumulative'}
                props={{ yAxisTick: 19, yWidth: 200 }}
              />

              <TotalAmountAndProjectsNumber
                data={graphData.averageByFund.annual.data}
                id={'totalAmountAndProjectsNumberAnnual'}
              />
              <TotalAmountAndProjectsNumber
                data={graphData.averageByFund.actual.data}
                id={'totalAmountAndProjectsNumberCurrent'}
              />
              <TotalAmountAndProjectsNumber
                data={graphData.averageByFund.total.data}
                id={'totalAmountAndProjectsNumberCumulative'}
              />

              <OpportunitiesByStage
                data={graphData.opportunitiesByStage.annual.data}
                id={'opportunitiesByStageAnnual'}
              />
              <OpportunitiesByStage
                data={graphData.opportunitiesByStage.actual.data}
                id={'opportunitiesByStageActual'}
              />
              <OpportunitiesByStage
                data={graphData.opportunitiesByStage.total.data}
                id={'opportunitiesByStageCumulative'}
              />

              <OpportunitiesByStage
                data={graphData.opportunitiesByProvinceByStage.annual.data}
                id={'opportunitiesByProvinceByStageAnnual'}
                yAxisId={'province'}
              />
              <OpportunitiesByStage
                data={graphData.opportunitiesByProvinceByStage.actual.data}
                id={'opportunitiesByProvinceByStageActual'}
                yAxisId={'province'}
              />
              <OpportunitiesByStage
                data={graphData.opportunitiesByProvinceByStage.total.data}
                id={'opportunitiesByProvinceByStageCumulative'}
                yAxisId={'province'}
              />

              {/* Average value and number of projects  */}
              <DoubleBarsHorizontalChart
                data={graphData.priorityAreasCMHC.annual.data}
                id={'averageAmountByPriorityCMHCAnnual'}
                props={{ yAxisTick: 21, noTotal: true }}
              />
              <DoubleBarsHorizontalChart
                data={graphData.priorityAreasCMHC.actual.data}
                id={'averageAmountByPriorityCMHCCurrent'}
                props={{ yAxisTick: 21, noTotal: true }}
              />
              <DoubleBarsHorizontalChart
                data={graphData.priorityAreasCMHC.total.data}
                id={'averageAmountByPriorityCMHCCumulative'}
                props={{ yAxisTick: 21, noTotal: true }}
              />

              <ApplicationsInProgress
                data={graphData.durationInProgress.annual.data}
                id={'durationInProgressAnnual'}
              />
              <ApplicationsInProgress
                data={graphData.durationInProgress.actual.data}
                id={'durationInProgressActual'}
              />
              <ApplicationsInProgress
                data={graphData.durationInProgress.total.data}
                id={'durationInProgressCumulative'}
              />

              <ApplicationsInProgress
                data={graphData.durationSubmitted.annual.data}
                id={'durationSubmittedAnnual'}
                noTotal={true}
              />
              <ApplicationsInProgress
                data={graphData.durationSubmitted.actual.data}
                id={'durationSubmittedActual'}
                noTotal={true}
              />
              <ApplicationsInProgress
                data={graphData.durationSubmitted.total.data}
                id={'durationSubmittedCumulative'}
                noTotal={true}
              />

              <ApplicationAverageDuration
                data={graphData.averageDuration.annual.data}
                id={'averageDurationAnnual'}
              />
              <ApplicationAverageDuration
                data={graphData.averageDuration.actual.data}
                id={'averageDurationActual'}
              />
              <ApplicationAverageDuration
                data={graphData.averageDuration.total.data}
                id={'averageDurationCumulative'}
              />
            </div>
          ) : (
            <LoadingPlaceholder loading={loading}/>
          )}
          {error ? (
            <div style={{ color: 'red' }}>
              <Typography> Error: </Typography>
              {error}
            </div>
          ) : (
            ''
          )}
        </Grid>
        <Grid item xs={12} md={4} className={classes.gridItem}>
          Allocation Committee menu
          <AllocationForm
            parentHook={data => {
              this.setState({
                form: data
              })
            }}
          />
          {/*<FormControl fullWidth>*/}
          {/*  <InputLabel id="demo-simple-select-readonly-label">*/}
          {/*    Start Date*/}
          {/*  </InputLabel>*/}
          {/*  /!*<DatePicker value={fields.startDate} onChange={this.handleFieldChange('startDate')}/>*!/*/}
          {/*</FormControl>*/}
          <Button
            fullWidth
            variant={'contained'}
            color={'primary'}
            disabled={loading}
            onClick={() => {
              console.log(this.state.graphData)
              generateAllocationCommitteePDF(generateDocumentParts(this.state))
            }}
          >
            Get Allocation Committee PDF
          </Button>
          <hr/>
          <Button
            fullWidth
            variant={'contained'}
            color={'primary'}
            disabled={loading}
            onClick={() => {
              console.log(this.state)
              generateCMHCQuaterlyPDF(generateDocumentParts(this.state))
            }}
          >
            Get CMHC Quarterly Report PDF
          </Button>
        </Grid>
      </Grid>
    )
  }
}

const mapState = (state, ownProps) => {
  return {
    oauth2: state.oauth2
  }
}

const mapDispatch = dispatch => ({
  // reLogin: reLogin(dispatch),
  // getSchema: () => dispatch(schemaClientGet()),
})

const AllocationCommittee = withStyles(styles)(
  connect(mapState, mapDispatch)(AllocationCommitteeInner)
)

export default AllocationCommittee
export { valueShortened }
