/**
 * Created by Michał Stawski on 29.02.2020.
 */
import React from 'react'
import { Cell, Legend, Pie, PieChart } from 'recharts'
import { WrappedRechart } from './rechartToSVG'

const RADIAN = Math.PI / 180
const renderCustomizedLabel = ({
                                 cx, cy, midAngle, innerRadius, outerRadius, percent, index,
                               }) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5
  const x = cx + radius * Math.cos(-midAngle * RADIAN)
  const y = cy + radius * Math.sin(-midAngle * RADIAN)

  return (
    <text x={x} y={y} fill="black" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  )
}

const COLORS = ['#4471C4', '#EC7C30', '#A4A4A3', '#FFC000', '#5B9BD4']

const exampleData = [
  { name: 'Group A', value: 400 },
  { name: 'Group B', value: 300 },
  { name: 'Group C', value: 300 },
  { name: 'Group D', value: 200 },
]

class AllocationByPriotity extends WrappedRechart {

  render () {
    let { width, height, data=exampleData, id, dataKey='count'} = this.props

    return <PieChart width={width} height={height}
                     ref={(chart) => {
                       this.chartRef = chart
                     }}
    >
      <Pie
        isAnimationActive={false}
        id={id}
        data={data}
        // labelLine={false}
        // label={renderCustomizedLabel}
        label
        // outerRadius={150}
        // fill="#8884d8"
        dataKey={dataKey}
      >
        {
          exampleData.map((entry, index) => <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]}/>)
        }
      </Pie>
      <Legend/>
    </PieChart>
  }
}

export { AllocationByPriotity, renderCustomizedLabel, exampleData, COLORS }
