/**
 * Created by Michał Stawski on 26.06.2020.
 */
import { isCompletedStage, isGranteeStage, isSubmitted, oppToStageTimes, oppToValue } from './commonCalc'
import moment from 'moment'

const stageLengths = [
  {
    name: 'More than 120 days',
    start: 121,
    color: '#003EFA',
    order: 12
  },
  {
    name: '91 to 120 days',
    start: 91,
    color: '#FABD00',
    order: 9
  },
  {
    name: '61 to 90 days',
    start: 61,
    color: '#95BF28',
    order: 6
  },
  {
    name: '30 to 60 days',
    start: 30,
    color: '#EF760A',
    order: 3
  },
  {
    name: 'Less then 30 days',
    start: -1,
    color: '#1EB7A4',
    order: 0
  }
]

const o = [
  {
    OpportunityHistories: {
      totalSize: 11,
      done: true,
      records: [
        {
          attributes: {
            type: 'OpportunityHistory',
            url:
              '/services/data/v42.0/sobjects/OpportunityHistory/0085X000003DcaMQAS'
          },
          CreatedDate: '2020-06-03T13:20:46.000+0000',
          StageName: 'Operational'
        },
        {
          attributes: {
            type: 'OpportunityHistory',
            url:
              '/services/data/v42.0/sobjects/OpportunityHistory/0085X000003DTTOQA4'
          },
          CreatedDate: '2020-06-01 T15:28:56.000+0000',
          StageName: 'Contracted'
        },
        {
          attributes: {
            type: 'OpportunityHistory',
            url:
              '/services/data/v42.0/sobjects/OpportunityHistory/0085X00000397YfQAI'
          },
          CreatedDate: '2020-03-12T18:32:16.000+0000',
          StageName: 'Awarded'
        },
        {
          attributes: {
            type: 'OpportunityHistory',
            url:
              '/services/data/v42.0/sobjects/OpportunityHistory/0085X000003953VQAQ'
          },
          CreatedDate: '2020-03-12T13:12:57.000+0000',
          StageName: 'Executive Director Review'
        },
        {
          attributes: {
            type: 'OpportunityHistory',
            url:
              '/services/data/v42.0/sobjects/OpportunityHistory/0085X00000397E1QAI'
          },
          CreatedDate: '2020-03-12T17:42:51.000+0000',
          StageName: 'Validated'
        },
        {
          attributes: {
            type: 'OpportunityHistory',
            url:
              '/services/data/v42.0/sobjects/OpportunityHistory/0085X0000038dItQAI'
          },
          CreatedDate: '2020-02-20T21:40:56.000+0000',
          StageName: 'Submitted'
        },
        {
          attributes: {
            type: 'OpportunityHistory',
            url:
              '/services/data/v42.0/sobjects/OpportunityHistory/0085X0000038mbDQAQ'
          },
          CreatedDate: '2020-03-03T21:21:10.000+0000',
          StageName: 'In Depth Review'
        },
        {
          attributes: {
            type: 'OpportunityHistory',
            url:
              '/services/data/v42.0/sobjects/OpportunityHistory/0085X0000038dJwQAI'
          },
          CreatedDate: '2020-02-20T21:51:17.000+0000',
          StageName: 'First Review'
        },
        {
          attributes: {
            type: 'OpportunityHistory',
            url:
              '/services/data/v42.0/sobjects/OpportunityHistory/0085X0000038lESQAY'
          },
          CreatedDate: '2020-03-02T19:56:27.000+0000',
          StageName: 'Submitted'
        },
        {
          attributes: {
            type: 'OpportunityHistory',
            url:
              '/services/data/v42.0/sobjects/OpportunityHistory/0085X0000038hGZQAY'
          },
          CreatedDate: '2020-02-26T17:30:02.000+0000',
          StageName: 'More info required'
        },
        {
          attributes: {
            type: 'OpportunityHistory',
            url:
              '/services/data/v42.0/sobjects/OpportunityHistory/0085X0000038N9wQAE'
          },
          CreatedDate: '2020-01-24T19:48:25.000+0000',
          StageName: 'In Progress'
        }
      ]
    }
  }
]

const stageLengthToFill = value => {
  for (const stage of stageLengths) {
    if (stage.name === value) {
      return stage.color
    }
  }
  return '#40BAD2'
}

const lengthToLabel = length => {
  for (const l of stageLengths) {
    if (length >= l.start) {
      return { stageDuration: l.name, stageOrder: l.order }
    }
  }
  return {
    stageDuration: stageLengths[stageLengths.length - 1].name,
    stageOrder: stageLengths[stageLengths.length - 1].order
  }
}

const resToStageDuration = (res, endDate) => {
  const diff = endDate.diff(moment.utc(res.CreatedDate), 'days')
  if (!diff) {
    return 1
  }
  return diff
  // )
  // return 10
  // res.StageDuration
  // submit date
}

const calcDurationSubmitted = (result, { end }) => {
  const calculated = {
    Total: {
      value: 0,
      count: 0,
      order: 99
    }
  }
  const endDate = end
  console.log('calcDurationSubmitted', endDate)

  const total = 0
  const noData = 0
  let stageDuration
  let stageOrder
  let stageLabel
  let sortedStages, stageDateStart, stageDateEnd

  for (const res of result) {
    if (!isSubmitted(res) || moment.utc(res.CreatedDate).isAfter(endDate)) {
      // console.log('calcDurationSubmitted, PASSED not submitted', res.Name, res.StageName)
      continue
    }

    sortedStages = res.OpportunityHistories.records.sort(sortByCreatedDate)
    // console.log('sortedStages', sortedStages)
    let i
    for (i = 0; i < sortedStages.length; i++) {
      if (sortedStages[i].StageName === 'Submitted') {
        stageDateStart = moment.utc(sortedStages[i].CreatedDate)
        break
      }
    }
    if (i >= sortedStages.length) {
      // no submitted
      // console.log('calcDurationSubmitted, PASSED no submitted?', res.Name, res.StageName)
      continue
    }

    for (i = 0; i < sortedStages.length; i++) {
      if (sortedStages[i].StageName === 'Awarded') {
        stageDateEnd = moment.utc(sortedStages[i].CreatedDate)
        break
      }
    }

    if (i >= sortedStages.length) {
      // still processing
      // console.log('calcDurationSubmitted, PASSED not awarded', res.Name, res.StageName)
      continue
    }

    const diff = stageDateEnd.diff(stageDateStart, 'days')
    stageLabel = lengthToLabel(diff)
    stageDuration = stageLabel.stageDuration
    stageOrder = stageLabel.stageOrder

    if (!calculated[stageDuration]) {
      calculated[stageDuration] = {
        value: 0,
        count: 0,
        order: stageOrder
      }
    }
    calculated[stageDuration].value += diff
    calculated[stageDuration].count += 1

    calculated.Total.value += diff
    calculated.Total.count += 1
  }

  let data = []

  for (const type in calculated) {
    // for (let duration in calculated[type]) {
    data.push({
      name: type,
      // status: duration,
      fill: stageLengthToFill(type),
      value: calculated[type].count,
      order: calculated[type].order
    })
    // }
  }
  data.push({
    name: 'Average number of days to resolve application',
    fill: '#7F211E',
    value:
      calculated.Total.count !== 0
        ? calculated.Total.value / calculated.Total.count
        : 0,
    order: 100
  })
  data = data.sort((a, b) => a.order - b.order)
  console.log('calcDurationSubmitted', data, calculated)
  return { data, total, noData }
}

const calcDurationInProgress = (result, { end }) => {
  const calculated = {
    Total: {
      value: 0,
      count: 0,
      order: 99
    }
  }
  const endDate = end

  const total = 0
  const noData = 0
  let stageDuration
  let stageOrder
  let stageLabel
  for (const res of result) {
    if (isSubmitted(res) || moment.utc(res.CreatedDate).isAfter(endDate)) {
      continue
    }

    stageLabel = lengthToLabel(resToStageDuration(res, endDate))
    stageDuration = stageLabel.stageDuration
    stageOrder = stageLabel.stageOrder

    if (!calculated[stageDuration]) {
      calculated[stageDuration] = {
        // Total:{
        value: 0,
        count: 0,
        order: stageOrder
        // }
      }
      // if (!calculated[stage][stageDuration])
      //   calculated[stage][stageDuration] = {
      //     value: 0,
      //     count: 0
      //   }
      // if (!calculated['Total'][stageDuration])
      //   calculated['Total'][stageDuration] = {
      //     value: 0,
      //     count: 0
      //   }
    }
    calculated[stageDuration].value += oppToValue(res)
    calculated[stageDuration].count += 1

    // calculated[stage]['Total'].value += oppToValue(res)
    // calculated[stage]['Total'].count += 1

    calculated.Total.value += oppToValue(res)
    calculated.Total.count += 1
  }

  let data = []

  for (const type in calculated) {
    // for (let duration in calculated[type]) {
    data.push({
      name: type,
      // status: duration,
      fill: stageLengthToFill(type),
      value: calculated[type].count,
      order: calculated[type].order
    })
    // }
  }
  data = data.sort((a, b) => a.order - b.order)
  console.log(data, calculated)
  return { data, total, noData }
}

const GRANTEE_STAGE = 'Grantee average total time'
const CHTC_STAGE = 'Centre average total time'
const processOrder = {
  'Grantee Average': 0,
  'CHTC Average': 1,
  Submitted: 2,
  Hold: 3,
  Withdrawn: 4,
  'First Review': 5,
  'More info required': 6,
  'In Depth Review': 7,
  'External Review': 8,
  'Executive Director Review': 9,
  Validated: 10,
  Awarded: 11,
  Declined: 12,
  Contracted: 13,
  Operational: 14,
  'Special scrutiny': 15,
  Finished: 16,
  Terminated: 17
}

export function sortByCreatedDate (a, b) {
  return moment.utc(a.CreatedDate).diff(moment.utc(b.CreatedDate))
}

const calcAverageTimeFromSubmitting = result => {
  const calculated = []

  const startDate = moment.utc('19-12-2019', 'DD-MM-YYYY')
  const endDate = moment.utc()

  while (endDate > startDate || startDate.format('M') === endDate.format('M')) {
    calculated.push({
      name: startDate.format('YYYY-MM'),
      month: moment(startDate.format('YYYY-MM')),
      total: 0,
      num: 0
    })
    startDate.add(1, 'month')
  }

  for (const res of o) {
    console.log(res)
    let records
    if (res.OpportunityHistories) {
      records = res.OpportunityHistories.records
      // records.sort(sortByCreatedDate);
      records = records.sort(
        (a, b) =>
          new moment(a.CreatedDate).format('YYYYMMDD') -
          new moment(b.CreatedDate).format('YYYYMMDD')
      )

      let days = 0
      let recordIndex = 0
      let currRecord = records[0]
      console.log(records)

      for (let m = 0; m < calculated.length; m++) {
        if (calculated[m].month.isBefore(records[0].CreatedDate)) {
          continue
        } else {
          console.log(m, recordIndex)
          while (
            calculated[m].month.isAfter(currRecord.CreatedDate) &&
            recordIndex !== records.length
            ) {
            console.log(recordIndex)
            days = moment(calculated[m].month).diff(
              currRecord.CreatedDate,
              'days'
            )
            console.log(days)
            calculated[m].total = days
            if (
              currRecord.StageName === 'Contracted' ||
              currRecord.StageName === 'Declined' ||
              currRecord.StageName === 'Withdrawn'
            ) {
              console.log('HIT', days)
              m = calculated.length
              break
            }

            recordIndex++
            if (recordIndex === records.length) {
              calculated[m].num++
              break
            }
            currRecord = records[recordIndex]
          }
        }
      }

      // for (const obj of calculated) {
      //   obj.total = obj.total / obj.num;
      // }

      console.log(calculated)
      return { calculated }
    }
  }
}

const dayLenght = 24*60*60

const calcAverageDuration = (result, { end }) => {
  const calculated = {}
  calculated[GRANTEE_STAGE] = {
    value: 0,
    count: 1,
    duration: 0
  }
  calculated[CHTC_STAGE] = {
    value: 0,
    count: 1,
    duration: 0
  }

  const endDate = end
  const endDuration = moment.utc()
  const total = 0
  const noData = 0
  let stage
  let stageDuration
  let stageType

  let opportunityTimes = []

  for (const res of result) {
    if (!isSubmitted(res)) {
      continue
    }
    let opportunityStages = oppToStageTimes(res, endDuration, 'seconds')
    for (const duration of opportunityStages) {
      // console.log(duration)
      if (isGranteeStage(duration.StageName)) {
        stageType = GRANTEE_STAGE
      } else {
        stageType = CHTC_STAGE
      }
      // stage = 'Completed Projects'
      stage = duration.StageName

      if (!calculated[stage]) {
        calculated[stage] = {
          value: 0,
          count: 0,
          duration: 0
        }
      }
      calculated[stage].count += 1
      calculated[stage].duration += duration.days

      // calculated[stageType].count += 1
      // calculated[stageType].duration += duration.days
    }
    opportunityTimes.push({
      name: res.Name,
      opportunityStages
    })
    // calculated[stage]['Total'].value += oppToValue(res)
    // calculated[stage]['Total'].count += 1
  }
  console.log('opportunity Times', opportunityTimes)
  console.log('opportunity Times string', JSON.stringify(opportunityTimes))

  let data = []
  let name


  for (let type in calculated) {
    name = type
    if (type === 'Total') {
      name = 'Total Average'
    }
    if (name === 'In Progress') {
      name = 'Under Redaction'
    }
    // console.log(processOrder[type])
    if (isCompletedStage(type) || type=== 'Awarded'){
      continue
    }
      if (type === GRANTEE_STAGE || type === CHTC_STAGE){
        continue
      }
    data.push({
      name,
      order: processOrder[type],
      value: calculated[type].count
        ? calculated[type].duration / calculated[type].count/dayLenght
        : 0,
      fill:
        isGranteeStage(type) || type === GRANTEE_STAGE ? '#95BF28' : '#FABD00',
      category:
        isGranteeStage(type) || type === GRANTEE_STAGE
          ? GRANTEE_STAGE
          : CHTC_STAGE
    })
  }
  let stagesCountedCHTC = new Set()
  let stagesCountedGrantee = new Set()
  let grantee=0, chtc=0
  for (let d of data) {
    let type = d.name
    if (type === GRANTEE_STAGE || type === CHTC_STAGE){
      continue
    }
    if (isGranteeStage(type)) {
      stagesCountedGrantee.add(type)
      grantee += d.value
    } else {
      stagesCountedCHTC.add(type)
      chtc += d.value
    }
  }
  data.push({
    name: GRANTEE_STAGE,
    value: grantee,
    category: GRANTEE_STAGE,
    fill: '#95BF28'
  })
  data.push({
    name: CHTC_STAGE,
    value: chtc,
    category:CHTC_STAGE,
    fill: '#FABD00'
  })
  // console.log('counted Stages', stagesCountedCHTC, stagesCountedGrantee)
  data = data.sort((a, b) => a.order - b.order)
  return { data, total, noData }
}

export {
  calcDurationInProgress,
  calcAverageTimeFromSubmitting,
  calcDurationSubmitted,
  calcAverageDuration,
  stageLengthToFill
}
