import { provinceMapping } from './calcGeoDistribution'
import { oppToState } from './commonCalc'

/**
 * Created by Michał Stawski on 17.03.2020.
 */

const calcNumberOfTenants = (result) => {
  let calculated = {}
  let total = 0, noData = 0

  for (let res of result) {
    if (res.Account.BillingAddress) {
      res.state = oppToState(res)
      if (!calculated[res.state]) {
        calculated[res.state] = {
          value: 0
        }
      }
      calculated[res.state].value += res.Number_of_tenants__c
    }else{
      noData++
    }
  }

  let data = []
  for (let org in calculated) {
    data.push({
      name: org,
      ...calculated[org]
    })
  }

  return { data, total, noData }
}
export default calcNumberOfTenants
