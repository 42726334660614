/**
 * Created by Michał Stawski on 11.02.2020.
 */
import React from 'react'
import { withStyles } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import { connect } from 'react-redux'
import Paper from '@material-ui/core/Paper'
import { Route, Switch, withRouter } from 'react-router'
import ExecutiveSummary from './pdfcomponents/ExecutiveSummary'
import PieChartIcon from '@material-ui/icons/PieChart'
import SubjectIcon from '@material-ui/icons/Subject'
import ListAltIcon from '@material-ui/icons/ListAlt'
import PublicIcon from '@material-ui/icons/Public'
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import List from '@material-ui/core/List'
import ListItemLink from '../common/components/ListItemLink'
import DashboardSummary from './pdfcomponents/DashboardSummary'
import ListsSummary from './pdfcomponents/ListsSummary'
import GeographicSummary from './pdfcomponents/GeographicSummary'
import AllocationCommittee from './pdfcomponents/AllocationCommittee'

const styles = theme => ({
  mainGrid: {
    height: '100%'
  },
  root: {
    flexGrow: 1,
    padding: 10,
    maxHeight: 'calc(100vh - 64px)',
    // overflow:'hidden'
  },
  paper: {
    padding: theme.spacing(1),
    height: 'calc(100vh - 80px)',
    // overflowY: 'scroll',
  },
  tableContainer: {
    // TODO change to calc
    height: 'calc(100% - 92px)'

  },
  gridItem: {
    maxHeight: '100%'
  },
  gridHeader: {
    textAlign: 'center'
  },
  formControl: {
    margin: theme.spacing(3),
  },

})

class PdfTabInner extends React.Component {
  render () {
    let { classes, match } = this.props
    return <div className={classes.root}>
      <Grid container spacing={1} className={classes.mainGrid}
            alignItems={'stretch'}
      >
        <Grid item xs={12} sm={2} md={3} className={classes.gridItem}>
          <Paper className={classes.paper}>
            <List component="nav" aria-label="main mailbox folders">
              <ListItemLink to={`${match.url}/executiveSummary/`} primary="Executive Summary" icon={<SubjectIcon/>}/>
              <ListItemLink to={`${match.url}/dashboard/`} primary="Dashboard" icon={<PieChartIcon/>}/>
              <ListItemLink to={`${match.url}/lists/`} primary="Lists" icon={<ListAltIcon/>}/>
              <ListItemLink to={`${match.url}/geoFunding/`} primary="Funding" icon={<PublicIcon/>}/>
              <ListItemLink to={`${match.url}/allocationCommittee/`} primary="Periodic Reports" icon={<CalendarTodayIcon/>}/>
            </List>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={10} md={9} className={classes.gridItem}>
          <Switch>
            <Route key={'executiveSummary'} exact path={`${match.path}/executiveSummary/`}
                   component={ExecutiveSummary}/>
            <Route key={'dashboard'} exact path={`${match.path}/dashboard/`}
                   component={DashboardSummary}/>
            <Route key={'lists'} exact path={`${match.path}/lists/`}
                   component={ListsSummary}/>
            <Route key={'geoFunding'} exact path={`${match.path}/geoFunding/`}
                   component={GeographicSummary}/>
            <Route key={'allocationCommittee'} exact path={`${match.path}/allocationCommittee/`}
                   component={AllocationCommittee}/>
          </Switch>
        </Grid>
      </Grid>
    </div>
  }
}

const mapState = (state, ownProps) => {
  return {
    oauth2: state.oauth2,
  }
}

const mapDispatch = dispatch => ({
  // reLogin: reLogin(dispatch),
  // getSchema: () => dispatch(schemaClientGet()),

})

const PdfTab = withStyles(styles)(connect(mapState, mapDispatch)(withRouter(PdfTabInner)))

export default PdfTab
