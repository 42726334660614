/**
 * Created by Michał Stawski on 31.03.2020.
 */
import { normalizeGeoScopeDetaile, provinceReversMapping } from '../../../jsforceApi/opportunities'
import { summaryOptions } from '../../../components/pdfExport'
import { oppToPriorityCMHC } from './calcPriorityAreas'
import { oppToValue } from './commonCalc'

const provinceRow = () => {
  let row = {}

  row.total = {
    value: 0,
    count: 0
  }

  for (let province in provinceReversMapping) {
    row[provinceReversMapping[province]] = {
      value: 0,
      count: 0
    }
  }
  return row
}
const calcFundingByProvinces = (result, options) => {
  let calculated = {
    total: {}
  }
  let states, fund, priorities = [], value

  console.log('priority calcFundingByProvinces ', result)
  for (let res of result) {
    fund = res.RecordType.Name
    value = oppToValue(res)

    states = normalizeGeoScopeDetaile(res)
    if (options.priorities === summaryOptions.priorities.CMHC.key) {
      priorities = oppToPriorityCMHC(res)
    } else {
      if (res.Priority_areas__c) {
        priorities = res.Priority_areas__c.split(';')
      }
    }

    if (!calculated[fund]) {
      calculated[fund] = {}
    }
    for (let priority of priorities) {
      if (!calculated[fund][priority]) {
        calculated[fund][priority] = provinceRow()
      }
      if (!calculated.total[priority]) {
        calculated.total[priority] = provinceRow()
      }

      for (let state of states) {
        calculated[fund][priority][state].value += value
        calculated[fund][priority][state].count += 1
        calculated['total'][priority][state].value += value
        calculated['total'][priority][state].count += 1
      }
      calculated[fund][priority]['total'].value += value
      calculated[fund][priority]['total'].count += 1
      calculated['total'][priority]['total'].value += value
      calculated['total'][priority]['total'].count += 1
    }
  }

  // console.log(calculated)
  return { table: calculated }
}

export { calcFundingByProvinces }
