import { opportunityFields } from '../components/pdfExport'
import { provinceMapping } from '../tabs/pdfcomponents/calc/calcGeoDistribution'
import * as moment from 'moment'
import { oppToState } from '../tabs/pdfcomponents/calc/commonCalc'

/**
 * Created by Michał Stawski on 11.02.2020.
 */

const filterOpportunities = (result) => result ? result.filter(res => {
  let name = res.Name.toUpperCase()
  let legalName
  if (res.Account) {
    legalName = res.Account.Name.toUpperCase()
  } else {
    //console.warn('no account', res)
    legalName = 'NO ACCOUNT'
  }

  return !(name.includes('FAKE') || name.includes('TEST') ||
    legalName.includes('SOLJIT') || res.Owner.Alias === 'iHa'||
      res.RecordType.Name === 'TRA'
  )
}) : []

const filterOpportunitiesByDate = (
  {
    start,
    end,
    countIsValidated = false,
    countCreatedDate = false,
    countSubmitDate = false,
    countByAwardedDate = false,
  }) => (result) => {
  let date
  if (countByAwardedDate) {
    date = result.firstAwardedDate
  } else if (countSubmitDate) {
    date = result.Submit_Date__c
  } else if (countCreatedDate) {
    date = result.CreatedDate
  } else if (countIsValidated) {
    date = result.isvalidated__c ? result.isvalidated__c : result.Contract_Signed_Date__c
  } else {
    date = result.Contract_Signed_Date__c
  }
  if (start) {
    return moment.utc(date).isBetween(start, end, 'day', '[]')
  } else {
    return moment.utc(date).isSameOrBefore(end, 'day')
  }
}

const START_OF_RANGES = moment.utc('2019-12-13')

const getOpportunities = (conn) => {
  let getFields = opportunityFields.map(item => item.field)
  getFields = getFields.filter(item => item !== '')

  getFields.push('Id')

  return conn.sobject('Opportunity')
    .select(getFields.join(',') + ', Campaign.Name, Campaign.StartDate, Campaign.EndDate, Account.Name, ' +
      'RecordType.Name, Account.Account_Type__c, Account.BillingAddress, ' +
      'Owner.Alias,' +
      ' Budget__r.FGM_Base__Total_Budget__c, Budget__r.Name, ' +
      ' Accounting_Contact__c,' +
      ' isvalidated__c, Contract_Signed_Date__c, Submit_Date__c, CreatedDate, Account.Type')

    .include('Objectives__r')
    .select('Objective__c.Outcomes__c, Objective__c.Identified_needs__c, Objective__c.Objective__c')
    .end()

    .include('FGM_Base__Benchmarks__r')
    .select('FGM_Base__Due_Date__c, FGM_Base__Status__c,  FGM_Base__Completion_Date__c')
    .end()

    .include('FGM_Base__Reviews__r')
    .select('CMHC_Priority_areas_touched__c, RecordType.Name, FGM_Base__Review_Date__c, FGM_Base__Status__c ')
    .end()

    .include('OpportunityHistories')
    .select('CreatedDate, StageName')
    // .where({
    //   StageName: 'Submitted'
    // })
    .end()

    // .include('Opportunity__History ')
    // .select('OldValue, NewValue, Field, CreatedDate')
    // .where(
    //   {
    //     Field: 'StageName',
    //     OldValue: 'InProgress'
    //   })
    // .end()
    .autoFetch(true)
}

function getHistory (conn, id) {
  return conn.sobject('Opportunity__History')
    .select('OldValue, NewValue, Field, CreatedDate, ParentId')
    .where({
      Field: 'StageName',
      OldValue: 'InProgress',
      ParentId: id
    })
    .autoFetch(true)
}

function getHistory2 (conn) {
  return conn.sobject('OpportunityFieldHistory')
    .select('OldValue, NewValue, Field, CreatedDate, OpportunityId')
    // .where({
    // Field: 'StageName',
    // OldValue: 'InProgress',
    // OpportunityId : id
    // })
    .autoFetch(true)
}

const opportunityToPriorities = (res) => {
  let unrecognized = {}
  if (res.FGM_Base__Reviews__r) {
    // TODO Use only newest record,
    // let records = res.FGM_Base__Reviews__r.records.sort( (a,b) => {
    //   moment.utc(a.FGM_Base__Review_Date__c).
    // })
    for (let review of res.FGM_Base__Reviews__r.records) {
      if (review.RecordType.Name === 'Depth Review STF - Sectoral' ||
        review.RecordType.Name === 'Depth Review STF - Local' ||
        review.RecordType.Name === 'Depth Review CBTIF'
      ) {
        return review.CMHC_Priority_areas_touched__c || ''
      } else {
        // console.warn(' unrecognized review types', res, review)
        unrecognized[review.RecordType.Name] = true
      }
    }
  }
  return ''
}

const provinceReversMapping = {
  'Newfoundland and Labrador': 'NL',
  'Prince Edward Island': 'PE',
  'Nova Scotia': 'NS',
  'New Brunswick': 'NB',
  'Québec': 'QC',
  'Quebec': 'QC',
  'Ontario': 'ON',
  'Manitoba': 'MB',
  'Saskatchewan': 'SK',
  'Alberta': 'AB',
  'British Columbia': 'BC',
  'Yukon': 'YT',
  'Northwest Territories': 'NT',
  'Nunavut': 'NU',
  'Canada': 'CA', // projects across canada
}

const provinceToData = (province) => {
  return provinceMapping[provinceReversMapping[province]]
}

export const provinceToIso = (province) => {
  return provinceReversMapping[province]
}
const normalizeProvinceToAbrrev = res => {
  return provinceReversMapping[oppToState(res)]
}

const normalizeGeoScopeDetaile = res => {
  if (res.Geographical_scope_detailed__c) {
    return res.Geographical_scope_detailed__c.split(';').map(item => provinceReversMapping[item])
  }
  return []
}

export {
  normalizeProvinceToAbrrev,
  getOpportunities,
  getHistory,
  getHistory2,
  filterOpportunities,
  filterOpportunitiesByDate,
  opportunityToPriorities,
  normalizeGeoScopeDetaile,
  provinceReversMapping,
  provinceToData,
  START_OF_RANGES
}
