/**
 * Created by Michał Stawski on 17.03.2020.
 */


// const exampleData = [
//   {
//     status: 'In Progress',
//     projectType: 'CBTF',
//     value: 300000,
//   },
//   {
//     status: 'Declined',
//     projectType: 'CBTF',
//     value: 10000
//   },
//   {
//     status: 'Validated',
//     projectType: 'CBTF',
//     value: 100444
//   },
//   {
//     status: 'More Info Required',
//     projectType: 'CBTF',
//     value: 0
//   },
//   {
//     status: 'In Progress',
//     projectType: 'STF - Local Project',
//     value: 865611,
//   },
//   {
//     status: 'Submitted',
//     projectType: 'STF - Local Project',
//     value: 10000
//   },
//   {
//     status: 'Validated',
//     projectType: 'STF - Local Project',
//     value: 24489
//   },
//   {
//     status: 'More Info Required',
//     projectType: 'STF - Local Project',
//     value: 149923
//   },
//   {
//     status: 'Submitted',
//     projectType: 'STF - Sectoral Impact',
//     value: 2126465
//   },
//   {
//     status: 'Validated',
//     projectType: 'STF - Sectoral Impact',
//     value: 0
//   },
//   {
//     status: 'More Info Required',
//     projectType: 'STF - Sectoral Impact',
//     value: 72800
//   },
// ]

// const exampleTable = [
//   // stageName, amount CBTIF, count CBTIF, sum STF LP, count STF LP, sum STF-SI, count STF-IS,
//   // sum total, count total
//
//   ['In Progress', 278860, 11, 856611.52, 15, 2126475, 10, 3270946.52, 36],
//   ['Submitted', 278860, 11, 856611.52, 15, 2126475, 10, 3270946.52, 36],
//   ['First Review', 278860, 11, 856611.52, 15, 2126475, 10, 3270946.52, 36],
//   ['More info required', 278860, 11, 856611.52, 15, 2126475, 10, 3270946.52, 36],
// ]

const calcOpportunitiesByFundingLevel = (result) => {
  let calculated = {

    // total, <50K, 50K<= <=100K, >100K
    total: {
      name: 'Total',
      data_a: [0, 0, 0, 0, 0],
      data_r: [0, 0, 0, 0, 0]
    },
    stf_l: {
      name: 'STFLP',
      data_a: [0, 0, 0, 0, 0],
      data_r: [0, 0, 0, 0, 0]
    },
    stf_s: {
      name: 'STFSI',
      data_a: [0, 0, 0, 0, 0],
      data_r: [0, 0, 0, 0, 0]
    },
    cbitf: {
      name: 'CBTI',
      data_a: [0, 0, 0, 0, 0],
      data_r: [0, 0, 0, 0, 0]
    },
  }
  for (let res of result) {
    if (res.RecordType.Name === 'Sector Transformation Fund – Sectoral Impact') {
      res.fund = 'stf_s'
    } else if (res.RecordType.Name === 'Sector Transformation Fund – Local Project') {
      res.fund = 'stf_l'
    } else if (res.RecordType.Name === 'Community-Based Tenant Initiative Fund (CBTIF)') {
      res.fund = 'cbitf'
    }
    if (res.FGM_Base__Amount_Requested__c < 50000) {
      res.fundingValue = 0
    } else if (res.FGM_Base__Amount_Requested__c <= 100000) {
      res.fundingValue = 1
    } else {
      res.fundingValue = 2
    }
    if (res.StageName === 'Awarded') {
      res.awardedState = '_a'
    } else {
      res.awardedState = '_r'
    }

    calculated[res.fund]['data' + res.awardedState][0] += 1
    calculated[res.fund]['data' + res.awardedState][res.fundingValue + 1] += 1

    calculated.total['data' + res.awardedState][0] += 1
    calculated.total['data' + res.awardedState][res.fundingValue + 1] += 1
  }
  let data = []
  let table = []

  let row
  for (let stage in calculated) {
    row = calculated[stage]
    row.awardedState = stage.split('_')[1]
    data.push({
      status: 'Total',
      value: row.data_a[0],
      value2: row.data_r[0],
      projectType: row.name
    })
    data.push({
      status: '< 50K',
      value: row.data_a[1],
      value2: row.data_r[1],
      awarded: row.awardedState,
      projectType: row.name
    })
    data.push({
      status: '50K x <100K ',
      value: row.data_a[2],
      value2: row.data_r[2],
      projectType: row.name
    })
    data.push({
      status: '> 100K',
      value: row.data_a[3],
      value2: row.data_r[3],
      projectType: row.name
    })
  }
  // console.log(data)
  // data = data.filter(item => item.value)
  return { data, table }
}
export default calcOpportunitiesByFundingLevel

