/**
 * Created by Michał Stawski on 01.02.2020.
 */
import React from 'react'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import { oauth2 } from '../App'

const doLogin = () => {
  // jsForce.browser.init({
  //   clientId: oauth2.clientId,
  //   loginUrl: 'https://chtc-ctlc--pc.my.salesforce.com',
  //   redirectUri: oauth2.redirectUri,
  //   clientSecret: oauth2.clientSecret,
  //   scope: 'web refresh_token api id',
  // }
  // console.log(oauth2.getAuthorizationUrl({ scope: 'api id web' }))
  window.location = oauth2.getAuthorizationUrl({
    response_type: 'token',
    scope: 'web api id'
  })
  // jsForce.browser.login({}, (res) =>{
  //   console.log('logged in', res)
  //   }
  // )
  // app.get('/oauth2/auth', function (req, res) {
  //   res.redirect(oauth2.getAuthorizationUrl({ scope: 'api id web' }))
  // })

  // conn.login(login, password, function (err, userInfo) {
  //   if (err) { return console.error(err) }
  //   // Now you can get the access token and instance URL information.
  //   // Save them to establish connection next time.
  //   console.log(conn.accessToken)
  //   console.log(conn.instanceUrl)
  //   // logged in user property
  //   console.log('User ID: ' + userInfo.id)
  //   console.log('Org ID: ' + userInfo.organizationId)
  //
  // })
  // generatePDF()

}

class LoginButton extends React.Component {
  render () {
    return <Paper>
      <Button
        fullWidth
        variant="text"
        color="primary"
        // disabled={!isValid}
        onClick={doLogin}
      >
        Login to salesforce
      </Button>
    </Paper>
  }

}

export default LoginButton
