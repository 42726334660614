/**
 * Created by Michał Stawski on 18.02.2020.
 */
import React, { forwardRef } from 'react'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import generatePDF, { summaryOptions, summaryTypes } from '../../components/pdfExport'
import Radio from '@material-ui/core/Radio'
import { Select, withStyles } from '@material-ui/core'
import { connect } from 'react-redux'
import MenuItem from '@material-ui/core/MenuItem'
import { oauth2 } from '../../App'
import { filterOpportunities, getOpportunities } from '../../jsforceApi/opportunities'
import MaterialTable from 'material-table'

import AddBox from '@material-ui/icons/AddBox'
import ArrowDownward from '@material-ui/icons/ArrowDownward'
import Check from '@material-ui/icons/Check'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import ChevronRight from '@material-ui/icons/ChevronRight'
import Clear from '@material-ui/icons/Clear'
import DeleteOutline from '@material-ui/icons/DeleteOutline'
import Edit from '@material-ui/icons/Edit'
import FilterList from '@material-ui/icons/FilterList'
import FirstPage from '@material-ui/icons/FirstPage'
import LastPage from '@material-ui/icons/LastPage'
import Remove from '@material-ui/icons/Remove'
import SaveAlt from '@material-ui/icons/SaveAlt'
import Search from '@material-ui/icons/Search'
import ViewColumn from '@material-ui/icons/ViewColumn'
import Refresh from '@material-ui/icons/Refresh'
import InputLabel from '@material-ui/core/InputLabel'

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref}/>),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref}/>),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref}/>),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref}/>),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref}/>),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref}/>),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref}/>),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref}/>),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref}/>),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref}/>),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref}/>),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref}/>),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref}/>),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref}/>),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref}/>),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref}/>),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref}/>),
  Refresh: forwardRef((props, ref) => <Refresh {...props} ref={ref}/>)
}

const styles = theme => ({
  mainGrid: {
    height: '100%'
  },
  root: {
    flexGrow: 1,
    padding: 10,
    maxHeight: 'calc(100vh - 64px)',
    // overflow:'hidden'
  },
  paper: {
    padding: theme.spacing(1),
    height: 'calc(100vh - 80px)',
    // overflowY: 'scroll',
  },
  tableContainer: {
    // TODO change to calc
    height: 'calc(100% - 92px)'

  },
  gridItem: {
    maxHeight: '100%'
  },
  gridHeader: {
    textAlign: 'center'
  },
  formControl: {
    margin: theme.spacing(3),
  },
  reportType: {
    width: '100%'
  }

})

class ExecutiveSummaryInner extends React.Component {
  state = {
    opportunities: [],
    opportunitiesChosen: {},
    reportType: '',
    fields: {
      general: summaryOptions.general.ANONYMOUS,
      description: summaryOptions.description.SHORT,
      objectives: summaryOptions.objectives.ONLY_OUTCOMES,
      priorities: summaryOptions.priorities.CENTRE.key
    }
  }

  componentDidMount () {
    this.refresh()
  }

  refresh () {
    if (!this.conn) {
      this.conn = oauth2.getConnection(this.props.oauth2)
    }

    getOpportunities(this.conn).then((result) => {
      // console.log(result)
      console.log('result', result.filter(i => i.Campaign))
      result = filterOpportunities(result)
      this.setState({ opportunities: result })
    }, (err) => {
      if (err) {
        this.props.handleSFError(err)
        console.error(err)
      }
    })
  }

  handleChangeVariants = (variant) => (e) => {
    let fields = this.state.fields
    fields[variant] = e.target.value
    console.log(fields)
    this.setState({ fields })
  }

  render () {
    let { classes } = this.props

    console.log(Boolean(this.state.opportunities.length),
      this.opportunitiesChosen,
      this.opportunitiesChosen &&  this.opportunitiesChosen.length )

    return <Grid container spacing={1} className={classes.mainGrid}>
      <Grid item xs={12} md={8} className={classes.gridItem}>
        <MaterialTable columns={[{ title: 'Name', field: 'Name' }]}
                       isLoading={!Boolean(this.state.opportunities.length)}
                       title={'Opportunities'}
                       icons={tableIcons}
                       data={this.state.opportunities}
                       options={{
                         search: true,
                         selection: true,
                         paging: false,
                         sorting: true,
                         maxBodyHeight: 'calc(100vh - 145px)',
                       }}
                       onSelectionChange={(rows) => {
                         console.log( this.opportunitiesChosen, rows)
                         this.opportunitiesChosen = rows
                         // this.setState({ opportunitiesChosen: rows })
                       }}
                       actions={[
                         {
                           icon: Refresh,
                           tooltip: 'Refresh Data',
                           isFreeAction: true,
                           onClick: () => this.refresh(),
                         }
                       ]}

        />
      </Grid>
      <Grid item xs={12} md={4} className={classes.gridItem}>
        <Paper className={classes.paper}>

          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-readonly-label">
              Report Variant
            </InputLabel>
            <Select
              fullWidth
              label={'Summary Type'}
              id={'summaryType'}
              value={this.state.reportType}
              onChange={(e) => {
                let fields = summaryTypes[e.target.value].fields
                console.log(fields)
                this.setState({ reportType: e.target.value, fields })
              }}
            >
              <MenuItem value={''}></MenuItem>
              <MenuItem value={summaryTypes.LT_50K.value}>{'Executive Summary <50k'}</MenuItem>
              <MenuItem value={summaryTypes.GT_50K.value}>{'Executive Summary >50k'}</MenuItem>
              <MenuItem value={summaryTypes.CMHC.value}>{'Executive Summary CMHC'}</MenuItem>
            </Select>
          </FormControl>
          <br/>
          <br/>
          <Typography variant={'subtitle1'}>
            Report Options
          </Typography>
          <FormControl component="fieldset" className={classes.formControl}>
            <FormLabel component="legend">General</FormLabel>
            <RadioGroup aria-label="general" name="general" value={this.state.fields.general}
                        onChange={this.handleChangeVariants('general')}>
              <FormControlLabel value={summaryOptions.general.NORMAL} control={<Radio/>} label="Normal"/>
              <FormControlLabel value={summaryOptions.general.ANONYMOUS} control={<Radio/>} label="Anonymous"/>
            </RadioGroup>
          </FormControl>

          <FormControl component="fieldset" className={classes.formControl}>
            <FormLabel component="legend">Description</FormLabel>
            <RadioGroup aria-label="general" name="general" value={this.state.fields.description}
                        onChange={this.handleChangeVariants('description')}>
              <FormControlLabel value={summaryOptions.description.SHORT} control={<Radio/>} label="Short"/>
              <FormControlLabel value={summaryOptions.description.FULL} control={<Radio/>} label="Full"/>
              <FormControlLabel value={summaryOptions.description.SUMMARY} control={<Radio/>} label="Summary"/>
            </RadioGroup>
          </FormControl>

          <FormControl component="fieldset" className={classes.formControl}>
            <FormLabel component="legend">Objectives</FormLabel>
            <RadioGroup aria-label="general" name="general" value={this.state.fields.objectives}
                        onChange={this.handleChangeVariants('objectives')}>
              <FormControlLabel value={summaryOptions.objectives.ALL} control={<Radio/>} label="All"/>
              <FormControlLabel value={summaryOptions.objectives.ONLY_OUTCOMES} control={<Radio/>}
                                label="Only Outcomes"/>
              <FormControlLabel value={summaryOptions.objectives.NONE} control={<Radio/>} label="None"/>
            </RadioGroup>
          </FormControl>

          <FormControl component="fieldset" className={classes.formControl}>
            <FormLabel component="legend">Report Type</FormLabel>
            <RadioGroup aria-label="general" name="general" value={this.state.fields.priorities}
                        onChange={this.handleChangeVariants('priorities')}>
              {Object.entries(summaryOptions.priorities).map(([key, value]) =>
                <FormControlLabel value={key} control={<Radio/>} label={value.label} key={key}/>
              )}
            </RadioGroup>
          </FormControl>

          <Button fullWidth variant={'contained'} color={'primary'}
                  disabled={ !(Boolean(this.state.opportunities.length))}
                  onClick={() => {
                    console.log(this.opportunitiesChosen)
                    generatePDF(
                      // this.state.opportunities.filter((item => Boolean(this.state.opportunitiesChosen[item.Id]))),
                      this.opportunitiesChosen,
                      { fields: this.state.fields, reportType: this.state.reportType }
                    )
                  }}
          >
            Get PDF
          </Button>
        </Paper>
      </Grid>
    </Grid>
  }
}

const mapState = (state, ownProps) => {
  return {
    oauth2: state.oauth2,
  }
}

const mapDispatch = dispatch => ({
  // reLogin: reLogin(dispatch),
  // getSchema: () => dispatch(schemaClientGet()),

})
const ExecutiveSummary = withStyles(styles)(connect(mapState, mapDispatch)(ExecutiveSummaryInner))

export default ExecutiveSummary
