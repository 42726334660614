/**
 * Created by Michał Stawski on 07.04.2020.
 */

import * as Excel from 'exceljs'
import { saveAs } from 'file-saver'
import { CBITF, STF_LP, STF_SI } from './pdfExport'
import { filterProvinces, valueToK } from './pdfGeoFundingExport'

const projectsByGeoFundStage = (sheet, table, { fund, provinceStart, provinceEnd }) => {
  let localProvinces = filterProvinces(provinceStart, provinceEnd)
  sheet.addRow([
    `Allocation by Priorities ${fund}`,
    `Total`,
    `Total`,
    ...localProvinces.map(item => ([
      item,
      item,
    ])).flat()
  ])
  sheet.addRow([
    `December 13, 2019 - March 31, 2020`,
    `$K`,
    `#`,
    ...localProvinces.map(item => ([
      '$K',
      '#',
    ])).flat()
  ])

  for (let priority in table) {
    sheet.addRow([
        priority,
        valueToK(table[priority].total.value),
        table[priority].total.count,
        ...localProvinces.map((item, index) => ([
          valueToK(table[priority][item].value),
          table[priority][item].count,
        ])).flat()
      ]
    )
  }
  sheet.addRow([])
}

const generateGeoFundExcel = (documentParts, options) => {
  let workbook = new Excel.Workbook()
  let sheet = workbook.addWorksheet('My Sheet', { views: [{ state: 'frozen', xSplit: 1, ySplit: 1 }] })
  let localProvinces = filterProvinces(0, 14)

  sheet.columns = [
    { header: 'Priority', width: 50 },
    { header: 'Total', width: 6 },
    { header: 'Total', width: 6 },
    ...localProvinces.map(item => [
      { header: item, width: 6 },
      { header: item, width: 6 },
    ]).flat()
  ]

  sheet.addRow(['Consolidated'])
  projectsByGeoFundStage(sheet, documentParts.fundingByProvinces.table.total, { fund: 'Consolidated', provinceEnd: 14 })

  sheet.addRow(['Community-Based Tenant Initiative Fund (CBTIF)'])
  projectsByGeoFundStage(sheet, documentParts.fundingByProvinces.table[CBITF], { fund: 'CBTI', provinceEnd: 14 })

  sheet.addRow(['Sector Transformation Fund – Local Project'])
  projectsByGeoFundStage(sheet, documentParts.fundingByProvinces.table[STF_LP], { fund: 'STF-L', provinceEnd: 14 })

  sheet.addRow(['Sector Transformation Fund – Sectoral Impact'])
  projectsByGeoFundStage(sheet, documentParts.fundingByProvinces.table[STF_SI], { fund: 'STF-SI', provinceEnd: 14 })

  let buff = workbook.xlsx.writeBuffer().then(function (data) {
    let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
    saveAs(blob, 'publications.xlsx')
  })
}

export { generateGeoFundExcel }
