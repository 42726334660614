import {
  isAwarded,
  isSubmitted,
  oppToFundType,
  oppToState,
  oppToValue
} from './commonCalc'
import { provinceToData, provinceToIso } from '../../../jsforceApi/opportunities'

/**
 * Created by Michał Stawski on 13.03.2020.
 */

// const exampleData = [
//   { province: 'NU', all: 9, submitted: 4, accepted: 3 },
//   { province: 'AB', all: 29, submitted: 14, accepted: 9 },
//   { province: 'SK', all: 3, submitted: 2, accepted: 0 },
//   { province: 'YT', all: 9, submitted: 4, accepted: 3 },
//
//   { province: 'MB', all: 19, submitted: 4, accepted: 3 },
//   { province: 'BC', all: 9, submitted: 6, accepted: 3 },
//   { province: 'ON', all: 22, submitted: 12, accepted: 9 },
//   { province: 'QC', all: 30, submitted: 9, accepted: 4 },
//   { province: 'PE', all: 12, submitted: 5, accepted: 3 },
//   { province: 'NL', all: 19, submitted: 4, accepted: 3 },
//   { province: 'NT', all: 3, submitted: 2, accepted: 2 },
//   { province: 'NS', all: 7, submitted: 4, accepted: 1 },
//   { province: 'NB', all: 12, submitted: 9, accepted: 3 },
// ]

const provinceMapping = {
  CA: { name: 'Canada', fill: '#bf1a00' }, // projects across Canada
  QC: { name: 'Quebec', fill: '#001f97' }, // keep #001f97 for Quebec
  Québec: { name: 'Quebec', fill: '#001f97' },
  NU: { name: 'Nunavut', fill: '#906E41' },
  AB: { name: 'Alberta', fill: '#098905' },
  SK: { name: 'Saskatchewan', fill: '#fc62f7' },
  YT: { name: 'Yukon', fill: '#00eeff' },
  MB: { name: 'Manitoba', fill: '#003c40' },
  BC: { name: 'British Columbia', fill: '#0061f2' },
  ON: { name: 'Ontario', fill: '#ff4400' },
  PE: { name: 'Prince Edward Island', fill: '#000840' },
  NL: { name: 'Newfoundland and Labrador', fill: '#8e19fc' },
  NT: { name: 'Northwest Territories', fill: '#330014' },
  NS: { name: 'Nova Scotia', fill: '#ff9d60' },
  NB: { name: 'New Brunswick', fill: '#ffcc00' }
}

const calcGeoDistribution = (result, { fund = null }) => {
  const calculated = {}
  const total = 0
  let noData = 0
  let fundType
  console.log(result)
  for (const res of result) {
    fundType = oppToFundType(res)

    if (fund && fund !== fundType) {
      continue
    }
    //

    if (res.Account && res.Account.BillingAddress) {
      res.state = oppToState(res)
      res.value = oppToValue(res)
      if (!calculated[res.state]) {
        calculated[res.state] = {
          name: provinceToIso(res.state),
          number: 0,
          all: 0,
          allValue: 0,
          submitted: 0,
          submittedValue: 0,
          accepted: 0,
          acceptedValue: 0
        }
      }

      if (isAwarded(res)) {
        calculated[res.state].accepted += 1
        calculated[res.state].acceptedValue += res.value
      } else if (isSubmitted(res)) {
        calculated[res.state].submitted += 1
        calculated[res.state].submittedValue += res.value
      }
      calculated[res.state].all += 1
      calculated[res.state].allValue += res.value
    } else {
      if (!res.Account) {
        console.warn('no Account', res)
      }
      noData++
    }
  }

  const data = []
  let provinceData
  for (const state in calculated) {
    provinceData = provinceToData(state)
    if (!provinceData){
      console.error('province not found', `"${state}"`)
    }

    data.push({
      province: state,
      fill: provinceData.fill,
      ...calculated[state]
    })
  }
  return { data, total, noData }
}

export default calcGeoDistribution
export { provinceMapping }
