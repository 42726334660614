/**
 * Created by Michał Stawski on 30.01.2020.
 */
import {
  baseGeneratePDF,
  CBITF,
  htmlToText,
  listToLi,
  pageBreakeBeforeWithManual,
  pageFooter,
  pageHeader,
  STF_LP,
  STF_SI
} from './pdfExport'
import {
  formatHighlights,
  fourPartPage,
  legendSVGImage,
  renderVerticalLegend,
  svgContainer,
  svgContainerWithoutTitleBlock,
  twoPartPage
} from './pdfAllocationCommittee'
import { fundTypeToFundColor, fundTypeToLabel, oppToFundType, oppToValue } from '../tabs/pdfcomponents/calc/commonCalc'
import { projectsByGeoFundStage } from './pdfGeoFundingExport'
import { oppToPriorityCMHC } from '../tabs/pdfcomponents/calc/calcPriorityAreas'

// LETTER: [612.00, 792.00],
const nf = new Intl.NumberFormat('en-CA', {
  style: 'currency',
  currency: 'CAD',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0
})

export const geoLegend = () => {
  // TODO build based on province mapping
  return [
    {
      colorSVG: legendSVGImage('#001f97'),
      name: 'Quebec'
    },
    {
      colorSVG: legendSVGImage('#ff4400'),
      name: 'Ontario'
    },
    {
      colorSVG: legendSVGImage('#0061f2'),
      name: 'British Columbia'
    },
    {
      colorSVG: legendSVGImage('#ff9d60'),
      name: 'Nova Scotia'
    },
    {
      colorSVG: legendSVGImage('#003c40'),
      name: 'Manitoba'
    },
    {
      colorSVG: legendSVGImage('#906E41'),
      name: 'Nunavut'
    },
    {
      colorSVG: legendSVGImage('#098905'),
      name: 'Alberta'
    },
    {
      colorSVG: legendSVGImage('#fc62f7'),
      name: 'Saskatchewan'
    },
    {
      colorSVG: legendSVGImage('#00eeff'),
      name: 'Yukon'
    },
    {
      colorSVG: legendSVGImage('#000840'),
      name: 'Prince Edward Island'
    },
    {
      colorSVG: legendSVGImage('#8e19fc'),
      name: 'Newfoundland and Labrador'
    },
    {
      colorSVG: legendSVGImage('#330014'),
      name: 'Northwest Territories'
    },
    {
      colorSVG: legendSVGImage('#ffcc00'),
      name: 'New Brunswick'
    },
    {
      colorSVG: legendSVGImage('#bf1a00'),
      name: 'Canada'
    }
  ]
}

const fundAllocation = (tableData, options = {}) => {
  const rows = []
  const { alignment = 'center' } = options
  const nfp = new Intl.NumberFormat('en-CA', { style: 'unit', unit: 'percent' })
  for (const rowTable of tableData) {
    rows.push([
      { text: rowTable[0], style: 'dashboardHeaderSide' },
      { text: nf.format(rowTable[1]), style: 'dashboardEven' },
      { text: nf.format(rowTable[2]), style: 'dashboardEven' },
      { text: nfp.format(rowTable[3]), style: 'dashboardEven' }
    ])
  }
  return {
    layout: 'dashboardLayout',
    margin: [0, 60, 0, 60],
    alignment,
    table: {
      headerRows: 0,
      widths: [70, 80, 80, 80],
      body: [
        [
          { text: 'FUND', style: 'dashboardHeader' },
          { text: 'ALLOCATED FUNDING', style: 'dashboardHeader' },
          { text: 'AVAILABLE FUNDING', style: 'dashboardHeader' },
          { text: 'PERCENTAGE GIVEN', style: 'dashboardHeader' }
        ],
        ...rows
      ]
    }
  }
}

const GRAPH_WIDTH = 350
const GRAPH_HEIGHT = 200
const DEFAULT_RATIO = 0.2
const dataContainer = (dataObject, options = {}) => {
  if (!options.titleRatio) {
    options.titleRatio = DEFAULT_RATIO
  }
  const {
    graphWidth = GRAPH_WIDTH,
    graphHeight = options.height
      ? options.height
      : dataObject.height
        ? dataObject.height
        : GRAPH_HEIGHT,
    tableId = 'svgContainer'
  } = { ...options }
  const titleWidth = graphWidth * options.titleRatio
  const chartWidth = graphWidth * (1 - options.titleRatio)
  return {
    layout: {
      hLineWidth: function (i, node) {
        return 0
      },
      vLineWidth: function (i, node) {
        return 0
      },
      hLineColor: function (i, node) {
        // return 'red'
        return 'white'
      },
      vLineColor: function (i, node) {
        // return 'red'
        return 'white'
      }
      // paddingTop: (index, node) => {
      //    applyVerticalAlignment(node, index, 'bottom');
      //    return 0
      // }
      // paddingTop: setTopMarginOfCellForVerticalCentering2
    },

    alignment: 'center',
    table: {
      tableId,
      headerRows: 0,
      widths: [titleWidth, chartWidth],
      margin: [0, 0, 0, 0],
      heights: graphHeight,
      body: [
        [
          {
            text: dataObject.title,
            style: 'allocationFilled',
            margin: [5, 5]
          },
          dataObject.inner
        ]
      ]
    }
  }
}

const timeFrames = {}

const countTotalRow = awardedProjects => {
  const total = {
    count: 0,
    value: 0
  }

  for (const item of awardedProjects) {
    total.count += 1
    total.value += oppToValue(item)
  }
  return total
}

const awardedTablesWtihTotals = (awardedProjects, options, isCMHC = false) => {
  const projects = {
    stf_s: awardedProjects.filter(item => oppToFundType(item) === 'stf_s'),
    stf_l: awardedProjects.filter(item => oppToFundType(item) === 'stf_l'),
    cbtif: awardedProjects.filter(item => oppToFundType(item) === 'cbtif')
  }
  const ret = []

  for (const projectList in projects) {
    console.log(projects)
    console.log(projects[projectList])
    ret.push(
      awardedTable(
        projects[projectList],
        {
          ...options,
          name: `Subtotal ${fundTypeToLabel(projectList)}`,
          type: projectList,
          total: countTotalRow(projects[projectList])
        },
        options,
        isCMHC
      )
    )
  }
  // const totalRow = countTotalRow(awardedProjects)
  // ret.push(
  //   awardedTable([], {
  //     name: 'Total ',
  //     type: 'total',
  //     total: totalRow
  //   })
  // )
  return ret
}

const awardedTable = (
  awardedProjects,
  subtotalRow,
  options,
  isCMHC = false
) => {
  // const { priorities } = { priorities: 'chmc', ...options, isCMHC }
  console.log(awardedProjects)
  console.log(options)
  let period
  if (!options.period) {
    period = ''
  } else {
    period = ' - ' + options.period
  }

  return awardedProjects && awardedProjects.length > 0 ? {
    margin: [10, 10, 10, 10],
    layout: 'awardedTableLayout',
    table: {
      tableId: 'mainTable',
      headerRows: 2,
      dontBreakRows: true,
      widths: [60, 60, 40, '*', 60],
      body: [
        [
          {
            text:
              `List of awarded grants ( ${awardedProjects[0].RecordType.Name}) ${period}`,
            style: 'tableHeader',
            fillColor: fundTypeToFundColor(subtotalRow.type),
            colSpan: 5
          },
          '',
          '',
          '',
          ''
        ],
        [
          // { text: 'Funding Stream', style: 'awardedProjectsHeader' },
          { text: 'Organization', style: 'awardedProjectsHeader' },
          { text: 'Project Name', style: 'awardedProjectsHeader' },
          { text: 'Grant Awarded', style: 'awardedProjectsHeader' },
          { text: 'Summary', style: 'awardedProjectsHeader' },
          {
            text: isCMHC ? 'CMHC Priority Areas' : 'Centre\'s Priority Areas',
            style: 'awardedProjectsHeader'
          }
        ],
        ...awardedProjects.map(item => [
          // {
          //   text: item.RecordType.Name,
          //   style: 'awardedProjectsFirst',
          //   fillColor: oppToFundColor(item)
          // },
          {
            text: item.Account ? item.Account.Name : 'noAccoutName',
            style: 'awardedProjectsBody'
          },
          { text: item.Name, style: 'awardedProjectsBody' },
          {
            text: nf.format(oppToValue(item)),
            style: 'awardedProjectsBody'
          },

          { text: htmlToText(item.Summary__c), style: 'awardedProjectsBody' },
          listToLi(
            isCMHC
              ? oppToPriorityCMHC(item)
              : item.Priority_areas__c.split(';'),
            {
              style: 'awardedProjectsBody'
            }
          )
        ]),
        subtotalRow
          ? [
            {
              text: subtotalRow.name,
              style: 'subTotalRow',
              fillColor: fundTypeToFundColor(subtotalRow.type),
              colSpan: 2,
              rowSpan: 2
            },
            {
              text: '',
              style: 'subTotalRow',
              fillColor: fundTypeToFundColor(subtotalRow.type)
            },
            {
              text: 'Sum',
              style: 'subTotalRow',
              fillColor: fundTypeToFundColor(subtotalRow.type)
            },
            {
              text: nf.format(`${subtotalRow.total.value}`),
              style: 'subTotalRow',
              color: 'black',
              colSpan: 2,
              fillColor: fundTypeToFundColor(subtotalRow.type)
            },
            {
              text: '',
              style: 'subTotalRow',
              fillColor: fundTypeToFundColor(subtotalRow.type)
            }
          ]
          : ['', '', '', '', '', ''],
        subtotalRow
          ? [
            {
              text: '',
              style: 'subTotalRow',
              fillColor: fundTypeToFundColor(subtotalRow.type)
            },
            {
              text: '',
              style: 'subTotalRow',
              fillColor: fundTypeToFundColor(subtotalRow.type)
            },
            {
              text: 'Count',
              style: 'subTotalRow',
              fillColor: fundTypeToFundColor(subtotalRow.type)
            },
            {
              text: `${subtotalRow.total.count}`,
              style: 'subTotalRow',
              color: 'black',
              colSpan: 2,
              fillColor: fundTypeToFundColor(subtotalRow.type)
            },
            {
              text: '',
              style: 'subTotalRow',
              fillColor: fundTypeToFundColor(subtotalRow.type)
            }
          ]
          : ['', '', '', '', '', '']
      ],
      fontSize: 3
    }
  } : ''
}

const fundingByPrioritiesTable = (fundingByProvinces, start, end, isCMHC) => {
  console.log(fundingByProvinces)
  return [
    // { text: 'Consolidated', style: 'tableHeader', alignment: 'center' },

    projectsByGeoFundStage(fundingByProvinces.table.total, {
      fund: 'Consolidated',
      start,
      end,
      isCMHC
    }),

    { style: 'manualPageBreak', text: ' ' },

    // {
    //   text: 'Community-Based Tenant Initiative Fund (CBTIF)',
    //   style: 'tableHeader',
    //   alignment: 'center'
    // },
    projectsByGeoFundStage(fundingByProvinces.table[CBITF], {
      fund: 'CBTI',
      start,
      end,
      isCMHC
    }),

    { style: 'manualPageBreak', text: ' ' },
    // {
    //   text: 'Sector Transformation Fund – Local Project',
    //   style: 'tableHeader',
    //   alignment: 'center'
    // },
    projectsByGeoFundStage(fundingByProvinces.table[STF_LP], {
      fund: 'STF-L',
      start,
      end,
      isCMHC
    }),

    { style: 'manualPageBreak', text: ' ' },
    // {
    //   text: 'Sector Transformation Fund – Sectoral Impact',
    //   style: 'tableHeader',
    //   alignment: 'center'
    // },
    projectsByGeoFundStage(fundingByProvinces.table[STF_SI], {
      fund: 'STF-SI',
      start,
      end,
      isCMHC
    })
  ]
}
const pageTitles = {
  1: {
    section: 'Funding Activities',
    header: 'Highlights, Facts and Figures'
  },
  // "Put the period always at the same place and maybe choose a different color for the 3 periods.
  2: {
    section: 'Funding Activities',
    header: 'Stream Distribution - Actual vs Targets'
  },
  3: {
    section: 'Funding Activities',
    header: 'Geographic Distribution – Consolidated',
    subheader: 'Value and Number of Projects'
  },
  4: {
    section: 'Funding Activities',
    header: 'Geographic Distribution – Community Based Tenant Initiative Fund ',
    subheader: 'Value and Number of Projects'
  },
  5: {
    section: 'Funding Activities',
    header: 'Geographic Distribution – Sector Transformation Fund, Local Project',
    subheader: 'Value and Number of Projects'
  },
  6: {
    section: 'Funding Activities',
    header: 'Geographic Distribution – Sector Transformation Fund, Sectoral Impact Project',
    subheader: 'Value and Number of Projects'
  },
  7: {
    section: 'Funding Activities',
    header: 'Centre’s Priorities Distribution – Consolidated',
    subheader: 'Value of Projects'
  },
  8: {
    section: 'Funding Activities',
    header: 'Centre’s Priorities Distribution – Consolidated ',
    subheader: 'Number of Projects'
  },
  9: {
    section: 'Funding Activities',
    header: 'CMHC’s Priorities Distribution – Consolidated',
    subheader: 'Value and Number of Projects'
  },
  10: {
    section: 'Funding Activities',
    header: 'Centre’s Priorities Distribution – Geographic Distribution',
    subheader: 'Consolidated Value and Number of Projects',
    date: 'actual',
  },
  11: {
    section: 'Funding Activities',
    header: 'Centre’s Priorities Distribution – Geographic Distribution',
    subheader: 'CBTI’s Value and Number of Projects',
    date: 'actual',
  },
  12: {
    section: 'Funding Activities',
    header: 'Centre’s Priorities Distribution – Geographic Distribution',
    subheader: 'STF-LP Value and Number of Projects',
    date: 'actual',
  },
  13: {
    section: 'Funding Activities',
    header: 'Centre’s Priorities Distribution – Geographic Distribution',
    subheader: 'STF-SIP Value and Number of Projects',
    date: 'actual',
  },
  14: {
    section: 'Funding Activities',
    header: 'CMHC’s Priorities Distribution – Geographic Distribution',
    subheader: 'Consolidated Value and Number of Projects',
    date: 'actual',
  },
  15: {
    section: 'Funding Activities',
    header: 'CMHC’s Priorities Distribution – Geographic Distribution',
    subheader: 'CBTI’s Value and Number of Projects',
    date: 'actual',
  },
  16: {
    section: 'Funding Activities',
    header: 'CMHC’s Priorities Distribution – Geographic Distribution',
    subheader: 'STF-LP Value and Number of Projects',
    date: 'actual',
  },
  17: {
    section: 'Funding Activities',
    header: 'CMHC’s Priorities Distribution – Geographic Distribution',
    subheader: 'STF-SIP Value and Number of Projects',
    date: 'actual',
  },
  18: {
    section: 'Funding Activities',
    header: 'Projects in the Pipeline',
    subheader: 'Number of Days from Submission to Decision '
  },
  19: {
    section: 'Funding Activities',
    header: 'Projects in the Pipeline',
    subheader: 'Number of Days Per Processing Stage '
  },
  20: {
    section: 'Funding Activities',
    header: 'Projects in the Pipeline',
    subheader: 'Evolution of Average Processing Time Since December 13, 2020 '

  }
}

const formatPipelineData = highlightData => {
  let list = []
  for (const element of highlightData.data) {
    if (
      element.name !== 'Average number of days to resolve application' &&
      element.name !== 'Total'
    ) {
      list.push(
        'Number of applications resolved in ' +
        element.name +
        ': ' +
        element.value
      )
    } else if (element.name === 'Total') {
      list.push(
        'Total number of applications in the pipepline: ' + element.value
      )
    } else {
      list.push(element.name + ': ' + element.value.toFixed())
    }
  }
  return [
    {
      text: `Data ${highlightData.rangeString}: `,
      // `Between ${highlightData.range.start.format('MMMM Do YYYY')}, ` +
      // `and ${highlightData.range.end.format('MMMM Do YYYY')}:`,
      style: 'highlightsHeader'
    },
    {
      ul: list,
      style: 'highlightsList'
    }
  ]
}

const generateCMHCQuaterlyPDF = (documentParts, options) => {
  console.log(documentParts)
  const pageTitledHeader = (currentPage, pageCount) => {
    const obj = pageTitles[currentPage]
    let header = ''
    let section = 'Project Summaries'
    let subheader = ''
    let dateString = null
    if (obj) {
      header = obj.header
      section = obj.section
      subheader = obj.subheader
      if (obj.date){
        dateString = `${documentParts.startDate.format(
            'MMMM Do YYYY'
          )} to ` + `${documentParts.endDate.format('MMMM Do YYYY')}`
      }
    }
    return pageHeader({
      title:
        `CMHC Quarterly Report  - ${documentParts.startDate.format(
          'MMMM Do YYYY'
        )} - ` + `${documentParts.endDate.format('MMMM Do YYYY')}`,
      header,
      section,
      subheader,
      dateString
    })
  }
  baseGeneratePDF(
    {
      info: documentParts.info,
      pageMargins: [0, 95, 0, 30],
      header: pageTitledHeader,
      footer: pageFooter,
      pageOrientation: 'landscape',
      content: [
        // 1
        fourPartPage([
          [
            dataContainer(
              {
                title: 'Highlights',
                inner: formatHighlights(documentParts.highlightDataActual, {
                  actual: true
                })
              },
              { height: GRAPH_HEIGHT + 20 }
            ),
            dataContainer(
              {
                title: 'Highlights',
                inner: formatHighlights(documentParts.highlightData)
              },
              { height: GRAPH_HEIGHT + 20 }
            )
          ],
          [
            dataContainer(
              {
                title: 'Highlights',
                inner: formatHighlights(documentParts.highlightDataTotal)
              },
              { height: GRAPH_HEIGHT + 20 }
            ),
            ''
          ]
        ]),
        // 2
        fourPartPage([
          [
            svgContainerWithoutTitleBlock(documentParts.fundsByFundTypeActual, {
              legend: false,
              fourPart: true
            }),
            svgContainerWithoutTitleBlock(documentParts.fundsByFundTypeAnnual, {
              legend: false,
              fourPart: true
            })
          ],
          [
            svgContainerWithoutTitleBlock(
              documentParts.fundsByFundTypeCumulative,
              {
                legend: false,
                fourPart: true
              }
            ),
            renderVerticalLegend(
              [
                {
                  colorSVG: legendSVGImage('#40BAD2'),
                  name: 'Budget'
                },
                {
                  colorSVG: legendSVGImage('#FAB900'),
                  name: 'Funds allocated'
                }
              ],
              80,
              true
            )
          ]
        ]),
        // 3
        fourPartPage([
          [
            svgContainerWithoutTitleBlock(
              documentParts.geoDistributionTotalActual,
              {
                legend: false,
                fourPart: true
              }
            ),
            svgContainerWithoutTitleBlock(
              documentParts.geoDistributionTotalAnnual,
              {
                legend: false,
                fourPart: true
              }
            )
          ],
          [
            svgContainerWithoutTitleBlock(
              documentParts.geoDistributionTotalCumulative,
              {
                legend: false,
                fourPart: true
              }
            ),
            renderVerticalLegend(geoLegend())
          ]
        ]),
        // 4
        fourPartPage([
          [
            svgContainerWithoutTitleBlock(
              documentParts.geoDistributionCBTIActual,
              {
                legend: false,
                fourPart: true
              }
            ),
            svgContainerWithoutTitleBlock(
              documentParts.geoDistributionCBTIAnnual,
              {
                legend: false,
                fourPart: true
              }
            )
          ],
          [
            svgContainerWithoutTitleBlock(
              documentParts.geoDistributionCBTICumulative,
              {
                legend: false,
                fourPart: true
              }
            ),
            renderVerticalLegend(geoLegend())
          ]
        ]),
        // 5
        fourPartPage([
          [
            svgContainerWithoutTitleBlock(
              documentParts.geoDistributionStfLPActual,
              {
                legend: false,
                fourPart: true
              }
            ),
            svgContainerWithoutTitleBlock(
              documentParts.geoDistributionStfLPAnnual,
              {
                legend: false,
                fourPart: true
              }
            )
          ],
          [
            svgContainerWithoutTitleBlock(
              documentParts.geoDistributionStfLPCumulative,
              {
                legend: false,
                fourPart: true
              }
            ),
            renderVerticalLegend(geoLegend())
          ]
        ]),
        // 6
        fourPartPage([
          [
            svgContainerWithoutTitleBlock(
              documentParts.geoDistributionStfSipActual,
              {
                legend: false,
                fourPart: true
              }
            ),
            svgContainerWithoutTitleBlock(
              documentParts.geoDistributionStfSipAnnual,
              {
                legend: false,
                fourPart: true
              }
            )
          ],
          [
            svgContainerWithoutTitleBlock(
              documentParts.geoDistributionStfSipCumulative,
              {
                legend: false,
                fourPart: true
              }
            ),
            renderVerticalLegend(geoLegend())
          ]
        ]),
        // 7
        fourPartPage([
          [
            svgContainerWithoutTitleBlock(
              documentParts.priorityAreasCentreValueCurrent,
              {
                legend: false,
                fourPart: true
              }
            ),
            svgContainerWithoutTitleBlock(
              documentParts.priorityAreasCentreValueAnnual,
              {
                legend: false,
                fourPart: true
              }
            )
          ],
          [
            svgContainerWithoutTitleBlock(
              documentParts.priorityAreasCentreValueCumulative,
              {
                legend: false,
                fourPart: true
              }
            ),
            {}
          ]
        ]),
        // 8
        fourPartPage([
          [
            svgContainerWithoutTitleBlock(
              documentParts.priorityAreasCentreNumberCurrent,
              {
                legend: false,
                fourPart: true
              }
            ),
            svgContainerWithoutTitleBlock(
              documentParts.priorityAreasCentreNumberAnnual,
              {
                legend: false,
                fourPart: true
              }
            )
          ],
          [
            svgContainerWithoutTitleBlock(
              documentParts.priorityAreasCentreNumberCumulative,
              {
                legend: false,
                fourPart: true
              }
            ),
            {}
          ]
        ]),
        // 9
        // fourPartPage([
        //   [
        //     svgContainerWithoutTitleBlock(
        //       documentParts.priorityAreasCMHCValueCurrent,
        //       {
        //         legend: false,
        //         fourPart: true
        //       }
        //     ),
        //     svgContainerWithoutTitleBlock(
        //       documentParts.priorityAreasCMHCValueAnnual,
        //       {
        //         legend: false,
        //         fourPart: true
        //       }
        //     )
        //   ],
        //   [
        //     svgContainerWithoutTitleBlock(
        //       documentParts.priorityAreasCMHCValueCumulative,
        //       {
        //         legend: false,
        //         fourPart: true
        //       }
        //     ),
        //     ''
        //   ]
        // ]),

        // 9
        fourPartPage([
          [
            svgContainerWithoutTitleBlock(
              documentParts.averageAmountByPriorityCMHCCurrent,
              {
                legend: false,
                fourPart: true,
                customHeight: GRAPH_HEIGHT / 2 + 70
              }
            ),
            svgContainerWithoutTitleBlock(
              documentParts.averageAmountByPriorityCMHCAnnual,
              {
                legend: false,
                fourPart: true,
                customHeight: GRAPH_HEIGHT / 2 + 70
              }
            )
          ],
          [
            svgContainerWithoutTitleBlock(
              documentParts.averageAmountByPriorityCMHCCumulative,
              {
                legend: false,
                fourPart: true,
                customHeight: GRAPH_HEIGHT / 2 + 70
              }
            ),
            renderVerticalLegend([
              {
                colorSVG: legendSVGImage('#40BAD2'),
                name: 'Total amount awarded'
              },
              {
                colorSVG: legendSVGImage('#FAB900'),
                name: 'Number of projects'
              }
            ], 80, true)
          ]
        ]),

        { style: 'manualPageBreak', text: ' ' },
        // { text: 'Centre Priorities', style: 'tableHeader' },
        ...fundingByPrioritiesTable(
          documentParts.fundingByProvincesCentre,
          documentParts.fundingByRange.start,
          documentParts.fundingByRange.end,
          false
        ),
        { style: 'manualPageBreak', text: ' ' },
        // { text: 'CMHC Priorities', style: 'tableHeader' },
        ...fundingByPrioritiesTable(
          documentParts.fundingByProvincesCMHC,
          documentParts.fundingByRange.start,
          documentParts.fundingByRange.end,
          true
        ),

        // 11
        // fourPartPage([
        //   [
        //     dataContainer(
        //       {
        //         title: 'Evaluation time highlights',
        //         inner: formatPipelineData(documentParts.pipelineDataActual)
        //       },
        //       { height: GRAPH_HEIGHT + 20 }
        //     ),
        //     dataContainer(
        //       {
        //         title: 'Evaluation time highlights',
        //         inner: formatPipelineData(documentParts.pipelineData)
        //       },
        //       { height: GRAPH_HEIGHT + 20 }
        //     )
        //   ],
        //   [
        //     dataContainer(
        //       {
        //         title: 'Evaluation time highlights',
        //         inner: formatPipelineData(documentParts.pipelineDataTotal)
        //       },
        //       { height: GRAPH_HEIGHT + 20 }
        //     ),
        //     ''
        //   ]
        // ]),
        fourPartPage([
          [
            svgContainerWithoutTitleBlock(
              // documentParts.durationInProgressCurrent,
              documentParts.durationSubmittedCurrent,
              {
                legend: false,
                fourPart: true
              }
            ),
            svgContainerWithoutTitleBlock(
              documentParts.durationSubmittedAnnual,
              {
                legend: false,
                fourPart: true
              }
            )
          ],
          [
            svgContainerWithoutTitleBlock(
              documentParts.durationSubmittedCumulative,
              {
                legend: false,
                fourPart: true
              }
            ),
            ''
          ]
        ]),
        // 12
        fourPartPage([
          [
            svgContainerWithoutTitleBlock(
              documentParts.averageDurationCurrent,
              {
                legend: false,
                fourPart: true
              }
            ),
            svgContainerWithoutTitleBlock(documentParts.averageDurationAnnual, {
              legend: false,
              fourPart: true
            })
          ],
          [
            svgContainerWithoutTitleBlock(
              documentParts.averageDurationCumulative,
              {
                legend: false,
                fourPart: true
              }
            ),

            renderVerticalLegend(
              [
                {
                  colorSVG: legendSVGImage('#95BF28'),
                  name: 'Applicant\'s Tasks'
                },
                {
                  colorSVG: legendSVGImage('#FABD00'),
                  name: 'Centre\'s Tasks'
                }
              ],
              80,
              true
            )
          ]
        ]),
        twoPartPage([
          [
            svgContainerWithoutTitleBlock(documentParts.averageTimeInPipeLine, {
              legend: false,
              fourPart: false
            }),

            renderVerticalLegend(
              [
                {
                  colorSVG: legendSVGImage('#95BF28'),
                  name: 'Applicant\'s Tasks'
                },
                {
                  colorSVG: legendSVGImage('#FABD00'),
                  name: 'Centre\'s Tasks'
                }
              ],
              80
            )
          ]
        ]),
        // { style: 'manualPageBreak', text: ' ' },
        ...awardedTablesWtihTotals(documentParts.awardedProjects, { period: documentParts.info.actual }, true)
      ],
      pageBreakBefore: pageBreakeBeforeWithManual
    },
    {
      filename: 'CMHC_Quarterly_'
    }
  )
}

export default generateCMHCQuaterlyPDF
export { awardedTablesWtihTotals, fundingByPrioritiesTable }
