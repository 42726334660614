import { isGranteeStage, isSubmitted } from './commonCalc'
import moment from 'moment'
import { sortByCreatedDate } from './calcApplicationsInProgress'

/**
 * Created by Michał Stawski on 10.10.2020.
 */


const sortBySortKey = (a, b) => {
  let textA = a.sortKey
  let textB = b.sortKey
  return (textA < textB) ? -1 : (textA > textB) ? 1 : 0
}

const stagesToDiffs = (sortedStages, submitDate) => {
  let grantee = 0, chtc = 0
  let i, diff, month, year
  let stageDateStart, stageDateEnd, lastStage
  let stageTimes = []
  let firstStage = false
  for (i = 0; i < sortedStages.length; i++) {

    if (sortedStages[i].StageName === 'In Progress' ||
      sortedStages[i].StageName === 'Withdrawn') {
      if (sortedStages[i].StageName === 'Withdrawn') {
        console.warn('application Withdrawn', sortedStages)
      }
      continue
    }
    if (sortedStages[i].StageName === 'Submitted') {
      if (!firstStage) {
        stageDateStart = moment.utc(sortedStages[i].CreatedDate)
        lastStage = stageDateStart
        firstStage = true
        continue
      }
    }
    if (!lastStage) {
      lastStage = submitDate
    }
    if (!lastStage) {
      console.error('lastStage null no submitted', sortedStages)
    }
    diff = moment.utc(sortedStages[i].CreatedDate).diff(lastStage, 'days')
    if (isGranteeStage(sortedStages[i].StageName)) {
      chtc += diff
      lastStage = moment.utc(sortedStages[i].CreatedDate)
      stageTimes.push({ sn: sortedStages[i].StageName, c: 'g', diff })
    } else {
      grantee += diff
      lastStage = moment.utc(sortedStages[i].CreatedDate)
      stageTimes.push({ sn: sortedStages[i].StageName, c: 'c', diff })
      if (sortedStages[i].StageName === 'Awarded' ||
        sortedStages[i].StageName === 'Declined') {
        stageDateEnd = moment.utc(sortedStages[i].CreatedDate)

        break
      }
    }
  }

  if (i >= sortedStages.length) {
    // still processing
    console.warn('still processing', sortedStages)
    return null
  }

  // console.log('inPipeline stages', stageTimes)
  diff = stageDateEnd.diff(stageDateStart, 'days')

  month = stageDateEnd.month()
  year = stageDateEnd.year()
  return {
    month,
    year,
    diff,
    stageDateStart,
    stageDateEnd,
    chtc, grantee
  }
}

export const calcTimeInPipeline = (result) => {

  let sortedStages
  let calculated = []
  let ret
  for (const res of result) {

    if (!isSubmitted(res) /*|| moment.utc(res.CreatedDate).isAfter(endDate)*/) {
      // console.log('PASSED')
      continue
    }

    sortedStages = res.OpportunityHistories.records.sort(sortByCreatedDate)
    // console.log('inPipeline sortedStages', sortedStages)
    ret = stagesToDiffs(sortedStages, res.Submit_Date__c)
    if (!ret) {
      // not awarded yet
      console.log('not awarded yet', res)
      continue
    }
    // if (ret.chtc < 0 || ret.grantee < 0) {
    //   console.log('inPipeline under zero', ret, res)
    // }
    // if (ret.chtc > 50 || ret.grantee > 50) {
    //   console.log('inPipeline over 50', ret, res)
    // }
    // if (ret.chtc < 5 || ret.grantee < 10) {
    //   console.log('inPipeline under 5', ret, res)
    // }

    calculated.push({
      ...ret,
      sortKey: ret.stageDateEnd.format('YYYYMM')
    })
  }
  calculated.sort(sortBySortKey)
// console.log('inPipeline results', calculated)
  let data = []
  if (calculated.length === 0) {
    return data
  }
  let startDomain = { year: calculated[0].year, month: calculated[0].month }
  let endDomain = {
    year: calculated[calculated.length - 1].year,
    month: calculated[calculated.length - 1].month
  }

// console.log('inPipeline domains', startDomain, endDomain)

  let startMonth, endMonth
  let i = 0
  for (let y = startDomain.year; y <= endDomain.year; y++) {
    if (y === startDomain.year) {
      startMonth = startDomain.month
    } else {
      startMonth = 0
    }
    if (y === endDomain.year) {
      endMonth = endDomain.month
    } else {
      endMonth = 11
    }
    // console.log('inPipeline year', y, startMonth, endMonth)
    for (let m = startMonth; m <= endMonth; m++) {
      let grantee = 0, chtc = 0, count = 0, diff = 0

      for (; i < calculated.length; i++) {
        if (calculated[i].year !== y || calculated[i].month !== m) {
          break
        }
        grantee += calculated[i].grantee
        chtc += calculated[i].chtc
        diff += calculated[i].diff
        count += 1
      }

      data.push({
        months: m,
        year: y,
        name: `${y}.${(m + 1).toString().padStart(2, '0')}`,
        chtc: count ? chtc / count : 0,
        diff: count ? diff / count : 0,
        grantee: count ? grantee / count : 0,
        count
      })
    }
  }
// if (!calculated[year]){
//   calculated[year]={
//     months: {}
//   }
// }
// if (!calculated[year].months[month]){
//   calculated[year].months[month] = {
//     count: 0,
//     value: 0
//   }
// }
// calculated[year].months
// console.log('inPipeline data', data)
  return data
}
