/**
 * Created by Michał Stawski on 30.01.2020.
 */
import {
  baseGeneratePDF,
  pageBreakeBeforeWithManual,
  pageFooter,
  pageHeader
} from './pdfExport'
import {
  awardedTablesWtihTotals,
  fundingByPrioritiesTable, geoLegend
} from './pdfCHTCQuaterly'

// LETTER: [612.00, 792.00],
const fundAllocation = (tableData, options = {}) => {
  const rows = []
  const { alignment = 'center' } = options

  const nf = new Intl.NumberFormat('en-CA', {
    style: 'currency',
    currency: 'CAD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  })
  const nfp = new Intl.NumberFormat('en-CA', { style: 'unit', unit: 'percent' })
  for (const rowTable of tableData) {
    rows.push([
      { text: rowTable[0], style: 'dashboardHeaderSide' },
      { text: nf.format(rowTable[1]), style: 'dashboardEven' },
      { text: nf.format(rowTable[2]), style: 'dashboardEven' },
      { text: nfp.format(rowTable[3]), style: 'dashboardEven' }
    ])
  }
  return {
    layout: 'dashboardLayout',
    margin: [0, 60, 0, 60],
    alignment,
    table: {
      headerRows: 0,
      widths: [70, 80, 80, 80],
      body: [
        [
          { text: 'FUND', style: 'dashboardHeader' },
          { text: 'ALLOCATED FUNDING', style: 'dashboardHeader' },
          { text: 'AVAILABLE FUNDING', style: 'dashboardHeader' },
          { text: 'PERCENTAGE GIVEN', style: 'dashboardHeader' }
        ],
        ...rows
      ]
    }
  }
}

const GRAPH_WIDTH = 350
const GRAPH_HEIGHT = 200
const DEFAULT_RATIO = 0.2
const dataContainer = (dataObject, options = {}) => {
  if (!options.titleRatio) {
    options.titleRatio = DEFAULT_RATIO
  }
  const {
    graphWidth = GRAPH_WIDTH,
    graphHeight = options.height
      ? options.height
      : dataObject.height
      ? dataObject.height
      : GRAPH_HEIGHT,
    tableId = 'svgContainer'
  } = { ...options }
  const titleWidth = graphWidth * options.titleRatio
  const chartWidth = graphWidth * (1 - options.titleRatio)
  return {
    layout: {
      hLineWidth: function (i, node) {
        return 0
      },
      vLineWidth: function (i, node) {
        return 0
      },
      hLineColor: function (i, node) {
        // return 'red'
        return 'white'
      },
      vLineColor: function (i, node) {
        // return 'red'
        return 'white'
      }
      // paddingTop: (index, node) => {
      //    applyVerticalAlignment(node, index, 'bottom');
      //    return 0
      // }
      // paddingTop: setTopMarginOfCellForVerticalCentering2
    },

    alignment: 'center',
    table: {
      tableId,
      headerRows: 0,
      widths: [titleWidth, chartWidth],
      margin: [0, 0, 0, 0],
      heights: graphHeight,
      body: [
        [
          {
            text: dataObject.title,
            style: 'allocationFilled',
            margin: [5, 5]
          },
          dataObject.inner
        ]
      ]
    }
  }
}

const legendSVGImage = color =>
  `<svg className="recharts-surface" width="14" height="14" viewBox="0 0 32 32"
    version="1.1" style="display: inline-block; vertical-align: middle; margin-right: 4px;" >
         <path stroke="none" fill="${color}" d="M0,4h32v24h-32z" className="recharts-legend-icon"> 
     </path>
     </svg>`

const renderLegend = legend => {
  /**
   [{
    colorSVG: legendSVGImage('#95BF28'),
    name: 'text
   }]
   **/
  return {
    layout: 'pageFourLayout',
    table: {
      tableId: 'svgContainer',
      headerRows: 0,
      widths: new Array(legend.length * 2)
        .fill('*')
        .map((item, index) => (index % 2 ? '*' : 5)),
      margin: [1, 1, 1, 1],
      body: [
        legend
          .map(item => {
            return [
              { svg: item.colorSVG, height: 10, margin: [0, 0, 0, 0] },
              { text: item.name, style: 'dashboardLegend' }
            ]
          })
          .flat()
      ]
    }
  }
}

const renderVerticalLegend = (legend, marginTop = 0, center = false) => {
  console.log('MARG', marginTop)
  return {
    layout: 'pageFourLayout',
    margin: [0, marginTop, 0, 20],
    alignment: 'center',
    width: GRAPH_WIDTH,
    // height: GRAPH_HEIGHT,
    table: {
      tableId: 'svgContainer',
      // alignment: 'center',
      headerRows: 0,
      widths: center ? ['auto', '*', '*', 'auto'] : [10, '*'],
      // heights: [GRAPH_HEIGHT, '*'],
      body: legend.map(item => {
        return [
          ...(center?['']:[]),
          { svg: item.colorSVG, height: 10, alignment: 'right' },
          { text: item.name, style: 'dashboardLegend' },
          ...(center?['']:[]),
        ]
      })
    }
  }
}
const svgContainer = (svgObject, options = {}) => {
  if (!options.titleRatio) {
    options.titleRatio = DEFAULT_RATIO
  }
  const {
    graphWidth = GRAPH_WIDTH,
    graphHeight = svgObject.height ? svgObject.height : GRAPH_HEIGHT,
    tableId = 'svgContainer'
  } = { ...options }
  const titleWidth = graphWidth * options.titleRatio
  const chartWidth = graphWidth * (1 - options.titleRatio)
  const inner = [
    svgObject
      ? {
          svg: svgObject.svg,
          fit: [chartWidth, graphHeight],
          // width: chartWidth  ,
          // height: graphHeight,
          margin: [60, 20, 10, 0]
        }
      : '',
    options.legend === false ||
    !svgObject.legend ||
    svgObject.legend.length === 0
      ? { text: ' ' }
      : renderLegend(svgObject.legend)
  ]
  return dataContainer({ title: svgObject.title, inner }, options)
}

const svgContainerWithoutTitleBlock = (svgObject, options = {}) => {
  return {
    layout: 'pageFourLayout',
    colSpan: options.colSpan ? options.colSpan : 1,
    table: {
      tableId: 'svgContainer',
      headerRows: 0,
      widths: [GRAPH_WIDTH / 2, '*'],
      margin: [0, 0, 0, 0],
      body: [
        [
          {
            text: svgObject.title,
            fontSize: 10,
            style: 'dashboardTitle',
            colSpan: 2,
            margin: [
              0,
              options.headerMargin ? options.headerMargin : 10,
              0,
              options.headerMargin ? options.headerMargin : 10
            ]
          },
          {}
        ],
        [
          svgObject
            ? {
                svg: svgObject.svg,
                alignment: 'center',
                width: GRAPH_WIDTH,
                height: options.fourPart
                  ? options.customHeight
                    ? options.customHeight
                    : GRAPH_HEIGHT / 2 + 80
                  : GRAPH_HEIGHT,
                margin: [0, 0, 0, 0],
                colSpan: 2
              }
            : 'No Projects in timeframe',
          {}
        ],
        [
          svgObject && svgObject.legend.length !== 0 && options.legend
            ? {
                layout: 'pageFourLayout',
                colSpan: 2,
                table: {
                  tableId: 'svgContainer',
                  headerRows: 0,
                  widths: [GRAPH_WIDTH / 2, GRAPH_WIDTH / 2],
                  heights: [15, '*'],
                  margin: [1, 1, 1, 1],
                  body: svgObject.legend.map(item => {
                    return [
                      {
                        svg: item.colorSVG,
                        height: 20,
                        alignment: 'right',
                        width: 30,
                        margin: [0, 0, 0, 0]
                      },
                      {
                        text: item.name,
                        fontSize: 15,
                        alignment: 'left',
                        style: 'dashboardLegend',
                        noWrap: true,
                        margin: [0, 0, 0, 0]
                      }
                    ]
                  })
                }
              }
            : '',
          {}
        ]
      ]
    }
  }
}

const fourPartPage = pageBody => ({
  layout: 'allocationCommitteeLayout',
  margin: [20, 0, 0, 10],
  table: {
    tableId: 'mainTable',
    headerRows: 0,
    widths: ['*', '*'],
    heights: ['*', '*'],
    body: pageBody
  }
})

const twoPartPage = pageBody => ({
  layout: 'allocationCommitteeLayout',
  margin: [50, 0, 0, 150],
  table: {
    tableId: 'mainTable',
    headerRows: 0,
    widths: ['50%', '50%'],
    heights: ['*', '*'],
    body: pageBody
  }
})

const pageTitles = {
  1: {
    header: 'Funding Activity, Highlights, and Facts and Figures',
    section: 'Overview of Allocated Funds'
  },
  // "Put the period always at the same place and maybe choose a different color for the 3 periods.
  2: {
    header: 'Funding Activity, Highlights, and Facts and Figures',
    section: 'Overview of Allocated Funds'
  },
  3: {
    header: 'Allocated Funding Overview',
    section: 'Overview of Allocated Funds'
  },
  4: {
    header: 'Geographic Distribution - Consolidated Funding, in $',
    section: 'Overview of Allocated Funds'
  },
  5: {
    header:
      'Geographic Distribution of the Community Based Tenant Initiative Fund in $',
    section: 'Overview of Allocated Funds'
  },
  6: {
    header:
      'Geographic Distribution of the Sector Transformation Fund, Local Projects in $',
    section: 'Overview of Allocated Funds'
  },
  7: {
    header:
      'Geographic Distribution of the Sector Transformation Fund, Sectoral Impact in $',
    section: 'Overview of Allocated Funds'
  },
  8: {
    header: 'Distribution of Consolidated Funding by Centre’s Priorities',
    section: 'Allocation by Priorities'
  },
  9: {
    section: 'Centre Priorities',
    header: 'Consolidated'
  },
  10: {
    section: 'Centre Priorities',
    header: 'Community-Based Tenant Initiative Fund (CBTIF)'
  },
  11: {
    section: 'Centre Priorities',
    header: 'Sector Transformation Fund – Local Project'
  },
  12: {
    section: 'Centre Priorities',
    header: 'Sector Transformation Fund – Sectoral Impact'
  }
}

const formatHighlights = (highlightData, props = {}) => {
  const { actual = false } = props

  console.log(highlightData, props)
  return [
    {
      text: `Data ${highlightData.rangeString}: `,
      // `Between ${highlightData.range.start.format('MMMM Do YYYY')}, ` +
      // `and ${highlightData.range.end.format('MMMM Do YYYY')}:`,
      style: 'highlightsHeader'
    },
    {
      ul: [
        `${highlightData.applications} Applications Submitted`,
        `${highlightData.awarded} Grants Awarded`,
        `Total Awarded $${highlightData.total.toLocaleString('en-CA')}`,
        `${highlightData.provinceCount} Provinces and Territories${
          highlightData.hasCanada ? ' + pan-Canadian projects' : ''
        }`,
        ...(actual
          ? [
              `${highlightData.inPipeline.length} projects in pipeline`,
              `Value of projects in pipeline: $${highlightData.pipelineValue.toLocaleString(
                'en-CA'
              )}`
            ]
          : '')
      ],
      style: 'highlightsList'
    }
  ]
}

const generateAllocationCommitteePDF = (documentParts, options) => {
  const pageTitledHeader = (currentPage, pageCount) => {
    const obj = pageTitles[currentPage]
    let header = ''
    let section = 'Project Summaries'
    if (obj) {
      header = obj.header
      section = obj.section
    }
    return pageHeader({
      title:
        `Allocation Committee \n ${documentParts.startDate.format(
          'MMMM Do YYYY'
        )} - ` + `${documentParts.endDate.format('MMMM Do YYYY')}`,
      header,
      section
    })
  }
  baseGeneratePDF(
    {
      info: documentParts.info,
      pageMargins: [0, 80, 0, 30],
      header: pageTitledHeader,
      footer: pageFooter,
      pageOrientation: 'landscape',
      content: [
        // 1
        fourPartPage([
          [
            dataContainer(
              {
                title: 'Highlights',
                inner: formatHighlights(documentParts.highlightDataActual)
              },
              { height: GRAPH_HEIGHT + 20 }
            ),
            dataContainer(
              {
                title: 'Highlights',
                inner: formatHighlights(documentParts.highlightData)
              },
              { height: GRAPH_HEIGHT + 20 }
            )
          ],
          [
            dataContainer(
              {
                title: 'Highlights',
                inner: formatHighlights(documentParts.highlightDataTotal)
              },
              { height: GRAPH_HEIGHT + 20 }
            ),
            ''
          ]
        ]),
        // 2
        fourPartPage([
          [
            svgContainerWithoutTitleBlock(documentParts.fundsByFundTypeActual, {
              legend: false,
              fourPart: true
            }),
            svgContainerWithoutTitleBlock(documentParts.fundsByFundTypeAnnual, {
              legend: false,
              fourPart: true
            })
          ],
          [
            svgContainerWithoutTitleBlock(
              documentParts.fundsByFundTypeCumulative,
              {
                legend: false,
                fourPart: true
              }
            ),
            renderVerticalLegend(documentParts.fundsByFundTypeAnnual.legend, 80, true)
          ]
        ]),

        // 3
        fourPartPage([
          [
            svgContainerWithoutTitleBlock(
              documentParts.totalAmountAndProjectsNumberCurrent,
              {
                legend: false,
                fourPart: true
              }
            ),
            svgContainerWithoutTitleBlock(
              documentParts.totalAmountAndProjectsNumberAnnual,
              {
                legend: false,
                fourPart: true
              }
            )
          ],
          [
            svgContainerWithoutTitleBlock(
              documentParts.totalAmountAndProjectsNumberCumulative,
              {
                legend: false,
                fourPart: true
              }
            ),
            renderVerticalLegend(
              documentParts.totalAmountAndProjectsNumberCumulative.legend, 80, true
            )
          ]
        ]),
        // 4
        fourPartPage([
          [
            svgContainerWithoutTitleBlock(
              documentParts.geoDistributionTotalActual,
              {
                legend: false,
                fourPart: true
              }
            ),
            svgContainerWithoutTitleBlock(
              documentParts.geoDistributionTotalAnnual,
              {
                legend: false,
                fourPart: true
              }
            )
          ],
          [
            svgContainerWithoutTitleBlock(
              documentParts.geoDistributionTotalCumulative,
              {
                legend: false,
                fourPart: true
              }
            ),
            renderVerticalLegend(geoLegend())
          ]
        ]),
        // 5
        fourPartPage([
          [
            svgContainerWithoutTitleBlock(
              documentParts.geoDistributionCBTIActual,
              {
                legend: false,
                fourPart: true
              }
            ),
            svgContainerWithoutTitleBlock(
              documentParts.geoDistributionCBTIAnnual,
              {
                legend: false,
                fourPart: true
              }
            )
          ],
          [
            svgContainerWithoutTitleBlock(
              documentParts.geoDistributionCBTICumulative,
              {
                legend: false,
                fourPart: true
              }
            ),
            renderVerticalLegend(geoLegend())
          ]
        ]),
        // 6
        fourPartPage([
          [
            svgContainerWithoutTitleBlock(
              documentParts.geoDistributionStfLPActual,
              {
                legend: false,
                fourPart: true
              }
            ),
            svgContainerWithoutTitleBlock(
              documentParts.geoDistributionStfLPAnnual,
              {
                legend: false,
                fourPart: true
              }
            )
          ],
          [
            svgContainerWithoutTitleBlock(
              documentParts.geoDistributionStfLPCumulative,
              {
                legend: false,
                fourPart: true
              }
            ),
            renderVerticalLegend(geoLegend())
          ]
        ]),
        // 7
        fourPartPage([
          [
            svgContainerWithoutTitleBlock(
              documentParts.geoDistributionStfSipActual,
              {
                legend: false,
                fourPart: true
              }
            ),
            svgContainerWithoutTitleBlock(
              documentParts.geoDistributionStfSipAnnual,
              {
                legend: false,
                fourPart: true
              }
            )
          ],
          [
            svgContainerWithoutTitleBlock(
              documentParts.geoDistributionStfSipCumulative,
              {
                legend: false,
                fourPart: true
              }
            ),
            renderVerticalLegend(geoLegend())
          ]
        ]),
        // 8
        fourPartPage([
          [
            svgContainerWithoutTitleBlock(
              documentParts.distributionByPrioritiesCurrent,
              {
                legend: false,
                fourPart: true
              }
            ),
            svgContainerWithoutTitleBlock(
              documentParts.distributionByPrioritiesAnnual,
              {
                legend: false,
                fourPart: true
              }
            )
          ],
          [
            svgContainerWithoutTitleBlock(
              documentParts.distributionByPrioritiesCumulative,
              {
                legend: false,
                fourPart: true
              }
            ),
            renderVerticalLegend(
              documentParts.distributionByPrioritiesCumulative.legend, 80, true
            )
          ]
        ]),
        { style: 'manualPageBreak', text: ' ' },
        // { text: 'Centre Priorities', style: 'tableHeader' },
        ...fundingByPrioritiesTable(
          documentParts.fundingByProvincesCentre,
          documentParts.fundingByRange.start,
          documentParts.fundingByRange.end,
          false
        ),
        ...awardedTablesWtihTotals(documentParts.awardedProjects, {
          priorities: 'centre'
        })
      ],
      pageBreakBefore: pageBreakeBeforeWithManual
    },
    {
      filename: 'AllocationCommittee_'
    }
  )
}

export default generateAllocationCommitteePDF
export {
  svgContainer,
  svgContainerWithoutTitleBlock,
  twoPartPage,
  fourPartPage,
  formatHighlights,
  renderLegend,
  legendSVGImage,
  renderVerticalLegend
}
