import { oppToValue } from './commonCalc'

/**
 * Created by Michał Stawski on 12.03.2020.
 */

const oppToPriorityCentre = res => {
  if (res.Priority_areas__c) {
    return res.Priority_areas__c.split(';')
  } else {
    return []
  }
}

const calcPriorityAreas = priorityFunction => (
  result,
  { noTotal } = { noTotal: true }
) => {
  let calculated = {
    Total: {
      count: 0,
      value: 0
    }
  }
  let total = 0
  let noPriority = 0
  let amount
  let priorities
  console.log('priority calcPriorityAreas ', result)
  for (const res of result) {
    priorities = priorityFunction(res)
    amount = oppToValue(res)
    if (priorities.length) {
      for (const area of priorities) {
        if (!calculated[area]) {
          calculated[area] = {
            count: 0,
            value: 0
          }
        }
        calculated[area].count += 1
        calculated[area].value += amount
        calculated.Total.count += 1
        calculated.Total.value += amount
      }
      total++
    } else {
      noPriority++
    }
  }
  calculated = Object.keys(calculated)
    .sort()
    .reduce(function (acc, key) {
      acc[key] = calculated[key]
      return acc
    }, {})

  const data = []
  for (const areaName in calculated) {
    if (areaName === 'Total' && noTotal) {
      continue
    }
    data.push({
      name: areaName,
      count: calculated[areaName].count,
      value: calculated[areaName].value,
      average: calculated[areaName].count
        ? calculated[areaName].value / calculated[areaName].count
        : 0
    })
  }
  console.log('calcPriorityAres', data)

  return { data: data, total, noPriority }
}

const calcPriorityAreasCentre = calcPriorityAreas(oppToPriorityCentre)

const oppToPriorityCMHC = res => {
  const unrecognized = {}
  const priorities = {}
  const ret = []
  let inDepthReviewCount = 0

  if (res.FGM_Base__Reviews__r) {
    for (const review of res.FGM_Base__Reviews__r.records) {
      if (
        review.RecordType.Name === 'Depth Review STF - Sectoral' ||
        review.RecordType.Name === 'Depth Review STF - Local' ||
        review.RecordType.Name === 'Depth Review CBTIF'
      ) {
        if (!review.FGM_Base__Status__c){
          continue
        }
        inDepthReviewCount ++
        if (review.CMHC_Priority_areas_touched__c) {
          for (const area of review.CMHC_Priority_areas_touched__c.split(';')) {
            priorities[area] = true
          }
        } else {
          console.warn(res, `review: ${review.id} ha no CMHC priority areas`)
        }
      } else {
        unrecognized[review.RecordType.Name] = true
      }
    }
  }
  for (const area in priorities) {
    ret.push(area)
  }
  if (inDepthReviewCount >1 ){
    console.error('to many reviews', res)
  }
  // console.log(ret)
  return ret
}
const calcPriorityAreasCMHC = calcPriorityAreas(oppToPriorityCMHC)

export { calcPriorityAreasCentre, calcPriorityAreasCMHC, oppToPriorityCMHC}
