/**
 * Created by Michał Stawski on 30.01.2020.
 */
import { baseGeneratePDF, CBITF, pageHeader, STF_LP, STF_SI } from './pdfExport'
import { provinceReversMapping } from '../jsforceApi/opportunities'
import moment from 'moment'
import { valueShortened } from '../tabs/pdfcomponents/AllocationCommittee'

const provinces = [
  ...new Set(Object.entries(provinceReversMapping).map(([key, value]) => value))
]
const filterProvinces = (provinceStart, provinceEnd) =>
  provinceEnd
    ? provinces.slice(provinceStart, provinceEnd)
    : provinces.slice(provinceStart)
const valueToK = value => Math.floor(value / 1000)

const projectsByGeoFundStage = (
  table,
  { start, end, fund, isCMHC, dates, provinceStart = null, provinceEnd = null }
) => {
  const localProvinces = filterProvinces(provinceStart, provinceEnd)
  if (!start) {
    start = moment.utc('2019-12-13')
  }
  if (!end) {
    end = moment.utc('2020-03-31')
  }
  console.log('projectsByGeoFundStage', start, end, isCMHC )

  let priorities = []
  const prioritiesSource = {
    Centre: [
      'Reducing the sector’s environmental footprint',
      'Answering to gaps in service for Indigenous communities',
      'Supporting innovative and sustainable business practices',
      'Increasing social inclusion and community engagement',
      'Facilitating growth of the community housing sector'
    ],
    CMHC: [
      'Governance',
      'Asset management',
      'Financial sustainability',
      'Social inclusion',
      'Environmental sustainability'
    ]
  }

  if (isCMHC) {
    priorities = [...prioritiesSource.CMHC]
  } else {
    priorities = [...prioritiesSource.Centre]
  }
  const tableObjects = []
  for (const priority in priorities) {
    tableObjects.push([
      { text: priorities[priority], colSpan: 2, alignment: 'center' },
      {}
    ])
  }

  const getTotal = () => {
    const array = []
    for (let index = 0; index < priorities.length; index++) {
      const priority = priorities[index]
      const count = table[priority] ? table[priority].total.count : '0'
      const value = table[priority]
        ? valueShortened(table[priority].total.value)
        : '0'
      const cell = [
        {
          text: count,
          alignment: 'center',
          style: 'province',
          fillColor: index % 2 ? 'white' : '#8497b0'
        },
        {
          text: value,
          alignment: 'center',
          style: 'province',
          fillColor: index % 2 ? 'white' : '#8497b0',
          noWrap: true
        }
      ]
      array.push(cell)
    }
    return array
  }

  const getColumnsType = () => {
    const array = []
    for (let index = 0; index < priorities.length; index++) {
      const cell = [
        { text: '#', style: 'headerProvince', alignment: 'center' },
        { text: '$K', style: 'headerProvince', alignment: 'center' }
      ]
      array.push(cell)
    }
    return array
  }

  const getRow = item => {
    const array = [
      { text: item, style: 'headerProvince' },
      {
        text: table[priorities[0]] ? table[priorities[0]][item].count : 0,
        style: 'province',
        alignment: 'center',
        fillColor: '#8497b0'
      },
      {
        text: table[priorities[0]]
          ? valueShortened(table[priorities[0]][item].value)
          : 0,
        style: 'province',
        alignment: 'center',
        fillColor: '#8497b0'
      },
      {
        text: table[priorities[1]] ? table[priorities[1]][item].count : 0,
        style: 'province',
        alignment: 'center'
      },
      {
        text: table[priorities[1]]
          ? valueShortened(table[priorities[1]][item].value)
          : 0,
        style: 'province',
        alignment: 'center'
      },
      {
        text: table[priorities[2]] ? table[priorities[2]][item].count : 0,
        style: 'province',
        alignment: 'center',
        fillColor: '#8497b0'
      },
      {
        text: table[priorities[2]]
          ? valueShortened(table[priorities[2]][item].value)
          : 0,
        style: 'province',
        alignment: 'center',
        fillColor: '#8497b0'
      },
      {
        text: table[priorities[3]] ? table[priorities[3]][item].count : 0,
        style: 'province',
        alignment: 'center'
      },
      {
        text: table[priorities[3]]
          ? valueShortened(table[priorities[3]][item].value)
          : 0,
        style: 'province',
        alignment: 'center'
      },
      {
        text: table[priorities[4]] ? table[priorities[4]][item].count : 0,
        style: 'province',
        alignment: 'center',
        fillColor: '#8497b0'
      },
      {
        text: table[priorities[4]]
          ? valueShortened(table[priorities[4]][item].value)
          : 0,
        style: 'province',
        alignment: 'center',
        fillColor: '#8497b0'
      }
    ]
    return array
  }

  let body
  if (table){
    body = [
      [
        {
          text: isCMHC
            ? 'Allocation by CMHC’s  Priorities'
            : 'Allocation by Centre’s Priorities',
          style: 'headerProvince',
          alignment: 'center'
        },
        ...tableObjects.flat()
      ],
      [
        { text: 'Column Type', style: 'headerProvince', alignment: 'center' },
        ...getColumnsType().flat()
      ],
      [
        {
          text: 'Total',
          style: 'headerProvince',
          alignment: 'center'
        },
        ...getTotal().flat()
      ],
      ...localProvinces.map((item, index) => getRow(item).flat())
    ]
  }else{
    body = [[]]
  }

  return {
    margin: [20, 10, 20, 10],
    fontSize: 9,
    table: {
      headerRows: 1,
      widths: ['*', ...priorities.map(item => ['7%', '9%']).flat()],
      body
    }
  }
}

const generateGeoFundPDF = (documentParts, options) => {
  baseGeneratePDF(
    {
      header: pageHeader({
        title: `Priority Report ${options.fields.priorities}`
      }),
      pageOrientation: 'landscape',
      content: [
        { text: 'Consolidated', style: 'tableHeader' },
        projectsByGeoFundStage(documentParts.fundingByProvinces.table.total, {
          fund: 'Consolidated',
          provinceEnd: 7
        }),
        projectsByGeoFundStage(documentParts.fundingByProvinces.table.total, {
          fund: 'Consolidated',
          provinceStart: 7
        }),

        {
          text: 'Community-Based Tenant Initiative Fund (CBTIF)',
          style: 'tableHeader'
        },
        projectsByGeoFundStage(documentParts.fundingByProvinces.table[CBITF], {
          fund: 'CBTI',
          provinceEnd: 7
        }),
        projectsByGeoFundStage(documentParts.fundingByProvinces.table[CBITF], {
          fund: 'CBTI',
          provinceStart: 7
        }),

        {
          text: 'Sector Transformation Fund – Local Project',
          style: 'tableHeader'
        },
        projectsByGeoFundStage(documentParts.fundingByProvinces.table[STF_LP], {
          fund: 'STF-L',
          provinceEnd: 7
        }),
        projectsByGeoFundStage(documentParts.fundingByProvinces.table[STF_LP], {
          fund: 'STF-L',
          provinceStart: 7
        }),

        {
          text: 'Sector Transformation Fund – Sectoral Impact',
          style: 'tableHeader'
        },
        projectsByGeoFundStage(documentParts.fundingByProvinces.table[STF_SI], {
          fund: 'STF-SI',
          provinceEnd: 7
        }),
        projectsByGeoFundStage(documentParts.fundingByProvinces.table[STF_SI], {
          fund: 'STF-SI',
          provinceStart: 7
        })
      ],
      pageBreakBefore: function (
        currentNode,
        followingNodesOnPage,
        nodesOnNextPage,
        previousNodesOnPage
      ) {
        if (currentNode.text && currentNode.style === 'tableHeader') {
          return previousNodesOnPage.length > 1
        } else {
          return false
        }
      }
    },
    {
      filename: 'Funding_'
    }
  )
}

export default generateGeoFundPDF
export { valueToK, filterProvinces, projectsByGeoFundStage }

// const body = [
//   [
//     { text: `Allocation by Priorities ${fund}`, style: 'headerProvince' },
//     { text: 'Total', style: 'headerProvince' },
//     { text: 'Total', style: 'headerProvince' },
//     ...localProvinces.map(item => ([
//       { text: item, style: 'headerProvince' },
//       { text: item, style: 'headerProvince' }
//     ])).flat()
//   ], [
//     { text: `${start.format('MMMM Do YYYY')} - ${end.format('MMMM Do YYYY')}`, style: 'headerProvince' },
//     { text: '$K', style: 'headerProvince' },
//     { text: '#', style: 'headerProvince' },
//     ...localProvinces.map(item => ([
//       { text: '$K', style: 'headerProvince' },
//       { text: '#', style: 'headerProvince' }
//     ])).flat()
//   ]
// ]

// for (const priority in table) {
//   body.push([
//     { text: priority },
//     { text: valueToK(table[priority].total.value), style: 'province', fillColor: '#8497b0' },
//     { text: table[priority].total.count, style: 'province', fillColor: '#8497b0' },
//     ...localProvinces.map((item, index) => ([
//       { text: valueToK(table[priority][item].value), style: 'province', fillColor: index % 2 ? '#8497B0' : 'white' },
//       { text: table[priority][item].count, style: 'province', fillColor: index % 2 ? '#8497B0' : 'white' }
//     ])).flat()
//   ]
//   )
// }
