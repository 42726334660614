import moment from 'moment'

/**
 * Created by Michał Stawski on 12.08.2020.
 */

export const startOfFiscalYear = (referenceDate) => {
  if (moment.utc(referenceDate).month() < 3) {
    return moment.utc({ year: referenceDate.year() - 1, month: 3, date: 1 })
  } else {
    return moment.utc({ year: referenceDate.year(), month: 3, date: 1 })
  }
}

export const endOfFiscalYear = (referenceDate) => {
  return startOfFiscalYear(referenceDate).add(11, 'months').endOf('month')
}


