/**
 * Created by Michał Stawski on 13.03.2020.
 */

//
// const exampleData = [
//   { name: 'Non Profit', value: 4 },
//   { name: 'Coop', value: 3 },
//   { name: 'Social housing', value: 1 },
//   { name: 'Other', value: 0 },
// ]

// const countedTypes = [
//   // Non profit
//   'Non-Profit Housing',
//   'Non-profit Organization',
//   //Coop
//   'Co-op Housing',
//   // Social Housing
//   'Social Housing',
// ]

// rest

// 'Service Provider'
// 'Sector Service Provider'
// 'Community Organization'
// 'Housing Provider'
// 'Tenant Association'

// const calcOther = (calculated) => {
//   let sum = 0
//   for (let type in calculated) {
//     if (countedTypes.indexOf(type) === -1) {
//       sum += calculated[type].count
//     }
//   }
//   return sum
// }

const calcAllocationPerType = (result) => {
  let calculated = {}
  let total = 0, noData = 0

  for (let res of result) {
    if (res.Account.Account_Type__c) {
      for (let area of res.Account.Account_Type__c.split(';')) {
        if (!calculated[area]) {
          calculated[area] = { count: 0 }
        }
        calculated[area].count += 1
      }
      total++
    } else {
      noData++
    }
  }

  let data = []

  for (let type in calculated){
    data.push({
      name: type,
      value: calculated[type].count
    })
  }
  // data.push({
  //   name: 'Non Profit',
  //   value:
  //     calculated['Non-Profit Housing'] ? calculated['Non-Profit Housing'].count : 0 +
  //     calculated['Non - profit Organization'] ? calculated['Non - profit Organization'].count : 0
  // })
  // data.push({
  //   name: 'Coop',
  //   value: calculated['Co-op Housing'] ? calculated['Co-op Housing'].count : 0
  // })
  // data.push({
  //   name: 'Social Housing',
  //   value: calculated['Social Housing'] ? calculated['Social Housing'].count : 0
  // })
  // data.push({
  //   name: 'Other',
  //   value: calcOther(calculated)
  // })
  return { data, total, noData }
}

export default calcAllocationPerType
