/**
 * Created by Michał Stawski on 21.02.2020.
 */
import React from 'react'
import { withStyles } from '@material-ui/core'
import { connect } from 'react-redux'
import { oauth2 } from '../../App'
import { filterOpportunities, getOpportunities } from '../../jsforceApi/opportunities'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import { calcProjectsByGeoFund } from './calc/calcProjectsByGeoFund'
import generateListsPDF from '../../components/pdfListsExport'
import { calcProjectsByGeoFundStage } from './calc/calcProjectsByGeoFundStage'
import { calcProjectsIndigenousByProvince } from './calc/calcProjectsIndigenousByProvince'
import CircularProgress from '@material-ui/core/CircularProgress'
import Typography from '@material-ui/core/Typography'
import LoadingPlaceholder from '../../common/components/LoadingPlaceholder'

const styles = theme => ({
  mainGrid: {
    height: '100%'
  },
  root: {
    flexGrow: 1,
    padding: 10,
    maxHeight: 'calc(100vh - 64px)',
    // overflow:'hidden'
  },
  paper: {
    padding: theme.spacing(1),
    height: 'calc(100vh - 80px)',
    // overflowY: 'scroll',
  },
  tableContainer: {
    // TODO change to calc
    height: 'calc(100% - 92px)'

  },
  gridItem: {
    maxHeight: '100%'
  },
  gridHeader: {
    textAlign: 'center'
  },
  formControl: {
    margin: theme.spacing(3),
  },
  reportType: {
    width: '100%'
  },
  errorContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  }
})

class ListsSummaryInner extends React.Component {
  state = {
    opportunities: [],
    data: {},
    error: null
  }

  componentDidMount () {
    this.refresh()
  }

  refresh () {
    if (!this.conn) {
      this.conn = oauth2.getConnection(this.props.oauth2)
    }
    getOpportunities(this.conn).then((result) => {
      // this.setState({ opportunities: result })
      this.calculateData(result)
    }, (err) => {
      if (err) {
        this.props.handleSFError(err)
        console.error(err)
        this.setState({ error: err.toString() })
      }
    })
  }

  calculateData (result) {
    result = filterOpportunities(result)
    console.log(result)

    let newState = {}
    try {
      /*
      5. List of projects per province and territory for each Fund

      # Fund
      ## Province
      * project
      * project
      */

      newState.projectsByGeoFund = calcProjectsByGeoFund(result)
      /*
      6. List of projects per province and territory by stage and Fund

      # Fund
      ## Province
      ### Stage
      * project
      * project
      */
      newState.projectsByGeoFundStage = calcProjectsByGeoFundStage(result)
      /*
      9.  List of Indigenous projects by province and territory and by Fund
      # Fund
      ## Province
      * project
      * project
      */
      newState.projectsIndigenousByProvince = calcProjectsIndigenousByProvince(result)

      this.setState({ data: newState, error: null })
    } catch (e) {
      console.error(e)
      console.log(newState)
      this.setState({ error: e.toString() })
    }
  }

  render () {
    let { classes } = this.props
    let { data, error } = this.state
    let loading = !Boolean(data.projectsByGeoFund)

    return <Grid container spacing={1} className={classes.mainGrid}>
      <Grid item xs={12} md={8} className={classes.gridItem}>
        <br/>
        {/*{JSON.stringify(data.projectsByGeoFund)}*/}
        <LoadingPlaceholder loading={loading}/>
        {error ? <div style={{ color: 'red' }}>
          <Typography> Error: </Typography>
          {error}
        </div> : ''}

      </Grid>
      <Grid item xs={12} md={4} className={classes.gridItem}>
        <Button fullWidth variant={'contained'} color={'primary'}
                disabled={!Boolean(data.projectsByGeoFund)}
                onClick={() => {
                  generateListsPDF(data)
                }}
        >
          Get Lists PDF
        </Button>
      </Grid>
    </Grid>
  }
}

const mapState = (state, ownProps) => {
  return {
    oauth2: state.oauth2,
  }
}

const mapDispatch = dispatch => ({
  // reLogin: reLogin(dispatch),
  // getSchema: () => dispatch(schemaClientGet()),
})

const ListsSummary = withStyles(styles)(connect(mapState, mapDispatch)(ListsSummaryInner))

export default ListsSummary
