/**
 * Created by Michał Stawski on 02.03.2020.
 */
import React from 'react'
import { WrappedRechart } from './rechartToSVG'
import { Bar, BarChart, LabelList, XAxis, YAxis } from 'recharts'

// const renderCustomizedLabel = ({
//                                  value,
//                                  x, y,
//                                  cx, cy,
//                                  // midAngle, innerRadius,
//                                  // outerRadius, percent, index,
//                                  ...props
//                                }) => {
//   console.log(value, props)
//   let labelSize = value.toString().length
//
//   // return ''
//   return (
//     <text x={x} y={y} fill="black" textAnchor={'center'} dominantBaseline="central">
//       {`${value}%`}
//     </text>
//   )
//
//   return <text
//     name="FLHLMQ"
//     x="368.3333333333333"
//     y="53.75"
//     width="626.6666666666666"
//     height="78"
//     fill="white"
//     viewBox="[object Object]"
//     offset="5"
//     className="recharts-text recharts-label"
//     text-anchor="middle">
//     <tspan x="368.3333333333333" dy="0.355em">
//       2350
//     </tspan>
//   </text>
// }

class FunnelBarChart extends WrappedRechart {
  parseData (data) {
    let sorted = data.sort((a, b) => b.value - a.value)
    let maxValue = sorted[0].value
    // sorted = sorted.slice(0, 4)
    return sorted.map((item, index) => ({ ...item, name: `${item.name}`, value2: (maxValue - item.value) / 2 }))
  }

  render () {
    let { width, height, id, data, yWidth = 150 } = this.props

    let parsedData = this.parseData(data)
    return <BarChart
      width={width}
      height={height}
      data={parsedData}
      ref={(chart) => {
        this.chartRef = chart
      }}
      id={id}
      layout='vertical'
    >
      {/*<CartesianGrid strokeDasharray="3 3"/>*/}
      <XAxis type='number' hide={true}/>
      <YAxis dataKey='name' type='category' width={yWidth}/>
      <Bar dataKey='value2' fill='#ffffff' stackId='a' fillOpacity={0}/>
      <Bar dataKey='value' fill='#4472C4' stackId='a'
        // label={{ fill: 'black' }}

           isAnimationActive={false}
        // label={renderCustomizedLabel}
      >
        <LabelList dataKey="value"/>
      </Bar>
      <Bar dataKey='value2' fill='#ffffff' stackId='a' fillOpacity={0}
           isAnimationActive={false}
      />
    </BarChart>
  }
}

export { FunnelBarChart }
