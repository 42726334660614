import React from 'react'
import { WrappedRechart } from './rechartToSVG'
import {
  LineChart,
  CartesianGrid,
  Legend,
  XAxis,
  YAxis,
  Line,
  Tooltip
} from 'recharts'

class LineChartContainer extends WrappedRechart {
  render () {
    const {
      width,
      height,
      data,
      id,
      barDefinition,
      legend = true,
      yWidth = 250,
      yAxisTick = {},
      margin = { top: 15, right: 5, bottom: 5, left: 15 }
    } = this.props

    console.log(data)
    return (
      <LineChart id={id} data={data} width={width} height={height}>
        <XAxis dataKey='name' />
        <YAxis />
        <Tooltip />
        <Legend />
        <Line type='monotone' dataKey='total' stroke='#8884d8' />
      </LineChart>
    )
  }
}

export { LineChartContainer }
