/**
 * Created by Michał Stawski on 21.02.2020.
 */
import React from 'react'
import { withStyles } from '@material-ui/core'
import { connect } from 'react-redux'
import { oauth2 } from '../../App'
import { filterOpportunities, getOpportunities } from '../../jsforceApi/opportunities'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import LoadingPlaceholder from '../../common/components/LoadingPlaceholder'
import { calcFundingByProvinces } from './calc/calcFundingByProvince'
import generateGeoFundPDF from '../../components/pdfGeoFundingExport'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { summaryOptions } from '../../components/pdfExport'
import Radio from '@material-ui/core/Radio'
import { generateGeoFundExcel } from '../../components/exportGeoFundExcel'

const styles = theme => ({
  mainGrid: {
    height: '100%'
  },
  root: {
    flexGrow: 1,
    padding: 10,
    maxHeight: 'calc(100vh - 64px)',
    // overflow:'hidden'
  },
  paper: {
    padding: theme.spacing(1),
    height: 'calc(100vh - 80px)',
    // overflowY: 'scroll',
  },
  tableContainer: {
    // TODO change to calc
    height: 'calc(100% - 92px)'
  },
  gridItem: {
    maxHeight: '100%'
  },
  gridHeader: {
    textAlign: 'center'
  },
  formControl: {
    margin: theme.spacing(3),
  },
  reportType: {
    width: '100%'
  },
  errorContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  }
})

class GeographicSummaryInner extends React.Component {
  state = {
    opportunities: [],
    error: null,
    fields: {
      priorities: summaryOptions.priorities.CENTRE.key
    },
  }

  componentDidMount () {
    this.refresh()
  }

  handleChangeVariants = (variant) => (e) => {
    let fields = this.state.fields
    fields[variant] = e.target.value
    console.log(fields)
    this.setState({ fields })
  }

  refresh () {
    if (!this.conn) {
      this.conn = oauth2.getConnection(this.props.oauth2)
    }
    getOpportunities(this.conn).then((result) => {
      this.setState({ opportunities: result })
      // this.calculateData(result)
    }, (err) => {
      if (err) {
        this.props.handleSFError(err)
        console.error(err)
        this.setState({ error: err.toString(), opportunities: [] })
      }
    })
  }

  calculateData (result, options ) {
    result = filterOpportunities(result)
    result = result.filter(item => item.StageName === 'Awarded')
    // limit march 31
    console.log(result)

    let newState = {}
    try {
      newState.fundingByProvinces = calcFundingByProvinces(result, options)
      this.setState({ error: null })
      return newState
    } catch (e) {
      console.error(e)
      console.log(newState)
      this.setState({ error: e.toString() })
    }
    return {}
  }

  render () {
    let { classes } = this.props
    let { error, opportunities } = this.state
    let loading = !Boolean(opportunities && opportunities.length)

    return <Grid container spacing={1} className={classes.mainGrid}>
      <Grid item xs={12} md={8} className={classes.gridItem}>
        <br/>
        {/*{JSON.stringify(data.projectsByGeoFund)}*/}
        <LoadingPlaceholder loading={loading}/>
        {error ? <div style={{ color: 'red' }}>
          <Typography> Error: </Typography>
          {error}
        </div> : ''}

      </Grid>
      <Grid item xs={12} md={4} className={classes.gridItem}>
        <Typography variant={'subtitle1'}>
          Report Options
        </Typography>

        <FormControl component="fieldset" className={classes.formControl}>
          <FormLabel component="legend">Priorities</FormLabel>
          <RadioGroup aria-label="general" name="general" value={this.state.fields.priorities}
                      onChange={this.handleChangeVariants('priorities')}>
            {Object.entries(summaryOptions.priorities).map(([key, value]) =>
              <FormControlLabel value={key} control={<Radio/>} label={value.label} key={key}/>
            )}
          </RadioGroup>
        </FormControl>
        <Button fullWidth variant={'contained'} color={'primary'}
                disabled={loading}
                onClick={() => {
                  let data = this.calculateData(opportunities, {...this.state.fields} )
                  generateGeoFundPDF(data, { fields: this.state.fields })
                }}
        >
          Get Funding PDF
        </Button>
        <Button fullWidth variant={'contained'} color={'primary'}
                disabled={loading}
                onClick={() => {
                  let data = this.calculateData(opportunities, {...this.state.fields} )
                  generateGeoFundExcel(data, { fields: this.state.fields })
                }}
        >
          Get Funding Excel
        </Button>
      </Grid>
    </Grid>
  }
}

const mapState = (state, ownProps) => {
  return {
    oauth2: state.oauth2,
  }
}

const mapDispatch = dispatch => ({
  // reLogin: reLogin(dispatch),
  // getSchema: () => dispatch(schemaClientGet()),
})

const GeographicSummary = withStyles(styles)(connect(mapState, mapDispatch)(GeographicSummaryInner))

export default GeographicSummary
