/**
 * Created by Michał Stawski on 30.01.2020.
 */
import { baseGeneratePDF, pageHeader } from './pdfExport'

const projectsByGeoFund = (table) => {
  let fundTables = []

  for (let fund in table) {
    for (let state in table[fund]) {
      fundTables.push({
        margin: [10, 10, 10, 10],
        table: {
          headerRows: 2,
          widths: ['*'],
          body: [
            [{ text: fund, style: 'dashboardTitle', fillColor: '#0087BA' }],
            [{ text: state, style: 'dashboardTitle', fillColor: '#5B9BD4' }],
            ...(table[fund][state].map(item => ([{
              text: item,
              style: 'projectRow'
            }])))
          ]
        }
      })
    }
  }

  return fundTables
}

const projectsByGeoFundStage = (table) => {
  let fundTables = []

  for (let fund in table) {
    for (let state in table[fund]) {
      for (let stage in table[fund][state]) {
        fundTables.push({
          margin: [10, 10, 10, 30],
          table: {
            headerRows: 2,
            widths: ['*'],
            body: [
              [{ text: fund, style: 'dashboardTitle', fillColor: '#0087BA' }],
              [{ text: state, style: 'dashboardTitle', fillColor: '#5B9BD4' }],
              [{ text: stage, style: 'dashboardTitle', fillColor: '#BCD5ED' }],
              ...(table[fund][state][stage].map(item => ([{
                text: item,
                style: 'projectRow'
              }])))
            ]
          }
        })
      }
    }
  }

  return fundTables
}

const generateListsPDF = (documentParts, options) => {
  baseGeneratePDF({
    header: pageHeader({ title: 'Project Lists' }),
    pageOrientation: 'portrait',
    content: [
      { text: 'List of projects per province and territory for each Fund', style: 'tableHeader' },
      ...projectsByGeoFund(documentParts.projectsByGeoFund.table),
      { text: 'List of projects per province and territory by stage and Fund', style: 'tableHeader' },
      ...projectsByGeoFundStage(documentParts.projectsByGeoFundStage.table),
      { text: 'List of Indigenous projects by province and territory and by Fund', style: 'tableHeader' },
      ...projectsByGeoFund(documentParts.projectsIndigenousByProvince.table),
    ],
    pageBreakBefore: function (currentNode, followingNodesOnPage, nodesOnNextPage, previousNodesOnPage) {
      if (currentNode.text && currentNode.style === 'tableHeader') {
        return previousNodesOnPage.length > 1
      } else {
        return false
      }
    },
  }, {
    filename: 'ProjectLists_',
  })
}

export default generateListsPDF
