/**
 * Created by Michał Stawski on 13.03.2020.
 */

import { benchmarksToDate, END_DATE, START_DATE } from '../../../components/pdfExport'
import moment from 'moment'

const lengthData = [
  {
    name: 'OVER ONE YEAR',
    lop: 0,
  },
  {
    name: 'ONE YEAR',
    lop: 0,
  },
  {
    name: 'UNDER ONE YEAR',
    lop: 0,
  },
]

const calcLengthOfProject = (result) => {
  let start, end
  result = result.filter(item => item.StageName === 'Awarded')

  let data = [...lengthData]
  for (let res of result) {
    start = benchmarksToDate(res, START_DATE)
    end = benchmarksToDate(res, END_DATE)

    start = moment(start)
    end = moment(end)
    let years = moment.duration(end - start).asYears().toFixed(1)
    if (years === '1.0') {
      data[1].lop += 1
    } else {
      if (parseFloat(years) > 1) {
        data[0].lop += 1
      } else {
        data[2].lop += 1
      }
    }
  }
  return { data }
}

export default calcLengthOfProject
