/**
 * Created by Michał Stawski on 21.02.2020.
 */
import React from 'react'
import { withStyles } from '@material-ui/core'
import { connect } from 'react-redux'
import { oauth2 } from '../../App'
import { filterOpportunities, getOpportunities } from '../../jsforceApi/opportunities'
import Grid from '@material-ui/core/Grid'
import moment from 'moment'
import Button from '@material-ui/core/Button'
import generateDashboardPDF from '../../components/pdfDashboardExport'
import { AllocationByPriotity } from './charts/AllocationByPriotity'
import rechartToSVG from './charts/rechartToSVG'
import { AllocationPerType } from './charts/AllocationPerType'
import { VerticalBarsChart } from './charts/VerticalBarsChart'
import { HorizontalBarsChart } from './charts/HorizontalBarsChart'
import { FunnelBarChart } from './charts/FunnelBarChart'
import { HorizontalBarsMultiAxisChart } from './charts/HorizontalBarsMultiAxisChart'
import calcNumberOfProjects from './calc/calcNumberOfProjects'
import { calcPriorityAreasCentre, calcPriorityAreasCMHC } from './calc/calcPriorityAreas'
import calcLengthOfProject from './calc/lenghtOfProjects'
import calcAllocationPerType from './calc/calcAllocationPerType'
import calcNumberOfTenants from './calc/calcNumberOfTenants'
import calcOpportunitiesByStage from './calc/calcOpportunitiesByStage'
import calcGeoDistribution from './calc/calcGeoDistribution'
import LoadingPlaceholder from '../../common/components/LoadingPlaceholder'
import Typography from '@material-ui/core/Typography'
import calcOpportunitiesByFundingLevel from './calc/calcOpportunitiesByFundingLevel'

const styles = theme => ({
  mainGrid: {
    height: '100%'
  },
  root: {
    flexGrow: 1,
    padding: 10,
    maxHeight: 'calc(100vh - 64px)',
    // overflow:'hidden'
  },
  paper: {
    padding: theme.spacing(1),
    height: 'calc(100vh - 80px)',
    // overflowY: 'scroll',
  },
  tableContainer: {
    // TODO change to calc
    height: 'calc(100% - 92px)'

  },
  gridItem: {
    maxHeight: '100%'
  },
  gridHeader: {
    textAlign: 'center'
  },
  formControl: {
    margin: theme.spacing(3),
  },
  reportType: {
    width: '100%'
  }

})

class DashboardSummaryInner extends React.Component {
  state = {
    opportunities: [],
    opportunitiesChosen: {},
    reportType: '',
    fields: {
      startDate: new moment().startOf('year'),
      endDate: new moment().endOf('day'),
    },
    error: null
  }

  constructor (props) {
    super(props)
    this.priorityAreasCentre = React.createRef()
    this.priorityAreasCentreCMHC = React.createRef()
    this.numberOfProjects = React.createRef()

    this.allocationPerType = React.createRef()
    this.lengthOfProject = React.createRef()
    this.geoDistribution = React.createRef()
    this.numberOfTenants = React.createRef()

    this.opportunitiesByStage = React.createRef()
    this.opportunitiesByFundingLevel = React.createRef()
  }

  componentDidMount () {
    this.refresh()
  }

  refresh () {
    if (!this.conn) {
      this.conn = oauth2.getConnection(this.props.oauth2)
    }

    getOpportunities(this.conn).then((result) => {
      // this.setState({ opportunities: result })
      this.calculateData(result)
    }, (err) => {
      if (err) {
        this.props.handleSFError(err)
        console.error(err)
        this.setState({ error: err.toString() })
      }
    })
  }

  calculateData (result) {
    result = filterOpportunities(result)
    console.log(result)

    let newState = {}
    try {

      newState.priorityAreasCentre = calcPriorityAreasCentre(result, { noTotal: true })
      newState.numberOfProjects = calcNumberOfProjects(result)
      newState.priorityAreasCentreCMHC = calcPriorityAreasCMHC(result, { noTotal: true })
      newState.lengthOfProject = calcLengthOfProject(result)
      newState.allocationPerType = calcAllocationPerType(result)
      newState.geoDistribution = calcGeoDistribution(result, {})
      newState.numberOfTenants = calcNumberOfTenants(result)
      newState.opportunitiesByStage = calcOpportunitiesByStage(result)
      newState.opportunitiesByFundingLevel = calcOpportunitiesByFundingLevel(result)

      this.setState({ graphData: newState, error: null })
    } catch (e) {
      console.error(e)
      console.log(newState)
      this.setState({ error: e.toString() })
    }
  }

  render () {
    let { classes } = this.props
    let { graphData, error } = this.state
    let loading = !Boolean(graphData)

    return <Grid container spacing={1} className={classes.mainGrid}>
      <Grid item xs={12} md={8} className={classes.gridItem}>
        Dashboard Opportunities
        {!loading ? <div style={{
            // display: 'none'
          }}>
            <AllocationByPriotity
              id={'priorityAreasCentre'}
              ref={(chart) => this.priorityAreasCentre = chart}
              width={400}
              data={graphData.priorityAreasCentre.data}
            />
            <div>
              total: {graphData.priorityAreasCentre.total}
              <br/>
              noPriority: {graphData.priorityAreasCentre.noPriority}
            </div>
            <AllocationByPriotity
              id={'priorityAreasCentreCMHC'}
              ref={(chart) => this.priorityAreasCentreCMHC = chart}
              width={400}
              data={graphData.priorityAreasCentreCMHC.data}
            />
            <div>
              total: {graphData.priorityAreasCentreCMHC.total}
              <br/>
              noData: {graphData.priorityAreasCentreCMHC.noPriority}
            </div>
            <HorizontalBarsChart
              id={'numberOfProjects'}
              ref={(chart) => this.numberOfProjects = chart}
              data={graphData.numberOfProjects.data}
              barDefinition={[
                { dataKey: 'stf_s', fill: '#B1B1B1', name: 'STF - Sectoral' },
                { dataKey: 'stf_l', fill: '#ED8E4D', name: 'STF - Local' },
                { dataKey: 'cbitf', fill: '#6387CB', name: 'CBITF' },
              ]}
            />

            <AllocationPerType
              width={400}
              id={'allocationPerType'}
              ref={(chart) => this.allocationPerType = chart}
              data={graphData.allocationPerType.data}
            />
            <div>
              total: {graphData.allocationPerType.total}
              <br/>
              noData: {graphData.allocationPerType.noData}
            </div>
            <HorizontalBarsChart
              id={'lengthOfProject'}
              ref={(chart) => this.lengthOfProject = chart}
              data={graphData.lengthOfProject.data}
              legend={false}
              barDefinition={[
                { dataKey: 'lop', fill: '#84B862', name: ' ' },
              ]}
            />
            <VerticalBarsChart
              id={'geoDistribution'}
              ref={(chart) => this.geoDistribution = chart}
              yWidth={100}
              data={graphData.geoDistribution.data}
              barDefinition={[
                { dataKey: 'all', fill: '#006FC0', name: 'All proposals' },
                { dataKey: 'submitted', fill: '#FFC000', name: 'Submitted' },
                { dataKey: 'accepted', fill: '#FF0000', name: 'Accepted' },
              ]}
            />
            <FunnelBarChart
              id={'numberOfTenants'}
              ref={(chart) => this.numberOfTenants = chart}
              data={graphData.numberOfTenants.data}
            />

            <HorizontalBarsMultiAxisChart
              id={'opportunitiesByStage'}
              ref={(chart) => this.opportunitiesByStage = chart}
              data={graphData.opportunitiesByStage.data}
              tickFormatter={(data) => {
                if (data === 0) {
                  return 0
                } else if (data < 1000000) {
                  return Math.floor(data / 1000).toString() + 'K'
                } else {
                  return (Math.floor(data / 100000) / 10).toString() + 'M'
                }
              }}
              barDefinition={[{
                dataKey: 'value',
              }]}
            />
            <HorizontalBarsMultiAxisChart
              id={'opportunitiesByFundingLevel'}
              ref={(chart) => this.opportunitiesByFundingLevel = chart}
              data={graphData.opportunitiesByFundingLevel.data}
              tickFormatter={(data) => data}
              barDefinition={[
                {
                  dataKey: 'value', fill: '#d5423c', name: 'Awarded'
                },
                {
                  dataKey: 'value2', fill: '#5B9BD4', name: 'Recived'
                },
              ]}
              fillFunction={null}
              smallTick={{ fontSize: 9 }}
              legend={true}
            />
          </div>
          : <LoadingPlaceholder loading={loading}/>
        }
        {error ? <div style={{ color: 'red' }}>
          <Typography> Error: </Typography>
          {error}
        </div> : ''}
      </Grid>
      <Grid item xs={12} md={4} className={classes.gridItem}>
        Dashboard menu

        {/*<FormControl fullWidth>*/}
        {/*  <InputLabel id="demo-simple-select-readonly-label">*/}
        {/*    Start Date*/}
        {/*  </InputLabel>*/}
        {/*  /!*<DatePicker value={fields.startDate} onChange={this.handleFieldChange('startDate')}/>*!/*/}
        {/*</FormControl>*/}


        <Button fullWidth variant={'contained'} color={'primary'}
                disabled={loading}
                onClick={() => {
                  generateDashboardPDF({
                    allocationTable: [
                      ['CBTIF', 90000, 98327493, 22],
                      ['STF - LOCAL', 23230, 24324324, 12],
                      ['STF - SECTORAL', 0, 2323, 0]
                    ],
                    opportunitiesByStageTable: graphData.opportunitiesByStage.table,
                    priorityAreasCentre: rechartToSVG(this.priorityAreasCentre.chartRef,
                      'Allocation by Centre\'s Priority Areas'
                    ),
                    priorityAreasCentreCMHC: rechartToSVG(this.priorityAreasCentreCMHC.chartRef,
                      'Allocation by CMHC\'s Priority Areas',
                    ),
                    numberOfProjects: rechartToSVG(this.numberOfProjects.chartRef,
                      'Number of Projects'
                    ),
                    allocationPerType: rechartToSVG(this.allocationPerType.chartRef,
                      'Allocation Per Type of Organization'
                    ),
                    geoDistribution: rechartToSVG(this.geoDistribution.chartRef,
                      'Geographical Distribution'
                    ),
                    lengthOfProject: rechartToSVG(this.lengthOfProject.chartRef,
                      'Length of Approved Projects'
                    ),
                    numberOfTenants: rechartToSVG(this.numberOfTenants.chartRef,
                      'Tenants Who Will Benefit per Province'
                    ),
                    opportunitiesByStage: rechartToSVG(this.opportunitiesByStage.chartRef,
                      'Opportunities by Stage'
                    ),
                    opportunitiesByFundingLevel: rechartToSVG(this.opportunitiesByFundingLevel.chartRef,
                      'Opportunities by Funding Level'
                    ),
                  })
                }}
        >
          Get Dashboard PDF
        </Button>
      </Grid>
    </Grid>
  }
}

const mapState = (state, ownProps) => {
  return {
    oauth2: state.oauth2,
  }
}

const mapDispatch = dispatch => ({
  // reLogin: reLogin(dispatch),
  // getSchema: () => dispatch(schemaClientGet()),
})

const DashboardSummary = withStyles(styles)(connect(mapState, mapDispatch)(DashboardSummaryInner))

export default DashboardSummary
