/**
 * Created by Michał Stawski on 01.03.2020.
 */
import React, { PureComponent } from 'react'
import { Bar, BarChart, Legend, Text, XAxis, YAxis } from 'recharts'
import { WrappedRechart } from './rechartToSVG'

class SkewedAxisTick extends PureComponent {
  render () {
    const { x, y, payload } = this.props

    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          dy={16}
          textAnchor='end'
          fill='#666'
          transform='rotate(-35)'
        >
          {payload.value}
        </text>
      </g>
    )
  }
}

const WrappedAxisTick = props => {
  const { x, y, payload, fontSize = 14 } = props

  return (
    <g transform={`translate(${x},${y})`}>
      <Text
        dy={16}
        fontSize={fontSize}
        textAnchor='middle'
        verticalAnchor='start'
        width={100}
      >
        {payload.value}
      </Text>
    </g>
  )
}

class VerticalBarsChart extends WrappedRechart {
  render () {
    const {
      width,
      height,
      id,
      barGap,
      data,
      barDefinition,
      labelsHeight,
      axisLabelKey = 'province',
      skewedAxis = true,
      xAxisTickOptions = {},
      yAxisOptions = {},
      margin
    } = this.props

    console.log(labelsHeight)
    return (
      <BarChart
        id={id}
        ref={chart => (this.chartRef = chart)}
        width={width}
        height={height}
        data={data}
        margin={margin}
        barGap={barGap}
      >
        {skewedAxis ? (
          <XAxis
            dataKey={axisLabelKey}
            tick={<SkewedAxisTick />}
            height={120}
            interval={0}
          />
        ) : (
          <XAxis
            dataKey={axisLabelKey}
            interval={0}
            width={90}
            height={labelsHeight}
            tick={<WrappedAxisTick {...xAxisTickOptions} />}
          />
        )}
        <YAxis {...yAxisOptions}/>
        <Legend />
        {barDefinition.map((item, index) => {
          const { children, ...rest } = item
          return (
            <Bar key={index} isAnimationActive={false} {...rest}>
              {children}
            </Bar>
          )
        })}
      </BarChart>
    )
  }
}

export { VerticalBarsChart, SkewedAxisTick, WrappedAxisTick }
