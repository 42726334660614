import { oppToState, oppToValue } from './commonCalc'

/**
 * Created by Michał Stawski on 17.03.2020.
 */


// const exampleData = [
//   {
//     status: 'In Progress',
//     projectType: 'CBTF',
//     value: 300000,
//   },
//   {
//     status: 'Declined',
//     projectType: 'CBTF',
//     value: 10000
//   },
//   {
//     status: 'Validated',
//     projectType: 'CBTF',
//     value: 100444
//   },
//   {
//     status: 'More Info Required',
//     projectType: 'CBTF',
//     value: 0
//   },
//   {
//     status: 'In Progress',
//     projectType: 'STF - Local Project',
//     value: 865611,
//   },
//   {
//     status: 'Submitted',
//     projectType: 'STF - Local Project',
//     value: 10000
//   },
//   {
//     status: 'Validated',
//     projectType: 'STF - Local Project',
//     value: 24489
//   },
//   {
//     status: 'More Info Required',
//     projectType: 'STF - Local Project',
//     value: 149923
//   },
//   {
//     status: 'Submitted',
//     projectType: 'STF - Sectoral Impact',
//     value: 2126465
//   },
//   {
//     status: 'Validated',
//     projectType: 'STF - Sectoral Impact',
//     value: 0
//   },
//   {
//     status: 'More Info Required',
//     projectType: 'STF - Sectoral Impact',
//     value: 72800
//   },
// ]

// const exampleTable = [
//   // stageName, amount CBTIF, count CBTIF, sum STF LP, count STF LP, sum STF-SI, count STF-IS,
//   // sum total, count total
//
//   ['In Progress', 278860, 11, 856611.52, 15, 2126475, 10, 3270946.52, 36],
//   ['Submitted', 278860, 11, 856611.52, 15, 2126475, 10, 3270946.52, 36],
//   ['First Review', 278860, 11, 856611.52, 15, 2126475, 10, 3270946.52, 36],
//   ['More info required', 278860, 11, 856611.52, 15, 2126475, 10, 3270946.52, 36],
// ]

const calcOpportunitiesByStage = (result) => {
  let calculated = {}

  for (let res of result) {
    if (!calculated[res.StageName]) {
      calculated[res.StageName] = {
        stf_l_v: 0,
        stf_s_v: 0,
        cbitf_v: 0,
        stf_l_c: 0,
        stf_s_c: 0,
        cbitf_c: 0
      }
    }
    if (res.RecordType.Name === 'Sector Transformation Fund – Sectoral Impact') {
      calculated[res.StageName].stf_s_c += 1
      calculated[res.StageName].stf_s_v += oppToValue(res)
    } else if (res.RecordType.Name === 'Sector Transformation Fund – Local Project') {
      calculated[res.StageName].stf_l_c += 1
      calculated[res.StageName].stf_l_v += oppToValue(res)
    } else if (res.RecordType.Name === 'Community-Based Tenant Initiative Fund (CBTIF)') {
      calculated[res.StageName].cbitf_c += 1
      calculated[res.StageName].cbitf_v += oppToValue(res)
    }

  }
  let data = []
  let table = []
  let totalRow = {
    stf_l_v: 0,
    stf_s_v: 0,
    cbitf_v: 0,
    stf_l_c: 0,
    stf_s_c: 0,
    cbitf_c: 0,
    total_c: 0,
    total_v: 0
  }

  let row
  for (let stage in calculated) {
    row = calculated[stage]
    data.push({
      status: stage,
      projectType: 'STF - Sectoral Impact',
      value: row.stf_s_v
    })
    data.push({
      status: stage,
      projectType: 'STF - Local Project',
      value: row.stf_l_v
    })
    data.push({
      status: stage,
      projectType: 'CBTF',
      value: row.cbitf_v
    })

    table.push([
      stage, row.cbitf_v, row.cbitf_c, row.stf_l_v, row.stf_l_c, row.stf_s_v, row.stf_s_c,
      row.cbitf_v + row.stf_l_v + row.stf_s_v,
      row.cbitf_c + row.stf_l_c + row.stf_s_c
    ])
    for (let value in row) {
      totalRow[value] += row[value]

    }
    totalRow.total_v += row.cbitf_v + row.stf_l_v + row.stf_s_v
    totalRow.total_c += row.cbitf_c + row.stf_l_c + row.stf_s_c
  }
  table.push([
    'Total',
    totalRow.cbitf_v, totalRow.cbitf_c, totalRow.stf_l_v, totalRow.stf_l_c, totalRow.stf_s_v, totalRow.stf_s_c,
    totalRow.total_v, totalRow.total_c
  ])
  // data = data.filter(item => item.value)
  return { data, table }
}

const calcOpportunitiesByProvinceByStage = (result) => {
  let calculated = {}
  let state

  for (let res of result) {
    state = oppToState(res)
    if (!calculated[res.StageName]) {
      calculated[res.StageName] = {}
    }

    if (!calculated[res.StageName][state]) {
      calculated[res.StageName][state] = {
        value: 0,
        count: 0
      }
    }

    calculated[res.StageName][state].value += oppToValue(res)
    calculated[res.StageName][state].count += 1

  }
  let data = []
  let totalRow = {}

  let row
  for (let stage in calculated) {
    row = calculated[stage]
    for (let province in calculated[stage]) {
      data.push({
        status: stage,
        province: province,
        value: row[province].value,
        count: row[province].count
      })
      if (!totalRow[province]) {
        totalRow[province] = {
          value: 0,
          count: 0
        }
      }

      totalRow[province].value += row[province].value
      totalRow[province].count += row[province].count
    }
  }
  // data = data.filter(item => item.value)
  return { data }
}
export default calcOpportunitiesByStage
export { calcOpportunitiesByProvinceByStage }
