/**
 * Created by Michał Stawski on 30.01.2020.
 */
// import React from 'react'
import pdfMake from 'pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import headerImage from '../media/chtc_header_small.png'
import he from 'he'
import { opportunityToPriorities } from '../jsforceApi/opportunities'
import { oppToFundType } from '../tabs/pdfcomponents/calc/commonCalc'
import { oppToPriorityCMHC } from '../tabs/pdfcomponents/calc/calcPriorityAreas'

const opportunityFields = [
  { name: 'Date of Request', field: 'CloseDate' },
  { name: 'Funds', field: '' }, // grant project
  { name: 'Organization’s name', field: 'AccountId' },
  { name: 'Project’s Name', field: 'Name' },
  { name: 'Grant requested', field: 'FGM_Base__Amount_Requested__c' },
  { name: 'Grant awarded', field: 'Amount' },
  { name: 'Centre’s Priorities', field: 'Priority_areas__c' },
  { name: 'Impact', field: 'The_project_and_transformational_impact__c' },
  { name: 'Targeted Demographics', field: 'Demographic__c' },
  { name: 'Area', field: 'Geographical_scope__c' },
  { name: 'Community', field: '' },
  { name: 'Start', field: 'npsp__Grant_Period_Start_Date__c' },
  { name: 'End', field: 'npsp__Grant_Period_End_Date__c' },
  { name: 'Project’s objectives', field: 'Objectives__c' },
  { name: 'Detailed description', field: 'Detailed_description__c' },
  { name: 'Acknowledgment Status', field: 'npsp__Acknowledgment_Status__c' },
  { name: 'Stage', field: 'StageName' },
  { name: 'LeadSource', field: 'LeadSource' },
  { name: 'Description', field: 'Description' },

  { name: 'Number of tenants', field: 'Number_of_tenants__c' },
  { name: 'Buildings', field: 'Buildings__c' },
  { name: 'Units', field: 'Units__c' },
  { name: 'Tenants number', field: 'Tenants_number__c' },
  { name: 'Organizations number', field: 'Organizations_number__c' },
  { name: 'Staff', field: 'Staff__c' },
  { name: 'Summary', field: 'Summary__c' },
  // { name: 'CreationDate', field: 'CreationDate' },

  { name: 'Geographical Location', field: 'Geographical_Location__c' },
  {
    name: 'Geographical Scope province',
    field: 'Geographical_scope_detailed__c'
  },

  { name: 'Grant Recommended', field: 'FGM_Base__Recommended_Amount__c' }
]

const htmlToText = html => {
  if (!html) {
    return html
  }
  html = html.replace(/<style([\s\S]*?)<\/style>/gi, '')
  html = html.replace(/<script([\s\S]*?)<\/script>/gi, '')
  html = html.replace(/<\/div>/gi, '\n\n')
  html = html.replace(/<\/li>/gi, '\n')
  html = html.replace(/<li>/gi, '  *  ')
  html = html.replace(/<\/ul>/gi, '\n\n')
  html = html.replace(/<\/p>/gi, '\n\n')
  html = html.replace(/<br\s*[/]?>/gi, '\n')
  html = html.replace(/<[^>]+>/gi, '')

  html = he.decode(html)
  return html
}

const START_DATE = 'START'
const END_DATE = 'END'
const benchmarksToDate = (opp, date) => {
  let type, field
  switch (date) {
    case START_DATE:
      type = 'Planning / initiation stage'
      field = 'FGM_Base__Due_Date__c'
      break
    case END_DATE:
      type = 'Closure stage'
      field = 'FGM_Base__Completion_Date__c'
      break
    default:
      console.error('unknown date type in conversion from benchmark')
      return null
  }
  try {
    const benchmark = opp.FGM_Base__Benchmarks__r.records.filter(
      item => item.FGM_Base__Status__c === type
    )
    return benchmark[0][field]
  } catch (e) {
    console.error('benchmarks not set', e)
    return null
  }
}

const objectivesToText = (opp, options) => {
  const outcomes = opp.Objectives__r.records.map(item => {
    return !item.Objective__c
      ? []
      : [
        { text: (item.Objective__c || 'N/A') + '\n\n', style: 'bold' },
        ...(options.fields.objectives === summaryOptions.objectives.ALL
          ? [
            { text: 'Identified Need:\n', style: 'bold' },
            `${item.Identified_needs__c} \n\n`
          ]
          : []),
        { text: 'Outcomes:\n', style: 'bold' },
        item.Outcomes__c ? `${item.Outcomes__c} \n\n` : 'Empty \n\n'
      ]
  })

  return outcomes.reduce((a, i, ci, arr) => {
    a.push(...i)
    return a
  }, [])
}

const emptyCheckbox =
  '<svg xmlns="http://www.w3.org/2000/svg" fill="black" width="10" height="10" viewBox="0 0 24 24"><path d="M22 2v20h-20v-20h20zm2-2h-24v24h24v-24z"/></svg>'
const markedCheckbox =
  '<svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="black" viewBox="0 0 24 24"><path d="M22 2v20h-20v-20h20zm2-2h-24v24h24v-24zm-6 16.538l-4.592-4.548 4.546-4.587-1.416-1.403-4.545 4.589-4.588-4.543-1.405 1.405 4.593 4.552-4.547 4.592 1.405 1.405 4.555-4.596 4.591 4.55 1.403-1.416z"/></svg>'
const CHMCObjectivesArray = [
  'Asset management',
  'Governance Governance',
  'Financial sustainability',
  'Environmental sustainability',
  'Social inclusion'
]
const PriorityAreas = [
  'Reducing the sector’s environmental footprint',
  'Answering to gaps in service for Indigenous communities',
  'Supporting innovative and sustainable business practices',
  'Increasing social inclusion and community engagement',
  'Facilitating growth of the community housing sector'
]

const CHMCPriorities = (list, list2) => {
  const readCHMCObjectivesArray = list.map(item => [
    list2.includes(item) ? { svg: markedCheckbox } : { svg: emptyCheckbox },
    item
  ])
  return {
    layout: 'noBorders',
    table: { body: readCHMCObjectivesArray },
    colSpan: 2
  }
}

const CentreObjectives = opp => {
  const CentreObjectivesList = opp.Objectives__r.records.map(
    i => i.Objective__c
  )
  return CentreObjectivesList
}

const listToLi = (list, props) => {
  let li
  if (!list) {
    return list
  }
  if (typeof list === 'string') {
    li = list.split(';')
  } else {
    li = list
  }
  return { ul: li.map(item => item), ...props }
}

const STF_LP = 'Sector Transformation Fund – Local Project'
const STF_SI = 'Sector Transformation Fund – Sectoral Impact'
const CBITF = 'Community-Based Tenant Initiative Fund (CBTIF)'

const oppToImpact = opp => {
  const content = []

  if (oppToFundType(opp) === 'stf_s' || oppToFundType(opp) === 'stf_l') {
    // content.push( ['STF'])
    content.push({ text: 'Buildings:', style: 'bold' })
    content.push(opp.Buildings__c)
    content.push({ text: 'Units:', style: 'bold' })
    content.push(opp.Units__c)
    content.push({ text: 'Tenants number:', style: 'bold' })
    content.push(opp.Tenants_number__c)
    content.push({ text: 'Organizations number:', style: 'bold' })
    content.push(opp.Organizations_number__c)
    content.push({ text: 'Staff:', style: 'bold' })
    content.push(opp.Staff__c)
  } else if (oppToFundType(opp) === 'cbtif') {
    // content.push( ["CBITF"])
    content.push({ text: 'Number of Tenants:', style: 'bold' })
    content.push(opp.Number_of_tenants__c)
  } else {
    content.push('Unknown Fund Type')
    console.warn('Unknown Campaign', opp.Campaign, opp)
  }
  return content
}

const summaryOptions = {
  general: {
    NORMAL: 'NORMAL',
    ANONYMOUS: 'ANONYMOUS'
  },
  description: {
    SHORT: 'SHORT',
    FULL: 'FULL',
    SUMMARY: 'SUMMARY'
  },
  objectives: {
    NONE: 'NONE',
    ALL: 'ALL',
    ONLY_OUTCOMES: 'ONLY_OUTCOMES'
  },
  priorities: {
    CENTRE: { key: 'CENTRE', label: 'Centre' },
    CMHC: { key: 'CMHC', label: 'CMHC' }
  }
}

const summaryTypes = {
  LT_50K: {
    value: 'LT_50K',
    title: 'Executive Summary <50k',
    fields: {
      general: summaryOptions.general.NORMAL,
      description: summaryOptions.description.SHORT,
      objectives: summaryOptions.objectives.NONE,
      priorities: summaryOptions.priorities.CENTRE
    }
  },
  GT_50K: {
    value: 'GT_50K',
    title: 'Executive Summary >50k',
    fields: {
      general: summaryOptions.general.NORMAL,
      description: summaryOptions.description.SHORT,
      objectives: summaryOptions.objectives.ONLY_OUTCOMES,
      priorities: summaryOptions.priorities.CENTRE
    }
  },
  CMHC: {
    value: 'CMHC',
    title: 'Executive Summary CHMC',
    fields: {
      general: summaryOptions.general.ANONYMOUS,
      description: summaryOptions.description.SHORT,
      objectives: summaryOptions.objectives.ONLY_OUTCOMES,
      priorities: summaryOptions.priorities.CMHC
    }
  }
}

const pageHeader = options => {
  return [
    {
      alignment: 'justify',
      columns: [
        // {
        //   svg:  '<?xml version="1.0" encoding="utf-8"?>\n' +
        //     '<!-- Generator: Adobe Illustrator 16.0.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->\n' +
        //     '<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">\n' +
        //     '<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"\n' +
        //     '\t width="216px" height="216px" viewBox="0 0 216 216" enable-background="new 0 0 216 216" xml:space="preserve">\n' +
        //     '<polygon fill="#0A87BA" points="112.766,113.264 112.766,167.409 151.051,205.692 171.89,184.854 122.832,135.798 143.379,115.251 \n' +
        //     '\t192.438,164.309 205.195,151.55 166.912,113.264 "/>\n' +
        //     '<polygon fill="#5FBB92" points="103.768,113.264 49.623,113.264 11.338,151.55 32.176,172.387 81.234,123.329 101.782,143.876 \n' +
        //     '\t52.724,192.938 65.482,205.692 103.768,167.409 "/>\n' +
        //     '<polygon fill="#02718A" points="103.768,104.4 103.768,50.255 65.482,11.97 44.644,32.809 93.701,81.866 73.153,102.414 \n' +
        //     '\t24.096,53.354 11.338,66.115 49.623,104.4 "/>\n' +
        //     '<polygon fill="#7CC246" points="112.766,104.399 166.912,104.399 205.195,66.114 184.356,45.274 135.3,94.334 114.753,73.787 \n' +
        //     '\t163.812,24.727 151.051,11.969 112.766,50.254 "/>\n' +
        //     '</svg>\n',
        //
        //   height: 50,
        //   width: 50
        // },
        {
          // you'll most often use dataURI images on the browser side
          // if no width/height/fit is provided, the original size will be used
          image: headerImage,
          width: 170,
          alignment: 'left',
          margin: [10, 10, 10, 0]
        },
        options.section
          ? {
            width: '*',
            text: options.section,
            alignment: 'center',
            fontSize: 20,
            bold: true,
            margin: [20, 20, 30, 0]
          }
          : {
            text: ''
          },
        {
          width: 170,
          text: options.title,
          fontSize: 8,
          alignment: 'right',
          margin: [0, 5, 5, 0]
        },
      ]
    },
    options.header
      ? {
        width: '*',
        text: options.header,
        alignment: 'center',
        margin: [0, 0, 0, 0],
        fontSize: 12,
        // bold: true
      }
      : {
        text: ''
      },
    options.subheader
      ? {
        width: '*',
        text: options.subheader,
        alignment: 'center',
        margin: [0, 0, 0, 0],
        fontSize: 12,
        // bold: true
      }
      : {
        text: ''
      },
    options.dateString
      ? {
        width: '*',
        text: options.dateString,
        alignment: 'center',
        margin: [0, 0, 0, 0],
        fontSize: 8,
        // bold: true
      }
      : {
        text: ''
      }
  ]
}
const pageFooter = (currentPage, pageCount) => {
  return ''
  // return {
  //   text: currentPage.toString() + ' of ' + pageCount,
  //   alignment: 'right',
  //   margin: [0, 0, 5, 5]
  // }
}

const moment = require('moment')
const opportunitiesToContent = (opportunities, options) => {
  const content = []

  for (const opp of opportunities) {
    const body = []
    body.push(
      [
        {
          text: 'General information:',
          style: 'tableHeader',
          colSpan: 6,
          alignment: 'Left'
        },
        {},
        {},
        {},
        {},
        {}
      ],
      [
        { text: 'Submission Date', style: 'tableHeader2' },
        {
          text: moment(opp.CloseDate).format('MMM Do YY'),
          style: 'tableContent2'
        },
        { text: 'Project Start Date', style: 'tableHeader2' },
        {
          text: moment(benchmarksToDate(opp, START_DATE)).format('MMMM YY'),
          style: 'tableContent2'
        },
        { text: 'Project End Date', style: 'tableHeader2' },
        {
          text: moment(benchmarksToDate(opp, END_DATE)).format('MMMM YY'),
          style: 'tableContent2'
        }
      ]
    )

    if (options.fields.priorities === summaryOptions.priorities.CENTRE.key) {
      body.push([
        { text: 'Organization’s name', style: 'tableHeader2' },
        { text: opp.Account.Name, colSpan: 5 },
        {},
        {},
        {},
        {}
      ])
    }
    if (options.fields.general === summaryOptions.general.NORMAL) {
      body.push([
        { text: 'Project’s Name', style: 'tableHeader2' },
        { text: opp.Name, colSpan: 2 },
        {},
        { text: 'Organization type', style: 'tableHeader2' },
        // TODO Wstawić pole z typem organizacji
        { text: opp.Type, colSpan: 2 },
        {}
      ])
    }
    body.push([
      {
        text:
          options.fields.priorities === summaryOptions.priorities.CENTRE.key
            ? 'Centre’s Objectives'
            : 'CHMC Priorities',
        style: 'tableHeader2'
      },
      options.fields.priorities === summaryOptions.priorities.CENTRE.key
        ? listToLi(CentreObjectives(opp), { colSpan: 2 })
        : CHMCPriorities(
        CHMCObjectivesArray,
        oppToPriorityCMHC(opp),
        { colSpan: 2 }
        ),
      {},
      { text: 'Centre’s Priority areas', style: 'tableHeader2' },
      CHMCPriorities(PriorityAreas, opp.Priority_areas__c, { colSpan: 2 }),
      {}
    ])
    body.push([
      { text: 'Demographic', style: 'tableHeader2' },
      listToLi(opp.Demographic__c, { colSpan: 2 }),
      {},
      { text: 'Area + scope', style: 'tableHeader2' },
      { text: opp.Geographical_scope__c, colSpan: 2 },
      {}
    ])
    if (options.fields.priorities === summaryOptions.priorities.CENTRE.key) {
      body.push([
        { text: 'Overall budget', style: 'tableHeader2' },
        // TODO Wstawić Overall_budget
        { text: '' },
        { text: 'Amount Requested', style: 'tableHeader2' },
        { text: opp.FGM_Base__Amount_Requested__c },
        { text: 'Amount Recommended', style: 'tableHeader2' },
        // TODO Wstawic Ammount_Recommended
        { text: '' }
      ])
    } else {
      body.push([
        { text: 'Amount requested', style: 'tableHeader2' },
        { text: opp.FGM_Base__Amount_Requested__c, colSpan: 2 },
        {},
        { text: 'Amount Recommended', style: 'tableHeader2' },
        // TODO Wstawić Ammount_Recommended
        { text: 'value', colSpan: 2 },
        {}
      ])
    }
    let description
    if (options.fields.description === summaryOptions.description.FULL) {
      description = {
        title: 'Project’s short description',
        field: 'Description'
      }
    } else if (
      options.fields.description === summaryOptions.description.SHORT
    ) {
      description = {
        title: 'Project’s description',
        field: 'Detailed_description__c'
      }
    } else {
      description = { title: 'Summary', field: 'Summary__c' }
    }
    body.push(
      [
        { text: description.title, colSpan: 6, style: 'tableHeader' },
        {},
        {},
        {},
        {},
        {}
      ],
      [
        {
          colSpan: 6,
          text: htmlToText(opp[description.field]),
          style: 'tableContent'
        },
        {},
        {},
        {},
        {},
        {}
      ]
    )

    if (options.fields.objectives !== summaryOptions.objectives.NONE) {
      body.push(
        [
          { text: 'Impact', colSpan: 6, style: 'tableHeader' },
          {},
          {},
          {},
          {},
          {}
        ],
        [
          { text: oppToImpact(opp), colSpan: 6, style: 'tableContent' },
          {},
          {},
          {},
          {},
          {}
        ]
      )
    }

    body.push(
      [
        { text: 'Evaluation', colSpan: 6, style: 'tableHeader' },
        {},
        {},
        {},
        {},
        {}
      ],
      [
        {
          text: 'No. of external Reviewer(s)',
          style: 'tableEvaluationHeader',
          colSpan: 2
        },
        {},
        { text: '' },
        {
          text:
            options.fields.priorities === summaryOptions.priorities.CENTRE.key
              ? 'Program manager’s Recommendation'
              : 'Centre’s Recommendation',
          style: 'tableEvaluationHeader'
        },
        // TODO Wstawić pole Program Manager's Recommendation
        { text: '', colSpan: 2 },
        {}
      ]
    )
    body.push(
      [
        {
          text:
            options.fields.priorities === summaryOptions.priorities.CENTRE.key
              ? 'Program manager’s Feedback'
              : 'Centre’s Feedback',
          colSpan: 6,
          style: 'tableEvaluationHeader'
        },
        {},
        {},
        {},
        {},
        {}
      ],
      // TODO Wstawić pole Program Manager's Feedback
      [{ text: '', colSpan: 6 }, {}, {}, {}, {}, {}]
    )

    const table = {
      layout: 'opportunityLayout',
      table: {
        // headers are automatically repeated if the table spans over multiple pages
        // you can declare how many rows should be treated as headers
        tableId: 'opportunityTable',
        headerRows: 0,
        margin: [10, 50, 0, 100],
        widths: [100, 80, 70, 100, 80, 70],
        body: body
      }
    }
    content.push(table)
    content.push({ text: '', margin: 20 })
  }
  return content
}

const generatePDF = (opportunities, options) => {
  if (!opportunities || !opportunities.length) {
    console.warn('no opportunities chosen')
    return
  }

  baseGeneratePDF(
    {
      header: pageHeader({
        title: options.reportType
          ? summaryTypes[options.reportType].title
          : 'Executive Summary'
      }),
      footer: pageFooter,
      pageBreakBefore: function (
        currentNode,
        followingNodesOnPage,
        nodesOnNextPage,
        previousNodesOnPage
      ) {
        // return currentNode.headlineLevel === 1 && followingNodesOnPage.length === 0
        if (
          currentNode.table &&
          currentNode.table.tableId === 'opportunityTable'
        ) {
          return currentNode.startPosition.verticalRatio > 0.2
        } else if (
          currentNode.table &&
          currentNode.table.tableId === 'mainTable'
        ) {
          console.log('mainTable break')
          return true
        } else {
          return false
        }
      },
      content: opportunitiesToContent(opportunities, options)
    },
    {
      filename: 'ExecutiveSummary_'
    }
  )
}

const baseGeneratePDF = (documentParts, options) => {
  const currentDate = new Date()
  pdfMake.vfs = pdfFonts.pdfMake.vfs
  let host
  if (process.env.NODE_ENV === 'development') {
    host = 'http://localhost:3000'
  } else {
    // host = `${process.env.PUBLIC_URL}`
    host = 'https://pdf.chtc.shiva.magnaro.com'
  }
  // console.log(host)
  pdfMake.fonts = {
    //   // download default Roboto font from cdnjs.com
    Roboto: {
      // normal: '/fonts/Roboto-Regular.ttf',
      // bold: '/fonts/Roboto-Bold.ttf',
      // italics: '/fonts/Roboto-Italic.ttf',
      // bolditalics: '/fonts/Roboto-BoldItalic.ttf',
      normal:
        'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
      bold:
        'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
      italics:
        'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
      bolditalics:
        'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf'
    },
    FirstNations: {
      normal: `${host}/fonts/First-Nations-Unicode-Regular.ttf`,
      bold: `${host}/fonts/First-Nations-Unicode-Bold.ttf`,
      italics: `${host}/fonts/First-Nations-Unicode-Italic.ttf`,
      bolditalics: `${host}/fonts/First-Nations-Unicode-BoldItalic.ttf`
    }
  }
  // console.log(pdfMake.fonts)

  const swUrl = `${process.env.public_url}/service-worker.js`
  pdfMake.tableLayouts = {
    opportunityLayout: {
      hLineWidth: function (i, node) {
        return i === 0 || i === node.table.body.length ? 0.5 : 0.5
      },
      vLineWidth: function (i, node) {
        return i === 0 || i === node.table.widths.length ? 0.5 : 0.5
      },
      hLineColor: function (i, node) {
        return i === 0 || i === node.table.body.length ? '#000000' : 'black'
      },
      vLineColor: function (i, node) {
        return i === 0 || i === node.table.widths.length ? '#000000' : 'black'
      }
      // hLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
      // vLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
      // paddingLeft: function(i, node) { return 4; },
      // paddingRight: function(i, node) { return 4; },
      // paddingTop: function(i, node) { return 2; },
      // paddingBottom: function(i, node) { return 2; },
      // fillColor: function (rowIndex, node, columnIndex) { return null},
    },
    dashboardLayout: {
      hLineWidth: function (i, node) {
        return i === 0 || i === node.table.body.length ? 0.5 : 0.5
      },
      vLineWidth: function (i, node) {
        return i === 0 || i === node.table.widths.length ? 0.5 : 0.5
      },
      hLineColor: function (i, node) {
        return i === 0 || i === node.table.body.length ? '#000000' : 'black'
      },
      vLineColor: function (i, node) {
        return i === 0 || i === node.table.widths.length ? '#000000' : 'black'
      }
    },
    pageFourLayout: {
      hLineWidth: function (i, node) {
        return 0
      },
      vLineWidth: function (i, node) {
        return 0
      },
      hLineColor: function (i, node) {
        // return 'red'
        return 'white'
      },
      vLineColor: function (i, node) {
        // return 'red'
        return 'white'
      }
    },
    allocationCommitteeLayout: {
      hLineWidth: function (i, node) {
        return 0
      },
      vLineWidth: function (i, node) {
        return 0
      },
      paddingBottom: function (i, node) {
        return 10
      }
    },
    awardedTableLayout: {
      hLineWidth: function (i, node) {
        return 1
      },
      vLineWidth: function (i, node) {
        return 1
      },
      hLineColor: function (i, node) {
        return '#a7a7a7'
      },
      vLineColor: function (i, node) {
        return '#a7a7a7'
      }
    }
  }

  pdfMake
    .createPdf({
      pageMargins: [25, 60, 25, 20],
      pageSize: 'LETTER',
      defaultStyle: {
        font: 'Roboto'
      },
      styles: {
        bold: {
          bold: true
        },
        header: {
          fontSize: 18,
          bold: true,
          margin: [0, 0, 0, 10]
        },
        subheader: {
          fontSize: 16,
          bold: true,
          margin: [0, 10, 0, 5]
        },
        tableStyle: {
          border: 0,
          borderColor: 'white'
        },
        tableExample: {
          margin: [0, 5, 0, 15]
        },
        tableHeader: {
          bold: false,
          fontSize: 10,
          color: 'black',
          fillColor: '#deeaf6',
          alignment: 'left'
        },
        tableHeader2: {
          fontsize: 2,
          bold: false,
          alignment: 'left',
          fillColor: '#e1f2d6'
        },
        tableEvaluationHeader: {
          fillColor: '#d0face',
          alignment: 'left'
        },
        tableContent: {
          fontsize: 5,
          bold: false,
          color: '#848587',
          alignment: 'left'
        },
        tableContent2: {
          fontsize: 7,
          bold: false,
          color: 'black',
          alignment: 'left'
        },
        acceptedHeader: {
          bold: true,
          fontSize: 13,
          color: 'black',
          fillColor: '#7bc249',
          alignment: 'center'
        },
        rejectedHeader: {
          bold: true,
          fontSize: 13,
          color: 'black',
          fillColor: '#d5423c',
          alignment: 'center'
        },
        dashboardHeader: {
          fillColor: '#5b9bd4',
          color: 'white',
          alignment: 'center'
        },
        dashboardHeaderSide: {
          fillColor: '#5B9BD4',
          color: 'white',
          alignment: 'left'
        },
        dashboardEven: {
          fillColor: '#BCD5ED',
          color: 'black',
          alignment: 'center'
        },
        dashboardOdd: {
          fillColor: '#DEEAF6',
          color: 'black',
          alignment: 'center'
        },
        dashboardTitle: {
          alignment: 'center',
          color: 'black',
          fontSize: 13
        },
        dashboardLegend: {
          alignment: 'left',
          color: 'black',
          fontSize: 9
        },
        h1Row: {
          alignment: 'center',
          color: 'black',
          fontSize: 13
        },
        headerProvince: {
          color: 'white',
          fillColor: '#203864',
          alignment: 'center'
        },
        province: {
          alignment: 'right'
        },
        allocationFilled: {
          fillColor: '#40BAD2',
          color: 'white',
          alignment: 'left',
          margin: [8, 8],
          fontSize: 8
        },
        highlightsHeader: {
          bold: true,
          fontSize: 15,
          margin: [20, 20],
          color: '#595959',
          alignment: 'left'
        },
        highlightsList: {
          fontSize: 10,
          alignment: 'left',
          margin: [20, 0],
          color: '#595959'
        },
        awardedProjectsHeader: {
          bold: true,
          fillColor: '#E5E4E5',
          color: '#55565B',
          fontSize: 8

          // border color D5D3D1
        },
        awardedProjectsFirst: {
          // fillColor: '#F9F9F7',
          // color: '#595959',
          fontSize: 8
        },
        awardedProjectsBody: {
          fontSize: 8,
          font: 'FirstNations'
        },

        subTotalRow: {
          fontSize: 10,
          color: '#55565B',
          font: 'Roboto',
          bold: true
        },
        totalRow: {
          fontSize: 10,
          color: 'white',
          fillColor: 'black',
          font: 'Roboto',
          bold: true
        },
        manualPageBreak: {
          margin: [0, 0, 0, 0],
          fontSize: 1
        }
      },
      ...documentParts
    })
    .download(
      options.filename + currentDate.toISOString().slice(0, 16) + '.pdf'
    )
}

function pageBreakeBeforeWithManual (
  currentNode,
  followingNodesOnPage,
  nodesOnNextPage,
  previousNodesOnPage
) {
  if (currentNode.table && currentNode.table.tableId === 'mainTable') {
    return currentNode.startPosition.pageNumber !== 1
  } else if (currentNode.style === 'manualPageBreak') {
    return true
  } else {
    return false
  }
}

export {
  pageBreakeBeforeWithManual,
  opportunityFields,
  summaryTypes,
  summaryOptions,
  htmlToText,
  listToLi,
  pageFooter,
  baseGeneratePDF,
  pageHeader,
  benchmarksToDate,
  START_DATE,
  END_DATE,
  CBITF,
  STF_SI,
  STF_LP
}

export default generatePDF
