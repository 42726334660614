/**
 * Created by Michał Stawski on 01.03.2020.
 */
import React from 'react'
import { WrappedRechart } from './rechartToSVG'
import { Cell, Legend, Pie, PieChart } from 'recharts'

const COLORS = [
  '#3C66B1',
  '#D5702C',
  '#959595',
  '#E7AD00',
  '#00A1E0',
  '#16325C',
  '#76DED9',
  '#08A69E',
  '#E2CE7D',
  '#C23934',
  '#FFB75D',
]

class AllocationPerType extends WrappedRechart {
  static defaultProps = {
    width: 700,
    height: 400,
  }

  render () {
    let { width, height, id, data } = this.props

    return <PieChart width={width} height={height}
                     ref={(chart) => {
                       this.chartRef = chart
                     }}

    >
      <Pie
        id={id}
        data={data}
        labelLine={false}
        isAnimationActive={false}
        // label={renderCustomizedLabel}
        // outerRadius={150}
        innerRadius='40%'
        fill="#8884d8"
        dataKey="value"
        label
      >
        {
          data.map((entry, index) => <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]}/>)
        }
      </Pie>
      <Legend/>
    </PieChart>
  }
}

export { AllocationPerType, COLORS }
