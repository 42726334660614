import React from 'react'
import { Provider } from 'react-redux'
import Router from '../router/router'
import moment from 'moment'
import MomentUtils from '@date-io/moment'
import {MuiPickersUtilsProvider} from '@material-ui/pickers'

class UTCMomentUtils extends MomentUtils {
  parse (value, format) {
    return this.moment.utc(value, format, true)
  }

  date (value) {
    return this.moment.utc(value)
  }
}

const Root = ({ store, history }) => {
  return (
    <Provider store={store}>
      <MuiPickersUtilsProvider
        utils={UTCMomentUtils}
        moment={moment}
        // locale?
        locale="en"
      >
        <Router history={history}/>
      </MuiPickersUtilsProvider>
    </Provider>
  )
}

export default Root

