/**
 * Created by Michał Stawski on 16.06.2020.
 */
import React, { useEffect, useRef, useState } from 'react'

import { connect } from 'formik'

function usePrevious (value) {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

function FormikEffect ({ onChange, firstRenderHandler, formik: { values } }) {
  const [firstRender, setFirstRender] = useState(true)
  const previousValues = usePrevious(values)

  useEffect(() => {
    if (firstRender) {
      setFirstRender(false)
      if (firstRenderHandler) {
        firstRenderHandler(values)
      }
    } else {
      onChange(previousValues, values)
    }
  }, [onChange, values])

  return null
}

export default connect(FormikEffect)
