import { fundTypeToLabel, oppToFundType, oppToValue } from './commonCalc'

/**
 * Created by Michał Stawski on 26.03.2020.
 */

const calcFundsByFundType = (result, { start, end, rangeType }) => {
  let calculated = {}
  let fund, amount, fund_type
  let budgets = {}
  let budgetsByYear = {}

  for (let res of result) {
    fund = res.RecordType.Name
    amount = oppToValue(res)
    fund_type = oppToFundType(res)

    if (!calculated[fund_type]) {
      calculated[fund_type] = {
        value: 0,
        count: 0,
        name: fund
      }
    }
    if (res.Budget__r) {
      if (!budgetsByYear[fund_type]) {
        budgetsByYear[fund_type] = {}
      }
      budgetsByYear[fund_type][res.Budget__r.Name] = res.Budget__r.FGM_Base__Total_Budget__c
    }
    if (!budgets[fund_type] && res.Budget__r) {
      if (fund_type === 'stf_l' && res.Budget__r.Name.startsWith('STFSI ')) {
        console.error('wrong budget', res)
      }
      budgets[fund_type] = res.Budget__r.FGM_Base__Total_Budget__c
    } else if (res.Budget__r) {
      if (res.Budget__r.FGM_Base__Total_Budget__c !== budgets[fund_type]) {
        console.warn('different budgets')
      }
    }

    calculated[fund_type].value += amount
    calculated[fund_type].count += 1
  }
  let data = []

  budgets = Object.fromEntries(Object.entries(budgetsByYear).map(
    ([key, value], index) => [key, Object.values(value).reduce((sum, item) => sum + item)]))

  let rangeScale
  let rangeLenghth
  if (rangeType === 'actual') {
    rangeLenghth = end.diff(start, 'days')
    rangeScale = rangeLenghth / 365

  } else {
    rangeScale = 1
  }
  console.log('budgets', budgets, rangeScale, rangeType, { start, end }, rangeLenghth)
  console.log('budgetsByYear', budgetsByYear)
  console.log('calculated funds', calculated)

  for (let key in calculated) {
    data.push({
      percent: calculated[key].value / budgets[key] * 100,
      value: calculated[key].value,
      budget: budgets[key] * rangeScale,
      // name: formatName(calculated[key].name),
      name: fundTypeToLabel(key)
    })
  }

  return { data }
}

export { calcFundsByFundType }
