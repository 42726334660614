import { provinceMapping } from './calcGeoDistribution'
import { oppToState } from './commonCalc'

/**
 * Created by Michał Stawski on 26.03.2020.
 */

const calcProjectsByGeoFundStage = (result) => {
  let calculated = {}
  let name, state, fund, stage

  for (let res of result) {
    name = res.Name
    fund = res.RecordType.Name
    stage = res.StageName

    state = oppToState(res)
    if (!calculated[fund]) {
      calculated[fund] = {}
    }
    if (!calculated[fund][state]) {
      calculated[fund][state] = {}
    }

    if (!calculated[fund][state][stage]) {
      calculated[fund][state][stage] = []
    }
    calculated[fund][state][stage].push(name)
  }
  for (fund in calculated) {
    for (state in calculated[fund]) {
      for (stage in calculated[fund][state]) {
        calculated[fund][state][stage].sort((a, b) => a ? a.localeCompare(b) : -1)
      }
    }
  }

  return { table: calculated }
}

export { calcProjectsByGeoFundStage }
