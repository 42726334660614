/**
 * Created by Michał Stawski on 16.06.2020.
 */
import React from 'react'
import { Field, Form, Formik } from 'formik'
import { RadioGroup, Switch } from 'formik-material-ui'
import FormLabel from '@material-ui/core/FormLabel'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'
import FormikEffect from './FormikEffect'
import { DatePicker, } from 'formik-material-ui-pickers'
import moment from 'moment'
import InputLabel from '@material-ui/core/InputLabel'

const PERIOD_OPTIONS = [
  { name: 'Fiscal Year', value: 'yearly' },
  { name: 'Quarterly', value: 'quarterly' },
  { name: 'Last Quarter', value: 'last_quarter' },
  { name: 'Monthly', value: 'monthly' },
  // { name: 'Cumulative', value: 'cumulative' },
  { name: 'Date picker', value: 'date_picker' },
]

const TYPE_OF_FUND = [
  { name: 'All', value: 'all' },
  { name: 'CBTI', value: 'cbti' },
  { name: 'STF-local', value: 'stf_l' },
  { name: 'STF-impact', value: 'stf_s' },
]

const QUARTERS = [
  { name: 'Q1', value: '1' },
  { name: 'Q2', value: '2' },
  { name: 'Q3', value: '3' },
  { name: 'Q4', value: '4' },
]
const current_date = moment.utc()

const YEARS = []

const FISCAL_YEARS = []

const getYears = (current_date) => {
  let current_year = moment().utc().year()
  let i
  for (i = current_year; i >= 2019; i--) {
    YEARS.push({ name: `${i}`, value: `${i}` })
    FISCAL_YEARS.push({ name: `${i}/${i + 1}`, value: `${i}` })
  }
}

const AMOUNTS = [
  { name: 'Total', value: 'total' },
  { name: 'Under 50k$', value: 'lt_50k' },
  { name: 'Between 50k$ and 100k$', value: 'gt_50k_lt_100K' },
  { name: 'Between 50k$ and 150k$', value: 'gt_50k_lt_150k' },
  { name: 'Over 50k$', value: 'gt_50k' },
  { name: 'Over 150k$', value: 'gt_150k' },
]

getYears(current_date)

const AllocationForm = ({ classes = {}, parentHook }) => (
  <div className={classes.formik}>
    <Formik
      initialValues={{
        typeOfFund: 'all',
        amount: 'total',
        period: 'last_quarter',
        fiscal_year: '2019',
        selected_quarter: '1',
        selected_year: '2020',
        endAtEndOfActual: true,
        endAtEnd: false,
        start: moment.utc().subtract(1, 'month').startOf('month').toDate(),
        end: moment.utc().subtract(1, 'month').endOf('month').toDate()
      }}
      onSubmit={(data) => {
        console.log(data)
      }}
      render={({ submitForm, isSubmitting, values }) => (
        /* TODO validation schema*/
        <Form className="m-sm-30">
          <FormikEffect
            onChange={(currentFormikState, nextFormikState) => {
              // do whatevs
              // FYI if you alter state it will cause another render
              if (currentFormikState !== nextFormikState) {
                parentHook(nextFormikState)
              }
            }}
            firstRenderHandler={parentHook}
          />
          <hr/>
          <Field component={RadioGroup} name={'typeOfFund'}>
            <FormLabel component="label">
              Type of fund
            </FormLabel>
            {TYPE_OF_FUND.map((item, index) => (
              <FormControlLabel
                key={index}
                value={item.value}
                control={<Radio disabled={isSubmitting}/>}
                label={item.name}
                // block for this time
                disabled={isSubmitting || Boolean(index)}

              >
              </FormControlLabel>
            ))}
          </Field>

          <hr/>
          <Field component={RadioGroup} name={'period'}>
            <FormLabel component="legend">
              Period covered
            </FormLabel>
            {PERIOD_OPTIONS.map((item, index) => (
              <FormControlLabel
                key={index}
                value={item.value}
                control={<Radio disabled={isSubmitting}/>}
                label={item.name}
                disabled={isSubmitting}
              >
              </FormControlLabel>
            ))}
          </Field>
          <hr/>
          {values.period === 'monthly' &&
          <Field
            views={['year', 'month']}
            component={DatePicker}
            name="end"
            label="End Month"
          />}
          {values.period === 'date_picker' &&
          <Field
            component={DatePicker}
            name="start"
            label="Start Date"
          />}

          {values.period === 'date_picker' &&
          <Field
            component={DatePicker}
            name="end"
            label="End Date"
          />}
          {values.period === 'yearly' &&
          <Field component={RadioGroup} name='fiscal_year'>
            <FormLabel component="legend">
              Fiscal Year
            </FormLabel>
            {FISCAL_YEARS.map((item, index) => (
              <FormControlLabel
                key={index}
                value={item.value}
                control={<Radio disabled={isSubmitting}/>}
                label={item.name}
                disabled={isSubmitting}
              >
              </FormControlLabel>
            ))}
          </Field>}
          {values.period === 'quarterly' &&
          <Field component={RadioGroup} name='selected_quarter'>
            <FormLabel component="legend">
              Quarter
            </FormLabel>
            {QUARTERS.map((item, index) => (
              <FormControlLabel
                key={index}
                value={item.value}
                control={<Radio disabled={isSubmitting}/>}
                label={item.name}
                disabled={isSubmitting}
              >
              </FormControlLabel>
            ))
            }</Field>}
          {values.period === 'quarterly' &&
          <Field component={RadioGroup} name='selected_year'>
            <FormLabel component="legend">
              Year
            </FormLabel>
            {YEARS.map((item, index) => (
              <FormControlLabel
                key={index}
                value={item.value}
                control={<Radio disabled={isSubmitting}/>}
                label={item.name}
                disabled={isSubmitting}
              >
              </FormControlLabel>
            ))}
          </Field>}
          <hr/>
          <Field component={RadioGroup} name={'amount'}>
            <FormLabel component="legend">
              Amount
            </FormLabel>
            {AMOUNTS.map((item, index) => (
              <FormControlLabel
                key={index}
                value={item.value}
                control={<Radio disabled={isSubmitting}/>}
                label={item.name}
                disabled={isSubmitting || Boolean(index)}
              >
              </FormControlLabel>
            ))}
          </Field>
          <hr/>
          <InputLabel
            htmlFor="countIsValidated"
          >
            <Field
              component={Switch}
              name={'countIsValidated'}
              type='checkbox'
              label={'Count is Validated'}
            >
              <FormLabel component="legend">
                Also count Validated date
              </FormLabel>
            </Field>
            Also count Validated date
          </InputLabel>
          <hr/>
          <InputLabel
            htmlFor="countIsValidated"
          >
            <Field
              component={Switch}
              name={'useCustomLabel'}
              type='checkbox'
              label={'Use custom Label dor Actual'}
            >
              <FormLabel component="legend">
                Use custom Label dor Actual
              </FormLabel>
            </Field>
            Use custom Label dor Actual
          </InputLabel>
          <InputLabel
            htmlFor="endAtEndOfActual"
          >
            <Field
              component={Switch}
              name={'endAtEndOfActual'}
              type='checkbox'
              label={'End annual and allTime at same date as actual'}
            >
              <FormLabel component="legend">
                End annual and allTime at same date as actual
              </FormLabel>
            </Field>
            End annual and allTime at same date as actual
          </InputLabel>
        </Form>
      )}
    >
    </Formik>
  </div>
)
export { AllocationForm }
