import { provinceMapping } from './calcGeoDistribution'
import { oppToState } from './commonCalc'

/**
 * Created by Michał Stawski on 26.03.2020.
 */

const calcProjectsByGeoFund = (result) => {
  let calculated = {}
  let name, state, fund

  for (let res of result) {
    name = res.Name
    fund = res.RecordType.Name

    state = oppToState(res)
    if (!calculated[fund]) {
      calculated[fund] = {}
    }
    if (!calculated[fund][state]) {
      calculated[fund][state] = []
    }
    calculated[fund][state].push(name)
  }
  for (fund in calculated) {
    for (state in calculated[fund]) {
      calculated[fund][state].sort((a, b) => a ? a.localeCompare(b) : -1)
    }
  }

  return { table: calculated }
}

export { calcProjectsByGeoFund }
