/**
 * Created by Michał Stawski on 29.02.2020.
 */
import React from 'react'
import { Cell, Label, LabelList, Legend, Pie, PieChart, Text } from 'recharts'
import { WrappedRechart } from './rechartToSVG'

const COLORS = ['#4471C4', '#EC7C30', '#A4A4A3', '#FFC000', '#5B9BD4']
// const react = ['#0070ff', '#d72e3d', '#249d3d', '#ffb90c', '#1698af', '#616a72']
// const Boostrap = ['#40C4FF', '#FF5252', '#00C853', '#FFEB3B', '#FF4081', '#E040FB']
// label={renderCustomizedLabel}

const exampleData = [
  { name: 'Group A', value: 400 },
  { name: 'Group B', value: 300 },
  { name: 'Group C', value: 300 },
  { name: 'Group D', value: 200 }
]

class PieChartContainer extends WrappedRechart {
  render () {
    let {
      width,
      height,
      dataKey = 'value',
      data = exampleData,
      id,
      label = x => x.name,
      filter,
      labelLine = x => x.name,
      // pieFill = defaultColors,
      margin = { top: 5, right: 5, bottom: 5, left: 5 }
    } = this.props

    if (filter) {
      data = data.filter(filter)
    }
    console.log(data)
    return (
      <PieChart
        width={width}
        height={height}
        id={id}
        margin={margin}
        ref={chart => {
          this.chartRef = chart
        }}
      >
        <Pie
          isAnimationActive={false}
          data={data}
          label={renderCustomLabel}
          labelLine={false}
          outerRadius={60}
          dataKey='all'
        >
          {data.map((entry, index) => {
            return <Cell key={`cell-${index}`} fill={entry.fill} />
          })}
        </Pie>

        <Pie
          isAnimationActive={false}
          data={data}
          label={label}
          innerRadius={85}
          dataKey={dataKey}
        >
          {data.map((entry, index) => {
            return <Cell key={`cell-${index}`} fill={entry.fill} />
          })}
        </Pie>
      </PieChart>
    )
  }
}

const RADIAN = Math.PI / 180
const renderCustomLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  value
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5
  const x = cx + radius * 2.2 * Math.cos(-midAngle * RADIAN)
  const y = cy + radius * 2.2 * Math.sin(-midAngle * RADIAN)

  return (
    <text
      style={{ fontSize: 18, fontWeight: 'bold' }}
      x={x}
      y={y}
      fill='#000000'
      textAnchor={x > cx ? 'start' : 'end'}
      dominantBaseline='central'
    >
      {value}
    </text>
  )
}

export { PieChartContainer, exampleData, COLORS }
