import ReactDOM from "react-dom";
import React from "react";

/**
 * Created by Michał Stawski on 29.02.2020.
 */

const rechartToSVG = (chartRef, title) => {
  let chartSVG = ReactDOM.findDOMNode(chartRef).children[0];
  let legendDom = ReactDOM.findDOMNode(chartRef).children[1];
  let legend = rechartToLegend(legendDom);
  return extractChart(chartSVG, legend, title);
};

const extractChart = (chart, legend, title) => {
  let svgURL = new XMLSerializer().serializeToString(chart);
  return { svg: svgURL, legend, title };
};

const idToSVG = (id, title) => {
  let chartDom = document.getElementById(id);
  let legendDom = document.getElementById(id).nextSibling;
  let legend = rechartToLegend(legendDom);

  return extractChart(chartDom, legend, title);
};

const rechartToLegend = (legend) => {
  let legendArray = [];
  if (
    legend &&
    legend.className === "recharts-legend-wrapper" &&
    legend.children[0]
  ) {
    for (let child of legend.children[0].children) {
      legendArray.push({
        colorSVG: new XMLSerializer().serializeToString(child.children[0]),
        name: child.children[1].innerHTML,
      });
    }
  }
  return legendArray;
};

class WrappedRechart extends React.Component {
  static defaultProps = {
    width: 700,
    height: 400,
  };
  constructor(props) {
    super(props);
    this.chartRef = React.createRef();
  }
}

export { WrappedRechart, idToSVG };
export default rechartToSVG;
