import { fundTypeToLabel, oppToFundType, oppToValue } from './commonCalc'

/**
 * Created by Michał Stawski on 26.03.2020.
 */


const calcAverageByFund = (result) => {
  let calculated = {
    total: {
      name: 'total',
      count: 0,
      value: 0
    }
  }
  let amount, fund_type

  for (let res of result) {
    amount = oppToValue(res)
    fund_type = oppToFundType(res)

    if (!calculated[fund_type]) {
      calculated[fund_type] = {
        value: 0,
        count: 0
      }
    }
    calculated[fund_type].value += amount
    calculated[fund_type].count += 1

    calculated.total.value += amount
    calculated.total.count += 1
  }
  let data = []
  for (let key in calculated) {
    data.push({
      name: fundTypeToLabel(key),
      count: calculated[key].count,
      value: calculated[key].value,
      average: calculated[key].count ? calculated[key].value / calculated[key].count : 0
    })
  }

  return { data }
}

export { calcAverageByFund }
