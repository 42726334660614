/**
 * Created by Michał Stawski on 30.01.2020.
 */
// import React from 'react'
import { baseGeneratePDF, pageHeader } from './pdfExport'

// LETTER: [612.00, 792.00],
const fundAllocation = (tableData, options = {}) => {
  let rows = []
  let { alignment = 'center' } = options

  let nf = new Intl.NumberFormat('en-CA',
    {
      style: 'currency', currency: 'CAD',
      minimumFractionDigits: 0, maximumFractionDigits: 0
    }
  )
  let nfp = new Intl.NumberFormat('en-CA',
    { style: 'unit', unit: 'percent' }
  )
  for (let rowTable of tableData) {
    rows.push([
      { text: rowTable[0], style: 'dashboardHeaderSide' },
      { text: nf.format(rowTable[1]), style: 'dashboardEven' },
      { text: nf.format(rowTable[2]), style: 'dashboardEven' },
      { text: nfp.format(rowTable[3]), style: 'dashboardEven' }
    ])
  }
  return {
    layout: 'dashboardLayout',
    margin: [0, 60, 0, 60],
    alignment,
    table: {
      headerRows: 0,
      widths: [70, 80, 80, 80],
      body: [
        [
          { text: 'FUND', style: 'dashboardHeader' },
          { text: 'ALLOCATED FUNDING', style: 'dashboardHeader' },
          { text: 'AVAILABLE FUNDING', style: 'dashboardHeader' },
          { text: 'PERCENTAGE GIVEN', style: 'dashboardHeader' },
        ],
        ...rows
      ]
    }
  }
}

const opportunityByStage = (tableData, options = {}) => {
  let rows = []
  let { alignment = 'center' } = options

  let nf = new Intl.NumberFormat('en-CA',
    {
      style: 'currency', currency: 'CAD',
      minimumFractionDigits: 2, maximumFractionDigits: 2
    }
  )

  for (let rowTable of tableData) {
    rows.push([
      { text: rowTable[0], style: 'dashboardHeaderSide' },
      { text: nf.format(rowTable[1]), style: 'dashboardEven' },
      { text: rowTable[2], style: 'dashboardEven' },
      { text: nf.format(rowTable[3]), style: 'dashboardEven' },
      { text: rowTable[4], style: 'dashboardEven' },
      { text: nf.format(rowTable[5]), style: 'dashboardEven' },
      { text: rowTable[6], style: 'dashboardEven' },
      { text: nf.format(rowTable[7]), style: 'dashboardEven' },
      { text: rowTable[8], style: 'dashboardEven' },
    ])
  }
  return {
    layout: 'dashboardLayout',
    margin: [0, 20, 0, 20],
    alignment,
    table: {
      headerRows: 2,
      widths: [100, 100, 40, 100, 40, 100, 40, 100, 40],
      body: [
        [
          { text: 'Opportunity Record Type', style: 'dashboardHeader' },
          { text: 'CBTIF', style: 'dashboardHeader', colSpan: 2 },
          '',
          { text: 'STF - Local Project', style: 'dashboardHeader', colSpan: 2 },
          '',
          { text: 'STF - Sectoral Impact', style: 'dashboardHeader', colSpan: 2 },
          '',
          { text: 'Total', style: 'dashboardHeader', colSpan: 2 },
          ''
        ],
        [
          { text: 'Stage', style: 'dashboardHeader' },
          { text: 'Sum Amount Requested', style: 'dashboardHeader' },
          { text: 'Count', style: 'dashboardHeader' },
          { text: 'Sum Amount Requested', style: 'dashboardHeader' },
          { text: 'Count', style: 'dashboardHeader' },
          { text: 'Sum Amount Requested', style: 'dashboardHeader' },
          { text: 'Count', style: 'dashboardHeader' },
          { text: 'Sum Amount Requested', style: 'dashboardHeader' },
          { text: 'Count', style: 'dashboardHeader' },
        ],
        ...rows
      ]
    }
  }
}

let GRAPH_WIDTH = 350
const svgContainer = (svgObject, options) => {
  let { graphWidth = GRAPH_WIDTH, graphHeight = 180, tableId = 'svgContainer' } = { ...options }
  return {
    layout: 'pageFourLayout',
    alignment: 'center',
    table: {
      tableId,
      headerRows: 0,
      widths: [graphWidth],
      margin: [0, 0, 0, 0],
      body: [
        [
          { text: svgObject.title, style: 'dashboardTitle' },
        ],
        [{
          svg: svgObject.svg,
          width: graphWidth,
          height: graphHeight,
          margin: [0, 0, 0, 0],
        }],
        [
          svgObject.legend.length === 0 ? ' ' : {
            layout: 'pageFourLayout',
            table: {
              tableId: 'svgContainer',
              headerRows: 0,
              widths: new Array(svgObject.legend.length * 2).fill('*').map(
                (item, index) => index % 2 ? '*' : 5
              ),
              margin: [1, 1, 1, 1],
              body: [
                svgObject.legend.map((item) => {
                  return [
                    { svg: item.colorSVG, height: 10, margin: [0, 0, 0, 0] },
                    { text: item.name, style: 'dashboardLegend' }
                  ]
                }).flat()
              ]
            }
          }
        ]
      ]
    }
  }
}

const svgContainerVertical = (svgObject) => {
  return {
    layout: 'pageFourLayout',
    table: {
      tableId: 'svgContainer',
      headerRows: 0,
      widths: [GRAPH_WIDTH / 2, GRAPH_WIDTH / 2],
      margin: [0, 0, 0, 0],
      body: [
        [
          { text: svgObject.title, style: 'dashboardTitle', colSpan: 2 },
          {}
        ],
        [{
          svg: svgObject.svg,
          width: GRAPH_WIDTH / 2,
          // height: 180,
          margin: [0, 0, 0, 0],
        },
          svgObject.legend.length === 0 ? ' ' : {
            layout: 'pageFourLayout',
            table: {
              tableId: 'svgContainer',
              headerRows: 0,
              widths: [10, '*'],
              margin: [1, 1, 1, 1],
              body: svgObject.legend.map((item) => {
                return [
                  { svg: item.colorSVG, height: 10, margin: [0, 0, 0, 0] },
                  { text: item.name, style: 'dashboardLegend' }
                ]
              })

            }
          }
        ]
      ]
    }
  }
}

const generateDashboardPDF = (documentParts, options) => {
  baseGeneratePDF({
    header: pageHeader({ title: 'Dashboard – Funding distribution' }),
    pageOrientation: 'landscape',
    content: [
      {
        layout: 'pageFourLayout',
        table: {
          tableId: 'mainTable',
          headerRows: 0,
          widths: [GRAPH_WIDTH + 2, GRAPH_WIDTH + 2],
          margin: [0, 0, 0, 0],
          body: [
            [
              // fundAllocation(documentParts.allocationTable),
              '',
              svgContainerVertical(documentParts.priorityAreasCentre)
            ],
            [
              svgContainer(documentParts.numberOfProjects),
              svgContainerVertical(documentParts.priorityAreasCentreCMHC)
            ]
          ]
        }
      },
      {
        layout: 'pageFourLayout',
        table: {
          tableId: 'mainTable',
          headerRows: 0,
          widths: [GRAPH_WIDTH + 2, GRAPH_WIDTH + 2],
          margin: [0, 0, 0, 0],
          body: [
            [
              svgContainerVertical(documentParts.allocationPerType),
              svgContainer(documentParts.lengthOfProject),
            ],
            [
              svgContainer(documentParts.geoDistribution),
              svgContainer(documentParts.numberOfTenants),
            ]
          ]
        }
      },
      {
        layout: 'pageFourLayout',
        table: {
          tableId: 'mainTable',
          headerRows: 0,
          widths: [GRAPH_WIDTH * 2],
          margin: [0, 0, 0, 0,],
          body: [
            [
              svgContainer(documentParts.opportunitiesByStage, { graphWidth: GRAPH_WIDTH * 2, graphHeight: null }),
            ],
            [
              opportunityByStage(documentParts.opportunitiesByStageTable, { alignment: 'center' }),
            ]
          ]
        }
      },
      svgContainer(documentParts.opportunitiesByFundingLevel, {
        graphWidth: GRAPH_WIDTH * 2, graphHeight: null, tableId: 'mainTable'
      }),
    ],
    pageBreakBefore: function (currentNode, followingNodesOnPage, nodesOnNextPage, previousNodesOnPage) {
      // return currentNode.headlineLevel === 1 && followingNodesOnPage.length === 0
      if (currentNode.table && currentNode.table.tableId === 'mainTable') {
        return currentNode.startPosition.verticalRatio > 0.2
      } else {
        return false
      }
    },
  }, {
    filename: 'Dashboard_',
  })
}

export default generateDashboardPDF
