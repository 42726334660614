import { provinceMapping } from './calcGeoDistribution'
import moment from 'moment'

/**
 * Created by Michał Stawski on 12.06.2020.
 */

function oppToFundType (res) {
  if (res.RecordType.Name === 'Sector Transformation Fund – Sectoral Impact') {
    return 'stf_s'
  } else if (res.RecordType.Name === 'Sector Transformation Fund – Local Project') {
    return 'stf_l'
  } else if (res.RecordType.Name === 'Community-Based Tenant Initiative Fund (CBTIF)') {
    return 'cbtif'
  }
}

const fundColors = {
  'stf_s': '#F9DFAE',
  'stf_l': '#9ECEE2',
  'cbtif': '#EFB1A7',
  'total': '#D6E1F0'
}
const oppToFundColor = (res) => fundColors[oppToFundType(res)]
const fundTypeToFundColor = (res) => fundColors[res]

const fundTypes = {
  'stf_s': 'STF - SI',
  'stf_l': 'STF - LP',
  'cbtif': 'CBTIF',
  'total': 'Total'
}

function fundTypeToLabel (fundType) {
  return fundTypes[fundType]
}

function oppToValue (res) {
  return res.FGM_Base__Recommended_Amount__c
}

function oppToValueRequested (res) {
  return res.FGM_Base__Amount_Requested__c
}

function isAwarded (res) {
  return isAwardedStage(res.StageName)
}

function isGranteeStage (StageName) {
  return [
    'In Progress',
    'Hold',
    'More info required',
    'Under Redaction', // handle changed name in application progress
  ].indexOf(StageName) !== -1
}

// stage later then awarded
function isAwardedStage (StageName) {
  return [
    'Awarded',
    'Contracted',
    'Operational',
    'Special scrutiny',
    'Finished',
    'Terminated',
  ].indexOf(StageName) !== -1
}

function isInPipeline (StageName) {
  return [
    'Submitted',
    'Hold',
    'More info required',
    'First Review',
    'In Depth Review',
    'External Review',
    'Executive Director Review',
    'Validated',
    'Awarded',
  ].indexOf(StageName) !== -1
}

function isInPipelineAllocation (StageName, countIsValidated) {
  return [
    'Submitted',
    'Hold',
    'More info required',
    'First Review',
    'In Depth Review',
    'External Review',
    'Executive Director Review',
    ...(countIsValidated ? ['Validated'] : [])
  ].indexOf(StageName) !== -1
}

function isCompletedStage (StageName) {
  return [
    'Contracted',
    'Operational',
    'Special scrutiny',
    'Terminated',
    'Finished',
    'Declined',
    'Withdrawn'
  ].indexOf(StageName) !== -1
}

const sortByString = (field, field2) => (a, b) => {

  if (field2) {
    return a[field][field2].localeCompare(b[field][field2])
  } else {
    return a[field].localeCompare(b[field])
  }
}

function sortByCreatedDate (a, b) {
  return moment.utc(a.CreatedDate).diff(moment.utc(b.CreatedDate))
}


function oppToStageTimes (res, endDate, precision) {
  let opportunityTimes = []
  let records
  if (res.OpportunityHistories) {
    records = res.OpportunityHistories.records
    records.sort(sortByCreatedDate)

    for (let i = 0; i < records.length - 1; i++) {
      let startDate = moment.utc(records[i].CreatedDate)
      let endDate = moment.utc(records[i + 1].CreatedDate)
      opportunityTimes.push({
        StageName: records[i].StageName,
        days: endDate.diff(startDate, precision),
        startDate: startDate.format('YYYY-MM-DD'),
        endDate: endDate.format('YYYY-MM-DD'),
      })
      if (opportunityTimes[opportunityTimes.length - 1].days < 0) {
        console.log('less thenzero', res)
      }

    }
    opportunityTimes.push({
      StageName: records[records.length - 1].StageName,
      startDate: moment.utc(records[records.length - 1].CreatedDate).format('YYYY-MM-DD'),
      endDate: endDate.format('YYYY-MM-DD'),
      days: endDate.diff(moment.utc(records[records.length - 1].CreatedDate), precision)
    })
    if (opportunityTimes[opportunityTimes.length - 1].days < 0) {
      console.log('less thenzero', res)
    }
    return opportunityTimes
  } else {
    return {}
  }

}

function isSubmitted (res) {
  return res.StageName !== 'In Progress'
}

function oppToState (res) {
  let state
  if (res.Geographical_scope_detailed__c) {
    state = res.Geographical_scope_detailed__c

  } else {
    if (isSubmitted(res)) {
      console.error('No geographical scope set for: ', res.Name, res.StageName)
      // alert(`No geographical scope set for: ${res.Name}`)
    }

    if (res && res.Account && res.Account.BillingAddress) {
      state = res.Account.BillingAddress.state
    } else {
      state = 'None'
    }
  }

  if (provinceMapping[state]) {
    state = provinceMapping[state].name
  }
  return state
}

const firstStageDate = (res, stageName) =>{
  let sortedStages = res.OpportunityHistories.records.sort(sortByCreatedDate)
  let i
  for (i = 0; i < sortedStages.length; i++) {
    if (sortedStages[i].StageName === stageName) {
      return moment.utc(sortedStages[i].CreatedDate)
    }
  }
}

export {
  firstStageDate,
  oppToFundType,
  oppToState,
  fundTypeToLabel,
  oppToValue,
  isAwarded,
  sortByString,
  isCompletedStage,
  isAwardedStage,
  isGranteeStage,
  oppToStageTimes,
  fundTypeToFundColor,
  oppToFundColor,
  isInPipeline,
  isInPipelineAllocation,
  isSubmitted,
  oppToValueRequested,
}
