/**
 * Created by Michał Stawski on 12.03.2020.
 */


// const stageNames = [
//   'In Progress',
//   'Submitted',
//   'Awarded',
//   'Validated',
//   'Withdrawn',
//   'External Review',
//   'First Review',
//   'In Depth Review',
//   'Declined',
//   'More info required',
//   'Executive Director Review'
// ]

const addStages = (data, excludeStages) => {
  let ret = {
    stf_l: 0,
    stf_s: 0,
    cbitf: 0
  }

  for (let stage in data) {
    if (excludeStages.indexOf(stage) === -1) {
      for (let fund in ret) {
        ret[fund] += data[stage][fund]
      }
    }
  }
  return ret
}

const calcNumberOfProjects = (result) => {
  let calculated = {}
  let total=0, noStageName=0
  for (let res of result) {
    if (res.StageName) {
      if (!calculated[res.StageName]) {
        calculated[res.StageName] = { stf_l: 0, stf_s: 0, cbitf: 0 }
      }
      if (res.RecordType.Name === 'Sector Transformation Fund – Sectoral Impact') {
        calculated[res.StageName].stf_s += 1
      } else if (res.RecordType.Name === 'Sector Transformation Fund – Local Project') {
        calculated[res.StageName].stf_l += 1
      } else if (res.RecordType.Name === 'Community-Based Tenant Initiative Fund (CBTIF)') {
        calculated[res.StageName].cbitf += 1
      }
    } else {
      noStageName++
    }
    total++
  }

  let data = []
  data.push({
    name: 'NUMBER OF PROJECTS ACCEPTED BY THE CENTRE A',
    stf_s: calculated['Awarded'].stf_s,
    stf_l: calculated['Awarded'].stf_l,
    cbitf: calculated['Awarded'].cbitf,
  })
  data.push({
    name: 'NUMBER OF PROJECTS SUBMITTED',
    ...addStages(calculated, ['In Progress'])
  })
  data.push({
    name: 'TOTAL NUMBER OF OPEN PROJECTS IN GMS (IN PROGRESS AND SUBMITTED)',
    ...addStages(calculated, [])
  })
  // data.push({
  //   name: 'In progress',
  //   stf_s: calculated['In Progress'].stf_s,
  //   stf_l: calculated['In Progress'].stf_l,
  //   cbitf: calculated['In Progress'].cbitf,
  // })
  return { data: data, total, noStageName }
}
export default calcNumberOfProjects
