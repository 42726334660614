/**
 * Created by Michał Stawski on 01.03.2020.
 */
import React from 'react'
import { WrappedRechart } from './rechartToSVG'
import { Bar, BarChart, CartesianGrid, Legend, XAxis, YAxis } from 'recharts'

class DoubleHorizontalBarsChart extends WrappedRechart {
  render () {
    let {
      width,
      height,
      data,
      id,
      barDefinition,
      legend = true,
      yWidth = 250,
      yAxisTick = {},
      margin = { top: 15, right: 5, bottom: 5, left: 15 }
    } = this.props

    return (
      <BarChart
        width={width}
        height={height}
        data={data}
        ref={chart => {
          this.chartRef = chart
        }}
        id={id}
        layout='vertical'
        margin={margin}
      >
        <CartesianGrid strokeDasharray='3 3' />
        <XAxis type='number' xAxisId={0} hide domain={[0, 'dataMax']} />
        <XAxis type='number' xAxisId={1} hide domain={[0, 'dataMax * 4']} />

        <XAxis type='number' />
        <YAxis dataKey='name' type='category' width={yWidth} tick={yAxisTick} />
        {legend ? <Legend /> : ''}
        {barDefinition.map((item, index) => {
          let { children, ...rest } = item
          return (
            <Bar key={index} {...rest} isAnimationActive={false}>
              {children}
            </Bar>
          )
        })}
      </BarChart>
    )
  }
}

export { DoubleHorizontalBarsChart }
