import React from 'react'
import { Redirect, Route } from 'react-router-dom'

export const PrivateRoute = ({ component: Component, authorization = {}, perms = null, ...rest }) => (
  <Route {...rest} render={props => {

    if (authorization.loggedIn) {
      if (!authorization.refreshed) {
        return <div>Loading</div>
      } else {

        let u = authorization.user
        if (!perms || (u.perms && u.perms.indexOf(perms) !== -1)) {
          return (<Component {...props} />)
        } else {
          return (<Redirect to={{ pathname: '/', state: { from: props.location } }}/>)
        }
      }
    }

    return (<Redirect to={{ pathname: '/login/', state: { from: props.location } }}/>)
  }}/>
)

